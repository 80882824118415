import { faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import React, { useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Container, Modal } from 'react-bootstrap';
import '../ConvertirLicenciaModal/style-modal.css'

interface ModalEliminarProvisorioProps {
    triggerSubmit: (() => void);
    ID: number;
    typeModal: number //0 = información complementaria. 1= facturas. 2=ventajas
    nroInfComp?: string
    nroFactura?: string
    ventajaDesc?: string
}

const ModalEliminarFacturasInfCompVentajas = ({ ID, triggerSubmit, typeModal, nroFactura, nroInfComp, ventajaDesc }: ModalEliminarProvisorioProps) => {

    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    let api = useAdvAPI();

    const handleClose = () => {
        setShow(false)
    }

    const confirmarEliminar = async () => {
        updateCargando(true)
        try {
            if (typeModal === 0) {
                await api.deleteCPL(ID)
            } else if (typeModal === 1) {
                await api.deleteDVD(ID)
            } else if (typeModal === 2) {
                await api.deleteSRG(ID)
            }
            updateCargando(false);
            triggerSubmit()
        } catch (error) {
            updateCargando(false);
            console.log('error', error)
        }
    }
    const handleShow = () => setShow(true);

    return (
        <>
         <FontAwesomeIcon  title='Borrar' onClick={handleShow} style={{ fontSize: 18,cursor:'pointer', color: '#db3939' }} icon={faTrash} />
            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    <Modal.Body>
                        <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon style={{ fontSize: 50, color: '#e83846' }} icon={faTimesCircle} />
                            <p style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                                {typeModal === 0
                                    ? `Confirma eliminar la informacion complementaria: ${nroInfComp}`
                                    : typeModal === 1
                                        ? `Confirma eliminar la factura: ${nroFactura}`
                                        : `Confirma eliminar la Ventaja: ${ventajaDesc}`}
                            </p>
                        </Container>
                        <Container style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignContent: 'center', justifyItems: 'center', alignItems: 'center', marginTop: 15 }}>
                            <Button variant="danger" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="success" onClick={() => confirmarEliminar()}>
                                Confirmar
                            </Button>
                        </Container>
                    </Modal.Body>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalEliminarFacturasInfCompVentajas