import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApi } from 'ApiHooks';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import { LoginAFIP, LoginAFIPRef } from 'Paginas/advanced/sesionafip';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Modal } from 'react-bootstrap'

interface propsModalSesiones {
    showModalSesiones: boolean | undefined;
    handleCloseModalSesiones: (() => void);
    cargando: boolean;
    updateCargando: ((args: boolean) => void)
    setShowModalOficializar: ((args: boolean) => void)
    setShowModalSesiones: ((args: boolean) => void)
    setCUITSeleccionado: Dispatch<SetStateAction<string>>
}
const ModalSesiones = ({ showModalSesiones, handleCloseModalSesiones, cargando, updateCargando, setShowModalOficializar, setShowModalSesiones, setCUITSeleccionado }: propsModalSesiones) => {

    let api = useAdvAPI();
    let apiSintia = useApi();
    let loginAFIPRef = useRef<LoginAFIPRef>(null);

    const [inicioSesion, setInicioSesion] = useState(false);
    const [resulado, updateResulado] = useState<any[]>([]);

    async function cargarDatos(listSesiones: any) {
        if (listSesiones.length > 0) {
            listSesiones.forEach(async (sesion: { CUIT: string; Nombre: string; UltimaConexion: DateTime }) => {
                let despanuevo = { 'cuit': '', 'nombre': '', 'inicioSesion': false };
                despanuevo.cuit = sesion.CUIT;
                despanuevo.nombre = sesion.Nombre;
                //verifico si tiene sesion
                let result = await api.getSesionInfo(sesion.CUIT);
                if (result.ParsedResponse!.ApiMessage.includes('Found a session')) {
                    despanuevo.inicioSesion = true;
                }
                updateResulado(resulado => [...resulado, despanuevo]);
            });
        }
        else {
            updateResulado([]);
        }
    }

    async function cargar() {
        try {
            updateCargando(true);
            updateResulado(([]));
            let sesiones = await apiSintia.getSesionesAFIPHistorico();
            await cargarDatos(sesiones);
        } catch (error) {
            if (!apiSintia.isCancel(error)) {
                console.error('Error al cargar caratulas', error);
            }
        } finally {
            updateCargando(false);
        }
    }

    const lenghtCss = function (nTotal: number) {
        return {
            '--length': nTotal

        }
    }

    const indiceCss = function (nIndice: number) {
        return {
            '--i': nIndice
        }
    }

    function iniciarSesion(t?: any) {
        //muestro el modal con el inicio de sesion en afip
        let logueo = false;
        let cuit: string;
        if (t === null || undefined) {
            cuit = '';
        } else {
            cuit = t?.cuit;
        }
        loginAFIPRef?.current!.mostrar(cuit)?.then(resultado => { setInicioSesion(resultado?.inicioSesion) })?.catch(() => { });
        if (logueo) {
            cargar();
        }
    }

    const CerrarSesionAFIP = async (cuit: string) => {
        try {
            updateCargando(true)
             await api.removeSessionByCuit({
                CuitLogin: cuit
            })
            cargar()
            updateCargando(false)
        } catch (error) {
            cargar()
            updateCargando(false)
        }
    }
    

    function GrillaDespachos(props: {
        despachantes: any[]
    }) {
        return <>
            <Button style={{ marginLeft: '25%' }} className="btn btn-info" onClick={e => {
                handleCloseModalSesiones()
                iniciarSesion();
                e.preventDefault();
                e.stopPropagation();
            }}>Iniciar nueva sesion</Button>
            <ol className="customoloficializar" style={lenghtCss(props.despachantes.length) as React.CSSProperties}>
                {props.despachantes && props.despachantes.map((t: any, indice: number) =>
                (
                    <li className="customli" style={indiceCss(indice + 1) as React.CSSProperties}>
                        <div className="align-items-center justify-content-center">
                            <h4 className='sesionButton'>{t?.nombre}</h4>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} className="align-items-center align-top">
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <h5>CUIT: {t?.cuit}</h5>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                    {
                                        (t?.inicioSesion) ?
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}><FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: "#2d85d7", }} /><h4 className="ml-2"> sesion iniciada</h4></div>
                                            :
                                            <Button disabled={t?.inicioSesion} style={{marginTop:0}} className="btn btn-info" onClick={e => {
                                                handleCloseModalSesiones()
                                                iniciarSesion(t);
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}>Iniciar Sesion</Button>
                                    }
                                    
                                </div>
                            </div>
                          { (t?.inicioSesion) && <Button style={{ marginLeft: '35%', marginTop: 10 }} onClick={() => {
                                setCUITSeleccionado(t?.cuit)
                                handleCloseModalSesiones()
                                setShowModalOficializar(true)
                            }}>Seleccionar</Button>}
                            {
                                        t?.inicioSesion && <Button style={{ marginLeft: 10, marginTop:10 }} className="btn btn-info" onClick={e => {
                                            CerrarSesionAFIP(t?.cuit);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}><FontAwesomeIcon icon={faTrash} /></Button>
                                    }
                        </div>
                    </li>
                ))}
            </ol>
        </>;
    }

    useEffect(() => {
        cargar();
        setCUITSeleccionado('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [showModalSesiones])

    useEffect(() => {
        const handleKeyDown = (event: { key: string; }) => {
          if (event.key === 'Insert') {
            handleCloseModalSesiones()
            iniciarSesion()
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup para evitar fugas de memoria
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    

    useEffect(() => {
        if (inicioSesion) {
            updateResulado([]);
            cargar();
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inicioSesion]);

    return (
        <>
            <LoginAFIP setShowModalSesiones={setShowModalSesiones} ref={loginAFIPRef} />
            <Modal show={showModalSesiones} onHide={handleCloseModalSesiones} dialogClassName="modal-nuevo_perfil"
                aria-labelledby="contained-modal-title-vcenter"
                centered  style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}>
                <Modal.Header >
                    <Modal.Title>Seleccionar sesión AFIP</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: 600, overflowY: 'scroll' }}>
                    <BlockUi blocking={cargando}  >
                        <GrillaDespachos despachantes={resulado}></GrillaDespachos>

                    </BlockUi>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 10 }} >
                        <Button variant='danger' onClick={() => handleCloseModalSesiones()}>Cerrar [ESC]</Button>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default ModalSesiones