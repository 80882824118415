import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Col, Table, Alert, DropdownButton, Dropdown } from "react-bootstrap";
//import { BaseSelect } from "BaseSelect";
//import styled from "styled-components";
import { MyForm, MyFormControl, MySelect, SelectOption } from "../../FormikHooks";
import * as Yup from "yup";
//import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
import { convertirDatosGenericosAOptionIdDescripcion } from "Utilidades";
import { useHistory } from "react-router";
import { LoginAFIP, LoginAFIPRef } from './sesionafip';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikProps } from "formik";
import ModalConvertirLicencia from "Paginas/advanced/ConvertirLicenciaModal/ModalConvertirLicencia";
import ModalPDFMaria from "Paginas/advanced/ProvisorioTable/ModalPDFMaria";
import { ObtenerDespachoFiltrosResponse } from "modelos/Advanced";
import BlockUi from "react-block-ui";

/*
const CustomSelect = styled(BaseSelect)`
    width:100%;
`;
*/
export function BuscarCaratula() {

    let api = useAdvAPI();
    //let refGrilla = useRef<GrillaRef>(null);
    let history = useHistory();
    let apiSintia = useApi();
    let [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    let [optionsImportadores, updateOptionsImportadores] = useState<SelectOption[]>([]);
    let [optionsAduana, updateoptionsAduana] = useState<SelectOption[]>([]);
    let [despachosNoResult, updateDespachosNoResult] = useState(false);
    let [resulado, updateREsulado] = useState([]);
    const [showModalPDFMaria, setShowModalPDFMaria] = useState(false)
    const [cargando, updateCargando] = useState(false)
    const [cargandoDespachantes, setCargandoDespachantes] = useState(false);
    const [cargandoImportadores, setCargandoImportadores] = useState(false);
    const [cargandoAduanas, setCargandoAduanas] = useState(false);
    const [nroDespacho, setNroDespacho] = useState('')
    let loginAFIPRef = useRef<LoginAFIPRef>(null);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }

    let schema = Yup.object({
        'cuitDespachante': Yup.string().nullable().required('Debe seleccionar un despachante'),
        'cuitImpoExpo': Yup.string().nullable().optional(),
        'aduana': Yup.string().nullable().required('Debe seleccionar una aduana'),
        'fechaDesde': Yup.date().required('Debe ingresar Fecha Desde'),
        'fechaHasta': Yup.date().required('Debe ingresar Fecha Hasta')
    });
    var d = new Date();
    let hoy = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
    ].join('-');


    const getDespachantes = async () => {
        setCargandoDespachantes(true)
        try {
            await apiSintia.getDespachantes().then((resp) => {
                updateOptionsDespachantes(resp.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                setCargandoDespachantes(false)
            })
        } catch (error) {
            setCargandoDespachantes(false)
        }
    }

    const getImportadores = async () => {
        setCargandoImportadores(true)
        try {
            await apiSintia.getImportadores().then((resp) => {
                updateOptionsImportadores(resp.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                setCargandoImportadores(false)
            })
        } catch (error) {
            setCargandoImportadores(false)
        }
    }

    const getAduanas = async () => {
        setCargandoAduanas(true)
        try {
            await apiSintia.getAduanas().then((resp) => {
                let options = convertirDatosGenericosAOptionIdDescripcion(resp)
                updateoptionsAduana(options);
                setCargandoAduanas(false)
            })
        } catch (error) {
            setCargandoAduanas(false)
        }
    }

    useEffect(() => {
        async function cargar() {
            try {
                getDespachantes()
                getImportadores()
                getAduanas()
            } catch (error) {
                if (!apiSintia.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);


    async function cargarDatos(pbusqueda: any) {
        updateCargando(true)
        if (pbusqueda.cuitDespachante) {

            /*let logueo = false;
            loginAFIPRef.current!.mostrar().finally(() => { logueo = true; })
            console.log(logueo);*/

            updateDespachosNoResult(false);
            let respuesta = await api.getDeclaracionesOficializadas(
                pbusqueda.fechaDesde,
                pbusqueda.fechaHasta,
                pbusqueda.cuitDespachante,
                pbusqueda.cuitImpoExpo,
                pbusqueda.aduana);


            // cuando FechaAVC es = "NO"
            // Iden

            if (respuesta.ListDespachosOficializados.length > 0) {
                let resultadoF = respuesta.ListDespachosOficializados.map((respuestan: any) => {
                    if (respuestan.FechaAVC === "NO") respuestan.IdentificadorAVC = "";
                    return respuestan;
                });
                updateREsulado(resultadoF);
                updateCargando(false)
            }
            else {
                updateREsulado([]);
                updateDespachosNoResult(true);
                updateCargando(false)
            }

        }
    }


    const showModalPDF = (row: ObtenerDespachoFiltrosResponse) => {
        setShowModalPDFMaria(true)
        // despachoID.current = row?.ID
        // nroProvisorio.current = row?.NumeroProvisorio
        // nroDespacho.current = row?.NroDespacho
        // estadoDespacho.current = row?.EstadoDescripcion
    }

    const contieneSIMIoSEDI = (cadena: string) => {
        const palabras = ["SIMI", "SEDI"];
        return palabras.some(palabra => cadena.includes(palabra));
    }

    function GrillaDespachos(props: {
        despachos: any[],
        eventoRatificar: (item: any) => void,
        eventoAVC: (item: any) => void
    }) {
        return <>
            <div style={{ height: 400, overflowX: 'scroll' }}>
                <Table style={{ overflowX: 'visible' }} className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Despacho</th>
                            <th scope="col">Fecha Of</th>
                            <th scope="col">Impo Expo</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Canal</th>
                            <th scope="col" className="text-right">A Pagar</th>
                            <th scope="col" className="text-right">A Garantizar</th>
                            <th scope="col">FechaAVC</th>
                            <th scope="col">Identif. AVC</th>
                            <th></th>
                            <th>Vista</th>
                        </tr>
                    </thead>
                    <tbody>{props.despachos.map((t: any, indice: number) =>
                    (
                        /*<tr className="cursor-pointer " key={t.Despacho} onClick={e => {
                            //let element = e.target as HTMLElement;
                            //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                            /*if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                                props.eventoAbrir(t);
                            }*/
                        //                        }}>*/}

                        <tr>
                            <th scope="row">{t.Despacho}</th>
                            <td>{t.FechaOficializacion}</td>
                            <td>{t.NombreImportador}</td>
                            <td>{t.Estado}</td>
                            <td>{t.Canal}</td>
                            <td className="text-right">{t.TotalAPagar}</td>
                            <td className="text-right">{t.TotalAGarantizar}</td>
                            <td>{t.FechaAVC}</td>
                            <td>{t.IdentificadorAVC}</td>
                            <td>
                                {/*
                                    <Button className="btn btn-sm btn-success mr-1"  onClick={e => {
                                        props.eventoRatificar(t);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>Ratificar</Button>
                                    */}

                            </td>
                            <td>
                                <DropdownButton drop="down" title="...">
                                    <Dropdown.Item as="button" onClick={e => {
                                        props.eventoAVC(t);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>Aviso de Carga</Dropdown.Item>
                                    {contieneSIMIoSEDI(t?.Despacho) && <Dropdown.Item as="button"><ModalConvertirLicencia despachanteNombre={optionsDespachantes?.filter((despachante) => despachante.value === formikRef?.current?.values?.cuitDespachante)?.[0]?.label} despachanteCuit={formikRef?.current?.values?.cuitDespachante} nroSimi={t?.Despacho} styleLink={true} /></Dropdown.Item>}
                                    <Dropdown.Item as="button" onClick={() => {
                                        setNroDespacho(t.Despacho)
                                        setShowModalPDFMaria(true)
                                    }}><button onClick={() => showModalPDF(t)} className="styleLink">Descargar PDF</button></Dropdown.Item>
                                </DropdownButton>
                            </td>

                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>


        </>;
    }

    let initialvalues = {
        cuitDespachante: '',
        cuitImpoExpo: '',
        aduana: '',
        fechaDesde: hoy,
        fechaHasta: hoy
    };

    function avidoDeCarga(item: any) {
        history.push('/advanced/avisodecargapost/' + encodeURIComponent(item.Despacho) + '|' + formikRef.current?.values?.cuitDespachante);
    }

    useEffect(() => {
        if(optionsDespachantes?.length === 1){
            formikRef?.current?.setFieldValue('cuitDespachante', optionsDespachantes?.[0]?.value)
        }
    }, [optionsDespachantes])

    return (
        <>
            <Container>
                <br /><p className="h3">Consulta de Declaraciones Oficializadas</p><br />
                <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={cargarDatos}>
                    <MyForm className="col-md-12">
                        <Form.Row className="col-md-12">
                            <Form.Group as={Col}>
                                <BlockUi blocking={cargandoDespachantes}>
                                    <MySelect
                                        name="cuitDespachante" label="Despachante" defaultValue={optionsDespachantes?.length === 1 && optionsDespachantes?.[0]}
                                        options={optionsDespachantes} onValueChange={eventoHuboCambios} />
                                </BlockUi>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="col-md-12">
                            <Form.Group as={Col} className="col-md-3" >
                                <MyFormControl name="fechaDesde" type="date" label="Fecha Desde" ></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-3" >
                                <MyFormControl name="fechaHasta" type="date" label="Fecha Hasta" ></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-6">
                                <BlockUi blocking={cargandoAduanas}>
                                    <MySelect
                                        name="aduana" label="Aduana"
                                        options={optionsAduana} onValueChange={eventoHuboCambios} />
                                </BlockUi>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group className="col-md-12">
                            <BlockUi blocking={cargandoImportadores}>
                                <MySelect
                                    name="cuitImpoExpo" label="Importador"
                                    options={optionsImportadores} onValueChange={eventoHuboCambios} />
                            </BlockUi>
                            <br />
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Button type="submit" className="mb-2" disabled={cargando}>{cargando ? 'Buscando..' : 'Buscar'}</Button>
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            {despachosNoResult &&
                                <Alert variant="warning">
                                    <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                                    <span className="ml-3">No hay Declaraciones para los paremetros seleccionados</span>
                                </Alert>
                            }
                        </Form.Group>
                    </MyForm>
                </Formik>

                <div className="mt-2">
                    <GrillaDespachos despachos={resulado}
                        eventoRatificar={(item: any) => ({})}
                        eventoAVC={(item: any) => avidoDeCarga(item)}></GrillaDespachos>
                </div>

            </Container>
            <ModalPDFMaria cargando={cargando} updateCargando={updateCargando} despachoID={0} nroDespacho={nroDespacho} nroProvisorio={'0'} setShowModalPDFMaria={setShowModalPDFMaria} showModalPDFMaria={showModalPDFMaria} estadoDespacho={'Oficializado'} />

            <LoginAFIP ref={loginAFIPRef} />
        </>

    )
}