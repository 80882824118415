import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { Alert, Table } from 'react-bootstrap'; // Asumiendo que estás usando react-bootstrap
import ModalFormItem from 'Paginas/advanced/ProvisorioTable/ModalFormItem';
import { ObtenerArticulosVistaResponse, ObtenerArticuosLiqVistaResponse, ObtenerItemVistaResponse } from 'modelos/Advanced';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ModalEditarSubItemSufijos from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarSubItemSufijos';
import { isNullOrWhiteSpace } from 'Utilidades';

interface propsRenderSwitchTablas {
    buttonSelected: string;
    tableDataItems: ObtenerItemVistaResponse[];
    tableDataItemsDetalle: any[];
    idDespacho: string;
    tableDataArticulos: ObtenerArticulosVistaResponse[];
    tableDataArticulosLiq: ObtenerArticuosLiqVistaResponse[]
    nroProvisorio: MutableRefObject<string | null>;
    setReloadForm: Dispatch<SetStateAction<boolean>>;
    estadoDespacho: string;
}

const sinResultados = () => {
    return (<>
        <Alert variant="warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span className="ml-3">No hay resultados.</span>
        </Alert>
    </>)
}

function formatNumber(num: any, decimales?: number) {
    let formattedNum = new Intl.NumberFormat('es-ES', {
        minimumFractionDigits: decimales ?? 2,
        maximumFractionDigits: decimales ?? 2
    }).format(num);

    return formattedNum;
}

const TablaGrilla = (props: React.PropsWithChildren<{ className?: string }>) => {
    let className;
    if (!isNullOrWhiteSpace(props.className)) {
        className = props.className + ' middle-vertical-align';
    } else {
        className = 'middle-vertical-align';
    }
    return (<Table bordered striped hover size="sm" className={className}>{props.children}</Table >);
}

const RenderSwitchTabla = ({ buttonSelected,estadoDespacho, tableDataItems,tableDataItemsDetalle, idDespacho, tableDataArticulos, setReloadForm, tableDataArticulosLiq, nroProvisorio }: propsRenderSwitchTablas) => {
  
    switch (buttonSelected) {
        case 'Items':
            return tableDataItems.length > 0 ? (
                <div className="table-responsives">
                    <TablaGrilla>
                        <thead>
                            <tr>
                                <th>ITEM</th>
                                <th>NCM</th>
                                <th>Lista</th>
                                <th>Origen</th>
                                <th>Procedencia</th>
                                <th>Unidad</th>
                                <th>Cantidad</th>
                                <th>Estadistica</th>
                                <th>Cantidad Est.</th>
                                <th>Kilo Neto</th>
                                <th>FOB</th>
                                <th>FLETE</th>
                                <th>SEGURO</th>
                                <th>Aj Inc</th>
                                <th>Aj Ded</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableDataItems.sort((a, b) => (a.ID === 0 ? -1 : b.ID === 0 ? 1 : 0)).map((item: any) => (
                                <ModalFormItem estadoDespacho={estadoDespacho} setReloadFormProvisorio={setReloadForm} item={item} id={item.NARTEXT} tableDataItems={tableDataItems} despachoID={idDespacho} nroProvisorio={nroProvisorio} />

                            ))}
                        </tbody>
                    </TablaGrilla>
                </div>
            ) : sinResultados();
        case 'ItemsDetalle':
            return tableDataItems.length > 0 ? (
                <div className="table-responsives">
                    <TablaGrilla>
                        <thead>
                            <tr>
                                <th>ITEM</th>
                                <th>NCM</th>
                                <th>Lista</th>
                                <th>Origen</th>
                                <th>Procedencia</th>
                                <th>Ventajas</th>
                                <th>Info Adicional</th>
                                <th>Documentos</th>
                            </tr>
                        </thead>

                        <tbody>
                                {tableDataItemsDetalle.sort((a, b) => (a.ID === 0 ? -1 : b.ID === 0 ? 1 : 0)).map((item: any) => (
                                <ModalFormItem estadoDespacho={estadoDespacho} itemDetalle={true} setReloadFormProvisorio={setReloadForm} item={item} id={item.NARTEXT} tableDataItems={tableDataItemsDetalle} despachoID={idDespacho} nroProvisorio={nroProvisorio} />

                            ))}
                        </tbody>
                    </TablaGrilla>
                </div>
            ) : sinResultados();
        case 'Articulos':
            return tableDataArticulos?.length > 0 ?
                <div className="table-responsives">
                    <Table style={{
                        'fontSize': '0.9rem',
                        'width': '100%',
                    }}
                        className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Item</th>
                                <th>SubIt</th>
                                <th>NCM</th>
                                <th>Sufijos</th>
                                <th>Unidad</th>
                                <th>Cantidad</th>
                                <th>Estadistica</th>
                                <th>Cantidad Est.</th>
                                <th>Unitario</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableDataArticulos?.map((articulo: any, index: any) =>
                            (
                                <tr className="cursor-pointer " key={index}>
                                    <td>{articulo.IEXT}</td>
                                    <td>{articulo.NARTEXT}</td>
                                    <td>{articulo.ISBT}</td>
                                    <td>{articulo.IESPNCE}</td>
                                    <td><ModalEditarSubItemSufijos estadoDespacho={estadoDespacho} despachoID={idDespacho} ISBT={articulo?.ISBT} NART={articulo?.NART} IDDT={articulo?.IDDT} setReloadForm={setReloadForm} sufijosData={articulo?.CSBTSVL} id={articulo?.ID} /></td>
                                    <td>{articulo.CARTUNTDCL}</td>
                                    <td style={{ textAlign: 'right' }}>{formatNumber(articulo.CANTIDAD)}</td>
                                    <td>{articulo.CARTUNTEST}</td>
                                    <td style={{ textAlign: 'right' }}>{formatNumber(articulo.CANTIDAD_ESTADISTICA)}</td>
                                    <td style={{ textAlign: 'right' }}>{formatNumber(articulo.FOB_UNITARIO, 5)}</td>
                                    <td style={{ textAlign: 'right' }}>{formatNumber(articulo.FOB_TOTAL)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div> : sinResultados();
        case 'Liquidación':
            return tableDataArticulosLiq?.length > 0 ?
                <div className="table-responsives">
                    <Table style={{
                        'fontSize': '0.9rem',
                        'width': '100%',
                    }}
                        className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>ITEM</th>
                                <th>NCM</th>
                                <th>FOB Dol</th>
                                <th>Seguro</th>
                                <th>Flete</th>
                                <th>Aj Inc</th>
                                <th>Aj Ded</th>
                                <th>Lista</th>
                                <th>Código</th>
                                <th>Descripción</th>
                                <th>Tipo</th>
                                <th>Base</th>
                                <th>Coef</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>{tableDataArticulosLiq?.map((articulo: any, index: any) =>
                        (
                            <tr className="cursor-pointer" key={index}>
                                <th>{articulo.NARTEXT}</th>
                                <th>{articulo.IESPNCE}</th>
                                <th>{articulo.IESPNCE}</th>
                                <th>{articulo.MARTASS}</th>
                                <th>{articulo.MARTFLE}</th>
                                <th>{articulo.MARTAJUINC}</th>
                                <th>{articulo.MARTAJUDED}</th>
                                <th>{articulo.LISTA}</th>
                                <th>{articulo.NART}</th>
                                <th>{articulo.LTAXABR}</th>
                                <th>{articulo.CLQATYP}</th>
                                <th>{articulo.MLQABAS}</th>
                                <th>{articulo.QLQACOEFIC}</th>
                                <th>{articulo.MLQA}</th>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div> : sinResultados();
        default:
            return <p>Sin Resultados.</p>;
    }
};

export default RenderSwitchTabla;