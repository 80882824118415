import React from "react";
import BlockUi from "react-block-ui";
import { Button, Table } from "react-bootstrap";
import { AppContext } from "../../../App";
import { useApi } from "ApiHooks";

export function VistaPrevia(props: { articulos: Array<any>, onContinue: () => void, onCancel: () => void }) {
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cargaTerminada') {
            return {
                cargando: false, paises: accion.paises, unidades: accion.unidades,
                estadosMerc: accion.estadosMerc, articulosExisten: accion.articulosExisten
            }
        }
        return { ...estado };
    }, { cargando: true });
    React.useEffect(() => {
        async function cargar() {
            try {
                let paises = await api.getPaises();
                let unidades = await api.getUnidades();
                let estadosMerc = await api.getEstadosMercaderia();
                let resultado = await api.articulosExisten(props.articulos.map((item: any) => ({ CodigoCatalogo: item.CodigoCatalogo, CodigoArticulo: item.CodigoArticulo })));
                updateEstado({ tipo: 'cargaTerminada', paises: paises, unidades: unidades, estadosMerc: estadosMerc, articulosExisten: resultado });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar vista previa', error);
                    mostrarError('Error al cargar vista previa');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
        return () => api.cancelCurrentTokens();
        //eslint-disable-next-line 
    }, [props.articulos]);
    return (<BlockUi style={{ minHeight: '8em' }} renderChildren={false} blocking={estado.cargando} message="Viendo si los articulos ya existen en el catalogo...">
        <Button onClick={props.onContinue} className="mb-2 mr-2">Transferir al catalogo</Button>
        <Button variant="danger" onClick={props.onCancel} className="mb-2">Cancelar</Button>
        <Table bordered responsive className="text-nowrap">
            <thead>
                <tr>
                    <th>Catalogo</th>
                    <th>Nro. Articulo</th>
                    <th>Existe en Catalogo</th>
                    <th>NCM</th>
                    <th>Pais Origen</th>
                    <th>Descripcion Catalogo</th>
                    <th>Descripcion María</th>
                    <th>Estado Mercadería</th>
                    <th>Kg Unitario</th>
                    <th>Unidad Declarada</th>
                    <th>Sufijos</th>
                    <th>Marca</th>
                    <th>Modelo</th>
                    <th>Presentacion</th>
                    <th>Filler</th>
                    <th>Sufijo AB/AI</th>
                    <th>Sufijo AS</th>
                    <th>Sufijos Parcial</th>
                    <th>Sufijo ZA</th>
                    <th>Sufijo ZB</th>
                    <th>Sufijo ZC</th>
                    <th>Estado Articulo</th>
                </tr>
            </thead>
            <tbody>
                {props.articulos.map((articulo: any) => {
                    let pais = estado.paises?.find((pais: any) => pais.Codigo === articulo.CodigoPaisOrigen);
                    let unidad = estado.unidades?.find((unidad: any) => unidad.Codigo === articulo.CodigoUnidadDeclarada);
                    let estadoMerc = estado.estadosMerc?.find((estadoMerc: any) => estadoMerc.Codigo === articulo.CodigoEstadoMercaderia);
                    let articuloExiste = estado.articulosExisten?.find((item: any) => item.CodigoCatalogo === articulo.CodigoCatalogo && item.CodigoArticulo === articulo.CodigoArticulo)?.Existe ?? false;
                    return (<tr key={articulo.CodigoCatalogo + '-' + articulo.CodigoArticulo}>
                        <td>{articulo.CodigoCatalogo}</td>
                        <td>{articulo.CodigoArticulo}</td>
                        <td>{articuloExiste ? 'Sí' : 'No'}</td>
                        <td>{articulo.Ncm}</td>
                        <td>{pais?.Descripcion ?? articulo.CodigoPaisOrigen}</td>
                        <td>{articulo.Descripcion}</td>
                        <td>{articulo.DescripcionMaria}</td>
                        <td>{estadoMerc?.Descripcion ?? articulo.CodigoEstadoMercaderia}</td>
                        <td className="text-right">{articulo.KgNetoUnitario}</td>
                        <td>{unidad?.Descripcion ?? articulo.CodigoUnidadDeclarada}</td>
                        <td>{articulo.Sufijos}</td>
                        <td>{articulo.Marca}</td>
                        <td>{articulo.Modelo}</td>
                        <td>{articulo.Presentacion}</td>
                        <td>{articulo.Filler}</td>
                        <td>{articulo.SufijoABAI}</td>
                        <td>{articulo.SufijoAS}</td>
                        <td>{articulo.SufijosParcial}</td>
                        <td>{articulo.SufijoZA}</td>
                        <td>{articulo.SufijoZB}</td>
                        <td>{articulo.SufijoZC}</td>
                        <td>{articulo.Estado}</td>
                    </tr>)
                })}
            </tbody>
        </Table>
    </BlockUi >);
}