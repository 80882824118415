import { useApi } from 'ApiHooks';
import { MySelect, MySelectConOptionABM, SelectOption } from 'FormikHooks';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import { convertirDatosGenericosAOption, isNullOrWhiteSpace, optionLabelConCodigo } from 'Utilidades';
import { Formik, FormikProps } from 'formik';
import { Caratula, InsertCPLBody, InsertDVDBody, UpdateDespachoCaratulaBody } from 'modelos/Advanced';
import React, { useEffect, useReducer, useRef, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Modal, Form, Col, Container, Card, Tabs, Tab, Table, Alert } from 'react-bootstrap';
import './styleTable.css'
import Loader from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ModalEditarInfComp from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarInfComp';
import ModalEditarFacturas from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarFacturas';
import ModalEliminarFacturasInfComp from 'Paginas/advanced/ProvisorioTable/ModalEliminarFacturasInfCompVentajas';
import { ModalImportadores } from 'Paginas/Importadores/Importadores';

interface props {
    id: string,
    estadoDespacho: string,
    reloadDespachoData: () => Promise<void>
}
enum EstadoCargando {
    CargaInicial,
    Cargando,
    Listo
}
const ModalFormCaratula = ({ id, estadoDespacho, reloadDespachoData }: props) => {

    const estadoModalReducer = (estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, valorSeleccionado: null };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, valorSeleccionado: accion.valor };
        }
        return estado;
    };
    const valorInicialEstadoModal = { abierto: false, valorSeleccionado: null };
    const [estadoModalImportadores, updateEstadoModalImportadores] = useReducer(estadoModalReducer, valorInicialEstadoModal);
    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    const [despachoCaratulaForm, setDespachoCaratulaForm] = useState<Caratula>();
    const [tableInformacionComp, setTableInformacionComp] = useState<any>([]);
    const [tableFacturas, setTableFacturas] = useState<any[]>([]);
    const [mensajeExitoInsertDVD, setMensajeExitoInsertDVD] = useState(null);
    const [mensajeExitoInsertCPL, setMensajeExitoInsertCPL] = useState(null);
    const [optionsImportadores, updateOptionsImportadores] = useState<SelectOption[]>([]);
    const [reloadForm, setReloadForm] = useState(false);
    const [tabActive, setTabActive] = useState<string>("montos");
    const [mensajeErrorServidor, setMensajeErrorServidor] = useState('')
    const [cargandoModalImportadores, updateCargandoModalImportadores] = useState<EstadoCargando>(EstadoCargando.CargaInicial);
    const [showModalConfirmarCaratula, setShowModalConfirmarCaratula] = useState(false)
    const [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    const [dataDespachoCaratula, setDataDespachoCaratula] = useState<UpdateDespachoCaratulaBody>()
    let formikRef = React.useRef<FormikProps<any>>(null);

    let numeroFacturaRef = useRef<HTMLInputElement>(null);
    let infComplementariaRef = useRef<HTMLInputElement>(null);
    let huboCambios = useRef({ valor: false });

    const handleClose = () => {
        setShow(false)
        setMensajeErrorServidor('')
        setMensajeExitoInsertDVD(null)
        setMensajeExitoInsertCPL(null)
        reloadDespachoData()
    }
    const handleShow = () => setShow(true);
    let api = useAdvAPI();
    let apiSintia = useApi();

    const getDespachantes = async () => {
        updateCargando(true)
        try {
            await apiSintia.getDespachantes().then((resp) => {
                updateOptionsDespachantes(resp.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                updateCargando(false)
            })
        } catch (error) {
            updateCargando(false)
        }
    }

    const cargarDatosForm = async () => {
        updateCargando(true)
        try {
            let dataDespachoCaratula = await api.getObtenerDespachoCaratula(id)
            setDespachoCaratulaForm(dataDespachoCaratula?.Caratula)
            setTableInformacionComp(dataDespachoCaratula?.CPL)
            setTableFacturas(dataDespachoCaratula?.DVD)
            setReloadForm(false)
            updateCargando(false);
        } catch (error) {
            if (!apiSintia.isCancel(error)) {
                console.error('Error al cargar vistas', error);
                updateCargando(false);
            }
        }
    }

    const updateDespachoCaratula = async (dataDespacho: UpdateDespachoCaratulaBody) => {
        updateCargando(true)
        try {
            await api.updateDespachoCaratula(dataDespacho)
            setMensajeErrorServidor('')
            updateCargando(false)
            setShowModalConfirmarCaratula(false)
            handleClose()
        } catch (error) {
            updateCargando(false)
            setMensajeErrorServidor('Error del servidor al guardar la Caratula')
            console.log('error', error)
        }
    }

    const datosGlobalesBorrarFacturas = async () => {
        updateCargando(true)
        try {
            await api.datosGlobalesBorrarFacturas(id)
            updateCargando(false);
            handleClose()
        } catch (error) {
            updateCargando(false);
            console.log('error', error)
        }
    }

    const DatosGlobalesInsertarFactura = async (bodyFactura: any) => {
        updateCargando(true)
        try {
            await api.DatosGlobalesInsertarFactura(bodyFactura)
            updateCargando(false);
            cargarDatosForm()
            numeroFacturaRef.current?.focus()
        } catch (error) {
            updateCargando(false);
        }
    }

    const insertDVD = async (dataDVD: InsertDVDBody) => {
        updateCargando(true)
        try {
            let insertDVDResponse = await api.insertDVD(dataDVD)
            setMensajeExitoInsertDVD(insertDVDResponse?.Descripcion)
            cargarDatosForm()
            updateCargando(false);
        } catch (error) {
            updateCargando(false);

            console.log('error', error)
        }
    }

    const insertCPL = async (dataCPL: InsertCPLBody) => {
        updateCargando(true)
        try {
            await api.insertCPL(dataCPL)
            cargarDatosForm()
            updateCargando(false)
        } catch (error) {
            updateCargando(false)
        }
    }

    async function getOptionsInformacionComplementaria() {
        const filterInfoComp = [
            'SIMI-DESC-MERC01',
            'SIMI-DESC-MERC02',
            'SIMI-DESC-MERC03',
            'SIMI-DESC-MERC04',
            'SIMI-DESC-MERC05',
            'SIMI-DESC-MERC06',
            'SIMI-DESC-MERC07',
            'SIMI-DESC-MERC08',
            'SIMI-DESC-MERC09',
            'SIMI-DESC-MERC10',
            'SIMI-PLAZO-GIRO',
            'SIMI-FECHA-ARRIB',
            'FECHA INIC.ACTIV',
            'DOMICIL.ESTABLEC',
            'IDTRIB-PROVEEDOR'
        ];
        let infComps = await apiSintia.getInformacionComplementaria();
        infComps = infComps.filter((item: any) => !filterInfoComp.includes(item.Codigo));
        return convertirDatosGenericosAOption(infComps);
    }

    function existeCUIT(cuit: string, lista: any) {
        return lista.some((cliente: { CUIT: string; }) => cliente.CUIT === cuit);
    }

    const existeLDVDREFDOC = (arrayObjetos: Array<{ LDVDREFDOC: string }>, valor: string): boolean => {
        return arrayObjetos?.some(objeto => objeto?.LDVDREFDOC === valor);
    };

    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }


    async function cargarImportadores(valorSeleccionado?: string) {
        let respuesta = await apiSintia.getImportadores();
        let array = respuesta.map((item: any) => ({ value: item.CUIT, label: item.Nombre }));
        updateOptionsImportadores(array);
        if (!isNullOrWhiteSpace(valorSeleccionado)) {
            formikRef.current?.setFieldValue('NDDTIMMIOE', valorSeleccionado);
            // await onCuitImportadorChange(valorSeleccionado);
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            const inputs = Array.from(e.currentTarget.querySelectorAll('input, select, textarea')) as Array<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
            const currentIndex = inputs.indexOf(document.activeElement as HTMLInputElement);

            // Define el siguiente índice que intentaremos enfocar
            let nextIndex = currentIndex + 1;

            // Verifica si el siguiente input está habilitado
            while (nextIndex < inputs.length) {
                if (!inputs[nextIndex].disabled) {
                    inputs[nextIndex].focus();
                    return; // Salimos de la función después de enfocar
                }
                nextIndex++; // Avanza al siguiente índice
            }

            // Si no se encontró uno habilitado, busca el siguiente índice más allá
            if (nextIndex < inputs.length && !inputs[nextIndex].disabled) {
                inputs[nextIndex].focus();
            }
        }
    };

    useEffect(() => {
        if (show || reloadForm) {
            getDespachantes()
            cargarDatosForm()
            cargarImportadores()
            getOptionsInformacionComplementaria()
        }

        // eslint-disable-next-line
    }, [show, reloadForm])

    useEffect(() => {
        if(optionsDespachantes?.length === 1){
            formikRef.current?.setFieldValue('despachante',optionsDespachantes?.[0]?.value)
        }
    }, [optionsDespachantes])
    

    useEffect(() => {
        async function cargar() {
            try {
                updateCargandoModalImportadores(EstadoCargando.Cargando);
                await cargarImportadores(estadoModalImportadores.valorSeleccionado);
                updateCargandoModalImportadores(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar clientes', error);
                    // mostrarError('Error al cargar clientes');
                    updateCargandoModalImportadores(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalImportadores.abierto && cargandoModalImportadores !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalImportadores.abierto]);

    return (
        <>

            <Button onClick={handleShow} className='' variant="outline-primary">Caratula</Button>
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Declaración Detallada</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando} loader={<Loader active={cargando} type='ball-beat' />} message="Modificando caratula..">
                    {!cargando && <Modal.Body>
                        <Container>
                            {mensajeErrorServidor?.length > 0 && <Alert variant="danger">{mensajeErrorServidor}</Alert>}
                            <Formik
                                innerRef={formikRef}
                                onSubmit={(values) => {
                                    let dataUpdateDespachoCaratula: UpdateDespachoCaratulaBody = {
                                        "DespachoId": Number(id),
                                        "CDDTBURDST": null,
                                        "CDDTPAIDST": values.CDDTPAIDST,
                                        "LDDTNOMFOD": values.LDDTNOMFOD,
                                        "NDDTIMMIOE": values.NDDTIMMIOE,
                                        "CDDTDEVASS": values.CDDTDEVASS,
                                        "CDDTDEVFLE": values.CDDTDEVFLE,
                                        "CDDTDEVFOB": values.CDDTDEVFOB,
                                        "MDDTASS": Number(values?.MDDTASS) === 0 ? null : Number(values.MDDTASS),
                                        "MDDTFLE": Number(values?.MDDTFLE) === 0 ? null : Number(values.MDDTFLE),
                                        "MDDTFOB": Number(values.MDDTFOB),
                                        "CDDTINCOTE": values.CDDTINCOTE,
                                        "CDDTFACPAG": null,
                                        "CDDTIVA": values.CDDTIVA,
                                        "TPR_MTPRAJUINC": Number(values?.TPR_MTPRAJUINC) === 0 ? null : Number(values.TPR_MTPRAJUINC),
                                        "TPR_MTPRAJUDED": Number(values?.TPR_MTPRAJUDED) === 0 ? null : Number(values.TPR_MTPRAJUDED),
                                        "IEXTS_Referencia": values?.IEXTS_Referencia,
                                        "IEXT": values?.IEXT
                                    }
                                    if (estadoDespacho === 'Validado') {
                                        setShowModalConfirmarCaratula(true)
                                        setDataDespachoCaratula(dataUpdateDespachoCaratula)
                                    } else {
                                        updateDespachoCaratula(dataUpdateDespachoCaratula)
                                    }
                                    if (values.presenciaFacturas === 'N') {
                                        datosGlobalesBorrarFacturas()
                                        insertDVD({
                                            ID: null,
                                            DespachoId: id,
                                            CDVDDOC: "FACTURACOMERCIAL",
                                            IDVD: despachoCaratulaForm?.IDDT!,
                                            LDVDREFDOC: "",
                                            NART: "0000",
                                            CDVDPRSDOC: "N"
                                        })
                                    }
                                }}
                                initialValues={{
                                    organismo: '',
                                    destinacion: despachoCaratulaForm?.ISTA || '',
                                    declaracion: despachoCaratulaForm?.IDDT || '',
                                    procedimiento: despachoCaratulaForm?.CDDTPRD || '',
                                    despachante: despachoCaratulaForm?.CDDTAGR || '',
                                    NDDTIMMIOE: despachoCaratulaForm?.NDDTIMMIOE || '',
                                    CDDTIVA: despachoCaratulaForm?.CDDTIVA || '',
                                    LDDTNOMFOD: despachoCaratulaForm?.LDDTNOMFOD || '',
                                    CDDTPAIDST: despachoCaratulaForm?.CDDTPAIDST || '',
                                    CDDTBURDST: despachoCaratulaForm?.CDDTBURDST || '',
                                    CDDTDEVFOB: despachoCaratulaForm?.CDDTDEVFOB || '',
                                    MDDTFOB: despachoCaratulaForm?.MDDTFOB || '',
                                    CDDTDEVFLE: despachoCaratulaForm?.CDDTDEVFLE || '',
                                    MDDTFLE: despachoCaratulaForm?.MDDTFLE || 0,
                                    CDDTDEVASS: despachoCaratulaForm?.CDDTDEVASS || '',
                                    MDDTASS: despachoCaratulaForm?.MDDTASS || 0,
                                    TPR_MTPRAJUINC: despachoCaratulaForm?.TPR_MTPRAJUINC || 0,
                                    TPR_MTPRAJUDED: despachoCaratulaForm?.TPR_MTPRAJUDED || 0,
                                    CDDTINCOTE: despachoCaratulaForm?.CDDTINCOTE || '',
                                    CDDTFACPAG: despachoCaratulaForm?.CDDTFACPAG || '',
                                    CCPL: '',
                                    MCPL: '',
                                    porcentajeSeguro: '',
                                    LDVDREFDOC: '',
                                    presenciaFacturas: tableFacturas?.some((factura: any) => factura?.CDVDPRSDOC === 'S') ? 'S' : tableFacturas?.some((factura: any) => factura?.CDVDPRSDOC === 'N') ? 'N' : 'Seleccionar',
                                    IEXTS_Referencia: despachoCaratulaForm?.IEXTS_Referencia || '',
                                    IEXT: despachoCaratulaForm?.IEXT || ''
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors
                                }) => (
                                    <Form onKeyDown={handleKeyDown}
                                        noValidate onSubmit={handleSubmit}>
                                        <div>
                                            <Form.Row>
                                                <Form.Group as={Col} md="6" controlId="validationFormik103">
                                                    <Form.Label>Referencia Cliente</Form.Label>
                                                    <Form.Control
                                                        type="text"

                                                        style={{ height: 38 }}
                                                        name="IEXTS_Referencia"
                                                        value={values.IEXTS_Referencia}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" controlId="validationFormik103">
                                                    <Form.Label>Identificador Propio</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        style={{ height: 38 }}
                                                        name="IEXT"
                                                        value={values.IEXT}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                {/* <Form.Group as={Col} md="12">
                                                    <MySelect
                                                        name="organismo" label='Organismo'
                                                        isDisabled
                                                        options={[{
                                                            value: '', label: ''
                                                        }]} onValueChange={(e) => e} />
                                                </Form.Group> */}
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="12">
                                                    <MySelect
                                                        name="destinacion" label='Destinación'
                                                        isDisabled
                                                        options={() => apiSintia.getSubregimenes().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={(value) => `${value.value} - ${value.label}`}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="3" controlId="validationFormik103">
                                                    <Form.Label>Declaración</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        style={{ height: 38 }}
                                                        name="declaracion"
                                                        value={values.declaracion}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="2" controlId="validationFormik104">
                                                    <Form.Label>Procedimiento</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ height: 38 }}
                                                        disabled
                                                        name="procedimiento"
                                                        value={values.procedimiento}
                                                        onChange={handleChange}
                                                    />

                                                </Form.Group>
                                                <Form.Group as={Col} md="5" lg="6" controlId="validationFormik105">
                                                    <MySelect
                                                        name="despachante" label='Despachante'
                                                        isDisabled
                                                        defaultValue={optionsDespachantes?.length === 1 && optionsDespachantes?.[0]?.value}
                                                        options={optionsDespachantes}
                                                        getOptionLabel={(value) => `${value.value} - ${value.label}`}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="8">
                                                    <MySelectConOptionABM name="NDDTIMMIOE" label='Import/Export'
                                                        getOptionLabel={optionLabelConCodigo}
                                                        options={optionsImportadores}
                                                        labelOptionABM="Nuevo cliente..."
                                                        onSelectABM={() => {
                                                            setShow(false)
                                                            updateEstadoModalImportadores({ tipo: 'mostrar' })
                                                        }}
                                                        onValueChange={(e) => {
                                                            eventoHuboCambios();
                                                        }}></MySelectConOptionABM>
                                                    {existeCUIT(values?.NDDTIMMIOE, optionsImportadores) && <p style={{ color: 'red' }}>El CUIT {values?.NDDTIMMIOE} no existe en el sistema</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="2">
                                                    <MySelect
                                                        name="CDDTIVA" label='IVA Inscripto'
                                                        options={[{
                                                            value: 'N',
                                                            label: 'N'
                                                        }, {
                                                            value: 'S',
                                                            label: 'S',
                                                        }]}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="5" controlId="validationFormik103">
                                                    <Form.Label>Compr./Vend.</Form.Label>
                                                    <Form.Control
                                                        style={{ height: 38 }}
                                                        type="text"
                                                        name="LDDTNOMFOD"
                                                        value={values.LDDTNOMFOD}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3">
                                                    <MySelect name="CDDTPAIDST" label="Pais Proc./Dest."
                                                        getOptionLabel={optionLabelConCodigo} options={() => apiSintia.getPaises().then(convertirDatosGenericosAOption)}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" lg="3">
                                                    <MySelect name="CDDTBURDST" label="Aduana Dest./Sal."
                                                        getOptionLabel={optionLabelConCodigo}
                                                        onValueChange={e => e}
                                                        options={() => apiSintia.getAduanas().then(convertirDatosGenericosAOption)}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </div>

                                        <Card>
                                            <Tabs
                                                defaultActiveKey={tabActive}
                                                className="mb-3"
                                                variant="pills"
                                                onSelect={(e) => setTabActive(e!)}
                                            >
                                                <Tab eventKey="general" title="General" disabled>
                                                    General
                                                </Tab>
                                                <Tab eventKey="montos" title="Montos">
                                                    <Card.Body>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" lg="3">
                                                                <MySelect name="CDDTDEVFOB" label="FOB Total"
                                                                    getOptionLabel={optionLabelConCodigo}
                                                                    onValueChange={e => {
                                                                        formikRef.current?.setFieldValue('CDDTDEVFOB', e)
                                                                    }}
                                                                    options={() => apiSintia.getMonedas().then(convertirDatosGenericosAOption)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2" >
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 26, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MDDTFOB"
                                                                    value={values.MDDTFOB}
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        if (values?.CDDTDEVFOB === values?.CDDTDEVFLE) {
                                                                            setFieldValue('porcentajeSeguro', Number(values?.MDDTFLE) + Number(e?.target?.value))
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" lg="3" >
                                                                <MySelect
                                                                    name="CDDTFACPAG" label='Facilidad de Pago' isDisabled
                                                                    options={[{ value: despachoCaratulaForm?.CDDTFACPAG, label: despachoCaratulaForm?.CDDTFACPAG }, { value: '', label: '' }]} onValueChange={(e) => e} />
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" lg="3">
                                                                <MySelect name="CDDTDEVFLE" label="Flete Total"
                                                                    getOptionLabel={optionLabelConCodigo}
                                                                    onValueChange={e => {
                                                                        formikRef.current?.setFieldValue('CDDTDEVFLE', e)
                                                                    }}
                                                                    options={() => apiSintia.getMonedas().then(convertirDatosGenericosAOption)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2" >
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 26, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MDDTFLE"
                                                                    value={values.MDDTFLE}
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        if (values?.CDDTDEVFOB === values?.CDDTDEVFLE) {
                                                                            setFieldValue('porcentajeSeguro', Number(e?.target?.value) + Number(values?.MDDTFOB))
                                                                        }
                                                                    }} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="3">
                                                                <Form.Label>Ajuste a incluir</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="TPR_MTPRAJUINC"
                                                                    value={values.TPR_MTPRAJUINC}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group style={{ height: 37, marginTop: 0 }}
                                                                as={Col} md="4" lg="3">
                                                                <Form.Label>Porcentaje Seg</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="porcentajeSeguro"
                                                                    value={values.porcentajeSeguro}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>

                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" lg="3">
                                                                <MySelect name="CDDTDEVASS" label="Seguro Total"
                                                                    getOptionLabel={optionLabelConCodigo}
                                                                    onValueChange={e => e}
                                                                    options={() => apiSintia.getMonedas().then(convertirDatosGenericosAOption)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2" >
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 26, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MDDTASS"
                                                                    value={values.MDDTASS}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" lg="3">
                                                                <Form.Label>Ajuste a deducir</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="TPR_MTPRAJUDED"
                                                                    value={values.TPR_MTPRAJUDED}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group style={{ height: 37, marginTop: 0 }}
                                                                as={Col} md="3" lg="4">
                                                                <MySelect name="CDDTINCOTE" label="Condición Venta"
                                                                    getOptionLabel={optionLabelConCodigo} onValueChange={e => e}
                                                                    options={() => apiSintia.getCondicionesVenta().then(convertirDatosGenericosAOption)} />
                                                            </Form.Group>

                                                        </Form.Row>
                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="infoComp" title="Información Complementaria" >
                                                    <Card.Body>
                                                        {mensajeExitoInsertCPL !== null && <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -20 }}><Alert style={{ fontSize: 15, fontWeight: 'bold' }} variant='primary'> <FontAwesomeIcon icon={faInfoCircle} />{mensajeExitoInsertCPL}</Alert ></Container>}
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" lg="6">
                                                                <MySelect name="CCPL" label="Código" onValueChange={(e) => e}
                                                                    ref={infComplementariaRef}
                                                                    autoFocus
                                                                    options={getOptionsInformacionComplementaria}
                                                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="5" lg="3" className='col-lg-3' >
                                                                <Form.Label>Valor:</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    name="MCPL"
                                                                    value={values.MCPL}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" className='col-lg-3'>
                                                                <Button variant='primary' className='buttonInsertar' style={{ width: 120 }} disabled={values?.MCPL?.length === 0 || values?.CCPL?.length === 0} onClick={() => {
                                                                    insertCPL({
                                                                        ICPL: despachoCaratulaForm?.IDDT!,
                                                                        NART: "0000",
                                                                        CCPL: values?.CCPL,
                                                                        ICPLDIF: "S",
                                                                        MCPL: values?.MCPL,
                                                                        AutomatizadorMaria_DDTID: id
                                                                    })
                                                                    numeroFacturaRef.current?.focus()
                                                                }}>Insertar</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Código</th>
                                                                        <th>Valor</th>
                                                                        <th>Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {tableInformacionComp.map((row: any, index: number) => (
                                                                        <>
                                                                            <tr>
                                                                                <td>{row.CCPL}</td>
                                                                                <td>{row.MCPL}</td>
                                                                                <td>
                                                                                    <ModalEditarInfComp id={row?.ID} AutomatizadorMaria_DDTID={row?.AutomatizadorMaria_DDTID} ICPL={row?.ICPL} ICPLDIF={row?.ICPLDIF} NART={row?.NART} CCPL={row?.CCPL} MCPL={row?.MCPL} setReloadForm={setReloadForm} />
                                                                                    <ModalEliminarFacturasInfComp ID={row?.ID} typeModal={0}
                                                                                        triggerSubmit={cargarDatosForm} nroInfComp={row?.CCPL} />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </Form.Row>
                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="facturas" title="Facturas" >
                                                    <Card.Body>
                                                        {mensajeExitoInsertDVD !== null && <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -20 }}><Alert style={{ fontSize: 15, fontWeight: 'bold' }} variant='primary'> <FontAwesomeIcon icon={faInfoCircle} />{mensajeExitoInsertDVD}</Alert ></Container>}
                                                        {(values.presenciaFacturas === 'N' && tableFacturas?.[0]?.CDVDPRSDOC === 'S') && <Alert key={'danger'} variant={'danger'} style={{ marginTop: 5 }}>
                                                            Se borrarán todás las facturas existentes al presionar Guardar
                                                        </Alert>}
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="7" lg="5">
                                                                <MySelect
                                                                    name="presenciaFacturas" label='Presencia de Todas las Facturas'
                                                                    options={[{
                                                                        value: 'S',
                                                                        label: 'S',
                                                                    }, {
                                                                        value: 'N',
                                                                        label: 'N',
                                                                    }]}
                                                                    onValueChange={(e) => {
                                                                        if (e === 'N') {
                                                                            setFieldValue('LDVDREFDOC', '')
                                                                        }
                                                                    }} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" lg="3"  >
                                                                <Form.Label>Número de Factura:</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    autoFocus
                                                                    ref={numeroFacturaRef}
                                                                    disabled={values.presenciaFacturas === 'N' || values.presenciaFacturas === '' || values.presenciaFacturas === null}
                                                                    name="LDVDREFDOC"
                                                                    value={values.LDVDREFDOC}
                                                                    onChange={handleChange}
                                                                />
                                                                {existeLDVDREFDOC(tableFacturas, values?.LDVDREFDOC) && <p style={{ color: 'red' }}>Ya existe una factura con ese número.</p>}

                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" className='col-lg-2'>
                                                                <Button variant='primary' className='buttonInsertarFactura' style={{ width: 120 }} onClick={() => {
                                                                    DatosGlobalesInsertarFactura({
                                                                        ID: null,
                                                                        DespachoId: id,
                                                                        CDVDDOC: "FACTURACOMERCIAL",
                                                                        IDVD: despachoCaratulaForm?.IDDT!,
                                                                        LDVDREFDOC: values?.LDVDREFDOC,
                                                                        NART: "0000",
                                                                        CDVDPRSDOC: values.presenciaFacturas
                                                                    })
                                                                    setFieldValue('LDVDREFDOC', '')
                                                                }
                                                                } disabled={values.presenciaFacturas !== 'S' || values.LDVDREFDOC?.length === 0 || existeLDVDREFDOC(tableFacturas, values?.LDVDREFDOC)}>Insertar</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            {(values.presenciaFacturas !== 'N' && tableFacturas?.[0]?.CDVDPRSDOC === 'S') && <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Referencia</th>
                                                                        <th>Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {tableFacturas.map((row: any, index: number) => (
                                                                        <>
                                                                            <tr >
                                                                                <td>{row.LDVDREFDOC}</td>
                                                                                <td>
                                                                                    <ModalEditarFacturas LDVDREFDOC={row?.LDVDREFDOC} ID={row?.ID} CDVDDOC={row?.CDVDDOC} CDVDPRSDOC={row?.CDVDPRSDOC} DespachoId={row?.DespachoId} IDVD={row?.IDVD} NART={row?.NART} setReloadForm={setReloadForm} />
                                                                                    <ModalEliminarFacturasInfComp ID={row?.ID} typeModal={1}
                                                                                        triggerSubmit={cargarDatosForm} nroFactura={row?.LDVDREFDOC} />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </tbody>
                                                            </Table>}
                                                        </Form.Row>
                                                    </Card.Body>                                                </Tab>
                                                <Tab eventKey="cuentaTerceros" disabled title="Cuenta de Terceros" >
                                                    Tab content for Contact
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', marginTop: 5 }}>
                                            <Button variant="primary" style={{ marginRight: 10 }} disabled={estadoDespacho === 'Oficializado'} onClick={() => {
                                                handleSubmit()
                                                reloadDespachoData()
                                            }
                                            }>
                                                Guardar
                                            </Button>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Cerrar
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </Container>

                    </Modal.Body>}
                </BlockUi>
            </Modal >
            <ModalImportadores mostrar={estadoModalImportadores.abierto}
                eventoCerrarModal={() => updateEstadoModalImportadores({ tipo: 'cerrar' })}
                eventoSeleccionarImportador={(item: any) => updateEstadoModalImportadores({ tipo: 'cerrar', valor: item.CUIT })}></ModalImportadores>

            <Modal style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} aria-labelledby="contained-modal-title-vcenter" show={showModalConfirmarCaratula} onHide={() => setShowModalConfirmarCaratula(false)}>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <FontAwesomeIcon color='red' size="3x" icon={faExclamationTriangle} />
                        <b style={{ fontSize: 20 }}>¿Confirma guardar cambios?</b>
                        <b style={{ fontSize: 20 }}>el provisorio se invalidará</b>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => updateDespachoCaratula(dataDespachoCaratula!)}>
                        Confirmar
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModalConfirmarCaratula(false)}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalFormCaratula