import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AppContext, NotFoundComponent } from "../../App";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MySelect, OnValueChangeArgument } from "../../FormikHooks";
import { Button, Col, Form, Alert, Modal } from "react-bootstrap";
import { useDecodedParams, convertirDatosGenericosAOption, optionLabelConCodigo, toHtmlDate, validarCUIT } from "../../Utilidades";
import { MyModal } from "MyModal";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import { TipoLock, useApi } from "ApiHooks";
import { CrearEditarTransportista, CrearEditarTransportistaRef } from "Paginas/Transportistas/CrearEditarTransportista";
import { storageFactory } from "storage-factory";

const storage = storageFactory(() => localStorage);

class CrearTransportistaError extends Error {
    constructor(message?: string, innerError?: Error) {
        // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
        super(message);

        // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CrearTransportistaError)
        }

        this.name = 'CrearTransportistaError';
        this.innerError = innerError;
    }

    innerError?: Error
}

enum EstadoModalSeleccionTransportista {
    Cerrado,
    Cargando,
    Abierto
}
export function EditarBultos() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikRefTransportista = React.useRef<FormikProps<any>>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let { interno } = useDecodedParams() as { interno: string };
    let history = useHistory();
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = React.useState(true);
    let [notFound, updateNotFound] = React.useState(false);
    let [deshabilitarCampos, updateDeshabilitarCampos] = React.useState(false);
    let [caratula, updateCaratula] = React.useState<any>({});
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [transportistaSeleccionado, setTransportistaSeleccionado] = React.useState<OnValueChangeArgument>('')
    let [estadoSeleccionTransportista, updateEstadoSeleccionTransportista] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { estadoModal: EstadoModalSeleccionTransportista.Cargando, transportistas: [], options: [] };
        } else if (accion.tipo === 'setTransportistas') {
            return {
                estadoModal: EstadoModalSeleccionTransportista.Abierto, transportistas: accion.transportistas ?? [],
                options: [
                    { value: 'add_transportista', label: 'Nuevo Transportista' },
                    ...(accion.transportistas?.map((t: any) => ({ value: t.CUIT, label: t.Nombre })) ?? []),
                ]
            }
        } else if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModalSeleccionTransportista.Cerrado, transportistas: [], options: [] };
        }
        return estado;
    }, { estadoModal: EstadoModalSeleccionTransportista.Cerrado, transportistas: [], options: [] })

    React.useEffect(() => {
        if (transportistaSeleccionado) {
            formikRefTransportista?.current?.setFieldValue('Valor', transportistaSeleccionado)
        }
    }, [transportistaSeleccionado])

    let schema = Yup.object({
        'NoEnviarBultos': Yup.boolean(),
        'MarcaBultos': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe ingresar la marca')
        }),
        'NumeroBultos': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe ingresar el número')
        }),
        'Via': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe seleccionar la via')
        }),
        'NombreTransporte': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe ingresar el nombre del transportista')
        }),
        'Bandera': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe seleccionar la bandera')
        }),
        'CuitTransporteBultos': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe ingresar el cuit del transportista')
                .test('cuit-valido', 'El CUIT ingresado no es válido', validarCUIT)
        }),
        'CodigoBultos': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe seleccionar el codigo')
        }),
        'TipoBultos': Yup.string().when('NoEnviarBultos', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe seleccionar el tipo')
        }),
        'CantidadBultosADespachar': Yup.number().when('NoEnviarBultos', {
            is: true,
            then: Yup.number().nullable().typeError('Debe ingresar un número'),
            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar la cantidad de bultos a despachar')
                .integer('Debe ser un numero entero').moreThan(0, 'Debe ser mayor a cero')
        }),
        'CantidadDisponibleBultos': Yup.number().when('NoEnviarBultos', {
            is: true,
            then: Yup.number().nullable().typeError('Debe ingresar un número'),
            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar la cantidad de bultos')
                .integer('Debe ser un numero entero').moreThan(0, 'Debe ser mayor a cero')
        }),
        'PesoBultos': Yup.number().when('NoEnviarBultos', {
            is: true,
            then: Yup.number().nullable().typeError('Debe ingresar un número'),
            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar el peso')
                .moreThan(0, 'Debe ser mayor a cero')
        }),
        'VencimientoEmbarque': Yup.date().when('NoEnviarBultos', {
            is: true,
            then: Yup.date().nullable(),
            otherwise: Yup.date().nullable().required('Debe ingresar la fecha de vencimiento del embarque')
        }),
    });
    React.useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getCaratula(interno);
                if (!respuesta) {
                    updateNotFound(true);
                    return;
                }
                const llevaBultos = await api.subregimenLlevaBultos(respuesta.CodigoSubregimen);
                if (llevaBultos) {
                    updateCaratula(respuesta);
                    updateDeshabilitarCampos(respuesta.NoEnviarBultos ?? false);
                    formikRef.current?.resetForm({
                        values: {
                            NoEnviarBultos: respuesta.NoEnviarBultos ?? false,
                            MarcaBultos: respuesta.MarcaBultos,
                            NumeroBultos: respuesta.NumeroBultos,
                            Via: respuesta.Via,
                            NombreTransporte: respuesta.NombreTransporte,
                            Bandera: respuesta.Bandera,
                            CuitTransporteBultos: respuesta.CuitTransporteBultos,
                            CodigoBultos: respuesta.CodigoBultos,
                            TipoBultos: respuesta.TipoBultos,
                            CantidadBultosADespachar: respuesta.CantidadBultosADespachar,
                            CantidadDisponibleBultos: respuesta.CantidadDisponibleBultos,
                            PesoBultos: respuesta.PesoBultos,
                            VencimientoEmbarque: toHtmlDate(respuesta.VencimientoEmbarque)
                        }
                    });
                    updateCargando(false);
                } else {
                    updateNotFound(true);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar bultos', error);
                    mostrarError('Error al cargar bultos');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);

    async function cargarTransportistas() {
        try {
            let respuesta = await api.getTransportistas();
            updateEstadoSeleccionTransportista({
                tipo: 'setTransportistas',
                transportistas: respuesta
            });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar transportistas', error);
                mostrarError('Error al cargar transportistas');
                updateEstadoSeleccionTransportista({ tipo: 'cerrar' });
            }
        }
    }
    React.useEffect(() => {
        if (estadoSeleccionTransportista.estadoModal === EstadoModalSeleccionTransportista.Cargando) {
            cargarTransportistas();
            return () => {
                api.cancelCurrentTokens();
            }
        }
        //eslint-disable-next-line
    }, [estadoSeleccionTransportista.estadoModal]);
    async function crearTransportista(CUIT: string, Nombre: string) {
        try {
            let respuesta = await api.getTransportistas();
            let cuitsExistentes = respuesta?.map((t: any) => t.CUIT) ?? [];
            if (!cuitsExistentes.includes(CUIT)) {
                let crear = await dialogoRef.current!.mostrar();
                if (crear) {
                    try {
                        let { exito, error } = await api.insertTransportista({ CUIT: CUIT, Nombre: Nombre });
                        if (!exito) {
                            throw new CrearTransportistaError(error);
                        }
                    } catch (error: any) {
                        if (api.isCancel(error)) {
                            throw error;
                        } else {
                            throw new CrearTransportistaError('Error al crear transportista', error);
                        }
                    }
                }
            }
        } catch (error) {
            if (api.isCancel(error)) {
                throw error;
            }
            console.error('Error al consultar transportistas existentes', error);
        }
    }
    async function submit(values: any) {
        try {
            let tieneLock = await api.obtenerLock(TipoLock.Caratula, caratula.Carpeta);
            if (!tieneLock) {
                updateMensajeErrorAlert('No se puede modificar la caratula porque otro usuario la está modificando');
            }
            let objetoPost;
            if (values.NoEnviarBultos) {
                objetoPost = {
                    NoEnviarBultos: true,
                    MarcaBultos: caratula!.MarcaBultos,
                    NumeroBultos: caratula!.NumeroBultos,
                    Via: caratula!.Via,
                    NombreTransporte: caratula!.NombreTransporte,
                    Bandera: caratula!.Bandera,
                    CuitTransporteBultos: caratula!.CuitTransporteBultos,
                    CodigoBultos: caratula!.CodigoBultos,
                    TipoBultos: caratula!.TipoBultos,
                    CantidadBultosADespachar: caratula!.CantidadBultosADespachar,
                    CantidadDisponibleBultos: caratula!.CantidadDisponibleBultos,
                    PesoBultos: caratula!.PesoBultos,
                    VencimientoEmbarque: caratula!.VencimientoEmbarque,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId:  (storage?.getItem('empresaActual') || userInfo.empresaActual),
                    Carpeta: interno
                };
            } else {
                objetoPost = {
                    ...values,
                    NoEnviarBultos: false,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId:  (storage?.getItem('empresaActual') || userInfo.empresaActual),
                    Carpeta: interno
                }
                await crearTransportista(values.CuitTransporteBultos, values.NombreTransporte);
            }
            await api.updateBultos(objetoPost);
            await api.eliminarLock(TipoLock.Caratula, interno);
            history.replace('/caratulas/' + encodeURIComponent(interno));
        } catch (error) {
            if (!api.isCancel(error)) {
                if (error instanceof CrearTransportistaError) {
                    console.error(error.message, error);
                    mostrarError(error.message);
                } else {
                    console.error('Error al guardar bultos', error);
                    mostrarError('Error al guardar bultos');
                }
            }
        }
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                history.goBack()
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line
    }, [history]);

    let refCrearEditarTransportista = useRef<CrearEditarTransportistaRef>(null);

    return notFound ? <NotFoundComponent></NotFoundComponent> : (<>
        <h2>Bultos de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <Formik initialValues={{}} validationSchema={schema} innerRef={formikRef} onSubmit={submit}>
            <MyForm blocking={cargando}>
                {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
                <Form.Row>
                    <Form.Group as={Col}>
                        <MyFormCheck disabled={caratula?.Bloqueada} name="NoEnviarBultos" label="NO Transferir Bultos"
                            onCheckedChange={checked => updateDeshabilitarCampos(checked)}></MyFormCheck>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="text" name="MarcaBultos" label="Marca (Bultos)"></MyFormControl>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="text" name="NumeroBultos" label="Numero Bultos"></MyFormControl>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MySelect isDisabled={deshabilitarCampos || caratula?.Bloqueada} name="Via" label="Via" options={() => api.getVias().then(convertirDatosGenericosAOption)}
                            getOptionLabel={optionLabelConCodigo}></MySelect>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="number" name="CuitTransporteBultos" label="Cuit Transportista" maxLength={11}></MyFormControl>
                        <Button className="mt-2" onClick={() => updateEstadoSeleccionTransportista({ tipo: 'mostrar' })}>Seleccionar Transportista</Button>

                    </Form.Group>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="text" maxLength={20} name="NombreTransporte" label="Nombre Transportista"></MyFormControl>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MySelect isDisabled={deshabilitarCampos || caratula?.Bloqueada} name="Bandera" label="Bandera" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                            getOptionLabel={optionLabelConCodigo}></MySelect>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MySelect isDisabled={deshabilitarCampos || caratula?.Bloqueada} name="CodigoBultos" label="Codigo" options={() => api.getCodigosEmbarque().then(convertirDatosGenericosAOption)}
                            getOptionLabel={optionLabelConCodigo}></MySelect>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MySelect isDisabled={deshabilitarCampos || caratula?.Bloqueada} name="TipoBultos" label="Tipo" options={() => api.getTiposEmbarque().then(convertirDatosGenericosAOption)}
                            getOptionLabel={optionLabelConCodigo}></MySelect>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="number" name="CantidadBultosADespachar" label="Bultos a Despachar"></MyFormControl>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="number" name="CantidadDisponibleBultos" label="Cantidad"></MyFormControl>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="number" name="PesoBultos" label="Peso (Kgr.)"></MyFormControl>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MyFormControl disabled={deshabilitarCampos || caratula?.Bloqueada} type="date" name="VencimientoEmbarque" label="Vencimiento Embarque"></MyFormControl>
                    </Form.Group>
                </Form.Row>
                <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Cancelar [ESC]</Button>
                <Button disabled={caratula?.Bloqueada} onClick={() => {
                    formikRef.current?.submitForm();
                }} className="mt-2 ml-2 mb-2">Guardar</Button>
            </MyForm>
        </Formik>
        <MyModal show={estadoSeleccionTransportista.estadoModal !== EstadoModalSeleccionTransportista.Cerrado}
            onHide={() => updateEstadoSeleccionTransportista({ tipo: 'cerrar' })}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Seleccionar Transportista
                </Modal.Header>
                <Formik innerRef={formikRefTransportista} validationSchema={Yup.object({
                    'Valor': Yup.string().nullable().required('Debe seleccionar un transportista')
                })} initialValues={{ 'Valor': '' }} onSubmit={({ Valor }, helpers) => {
                    let transportista = estadoSeleccionTransportista.transportistas.find((t: any) => t.CUIT === Valor);
                    formikRef.current?.setFieldValue('CuitTransporteBultos', transportista.CUIT);
                    formikRef.current?.setFieldValue('NombreTransporte', transportista.Nombre);
                    helpers.setSubmitting(false);
                    updateEstadoSeleccionTransportista({ tipo: 'cerrar' });
                }}>
                    {({ submitForm, isSubmitting }) => (<>
                        <Modal.Body>
                            <MyForm blocking={estadoSeleccionTransportista.estadoModal === EstadoModalSeleccionTransportista.Cargando}>
                                <Form.Group>
                                    <MySelect onValueChange={(e) => {
                                        setTransportistaSeleccionado(e)
                                        if (e === 'add_transportista') {
                                            refCrearEditarTransportista.current!.mostrarCrear()
                                        }
                                    }} options={estadoSeleccionTransportista.options} label="Transportista" hideLabel name="Valor"></MySelect>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstadoSeleccionTransportista({ tipo: 'cerrar' })}>Cerrar</Button>
                            <Button onClick={submitForm} disabled={isSubmitting || estadoSeleccionTransportista.estadoModal === EstadoModalSeleccionTransportista.Cargando || transportistaSeleccionado === 'add_transportista'}>Seleccionar</Button>
                        </Modal.Footer>
                    </>)}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje="No existe un transportista con el CUIT ingresado. ¿Desea crearlo?" resolverPromesaAlCancelar textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
        <CrearEditarTransportista setTransportistaSeleccionado={setTransportistaSeleccionado} cargarTransportistas={cargarTransportistas} ref={refCrearEditarTransportista}></CrearEditarTransportista>
    </>);
}