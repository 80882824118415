import React from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "App";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import BlockUi from "react-block-ui";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Formik, FormikProps, FormikHelpers } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MySelectConOptionABM } from "../../FormikHooks";
import { GrillaSync } from "Grilla";
import { isNullOrWhiteSpace } from "Utilidades";
import { useApi } from "ApiHooks";
import { ReferenciasCaratula } from "Paginas/Caratulas/ReferenciasCaratula";
import { Tareas } from "Paginas/Caratulas/Tareas";

function cambiarOrdenTarea(tareas: any[], nombreTarea: string, tipo: 'subir' | 'bajar') {
    function swapTarea(indiceA: number, indiceB: number) {
        let tareaA = { ...nuevasTareas[indiceA] };
        let tareaB = { ...nuevasTareas[indiceB] };
        let temp = tareaA.Orden ?? 0;
        tareaA.Orden = tareaB.Orden ?? 0;
        tareaB.Orden = temp;
        nuevasTareas[indiceB] = tareaA;
        nuevasTareas[indiceA] = tareaB;
    }
    let nuevasTareas: any[] = Array.from(tareas).sort((a: any, b: any) => (a.Orden ?? 0) - (b.Orden ?? 0));
    let indice = nuevasTareas.findIndex((r: any) => r.Nombre === nombreTarea);
    if (tipo === 'subir') {
        if (indice > 0) {
            swapTarea(indice, indice - 1);
        }
    } else {
        if (indice > -1 && indice < nuevasTareas.length - 1) {
            swapTarea(indice, indice + 1);
        }
    }
    return nuevasTareas;
}

export type CrearEditarPlantillaCaratulaRef = {
    mostrarCrear: () => Promise<any>,
    mostrarModificar: (plantilla: any) => Promise<any>
}

enum EstadoModal {
    Cerrado,
    Creando,
    Modificando
}

export const CrearEditarPlantillaCaratula = React.forwardRef((props: { plantillasExistentes: string[] }, ref: any) => {
    let api = useApi();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikReferenciaRef = React.useRef<FormikProps<any>>(null);
    let formikTareaRef = React.useRef<FormikProps<any>>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let { userInfo, mostrarError } = React.useContext(AppContext);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let [mostrarModalABMReferencias, updateMostrarModalABMReferencias] = React.useState(false);
    let [mostrarModalABMTareas, updateMostrarModalABMTareas] = React.useState(false);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return {
                estadoPrincipal: EstadoModal.Creando, referencias: [], tareas: [], nombre: '',
                resolve: accion.resolve, reject: accion.reject, estadoReferencias: EstadoModal.Cerrado,
                tiposReferencia: [], estadoTareas: EstadoModal.Cerrado, tiposTarea: [], cargando: true
            };
        } else if (accion.tipo === 'mostrarModificar') {
            return {
                estadoPrincipal: EstadoModal.Modificando, referencias: accion.referencias ?? [],
                tareas: accion.tareas ?? [], nombre: accion.nombre, resolve: accion.resolve, reject: accion.reject,
                estadoReferencias: EstadoModal.Cerrado, tiposReferencia: [],
                estadoTareas: EstadoModal.Cerrado, tiposTarea: [], cargando: true
            };
        } else if (accion.tipo === 'cerrar') {
            return {
                estadoPrincipal: EstadoModal.Cerrado, referencias: [], tareas: [],
                estadoReferencias: EstadoModal.Cerrado, tiposReferencia: [],
                estadoTareas: EstadoModal.Cerrado, tiposTarea: [], cargando: false
            };
        } else if (accion.tipo === 'mostrarCrearReferencia') {
            return { ...estado, estadoReferencias: EstadoModal.Creando, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificarReferencia') {
            return { ...estado, estadoReferencias: EstadoModal.Modificando, valorModificando: accion.referencia };
        } else if (accion.tipo === 'cerrarModalReferencia') {
            return { ...estado, estadoReferencias: EstadoModal.Cerrado, valorModificando: null };
        } else if (accion.tipo === 'insertReferencia') {
            let nuevasReferencias = Array.from(estado.referencias);
            let indice = nuevasReferencias.findIndex((r: any) => r.Nombre === accion.valor.Nombre);
            if (indice > -1) {
                nuevasReferencias.splice(indice, 1);
            }
            nuevasReferencias.push(accion.valor);
            return {
                ...estado, estadoReferencias: EstadoModal.Cerrado, valorModificando: null,
                referencias: nuevasReferencias
            }
        } else if (accion.tipo === 'deleteReferencia') {
            let nuevasReferencias = estado.referencias.filter((r: any) => r.Nombre !== accion.valor);
            return { ...estado, referencias: nuevasReferencias };
        } else if (accion.tipo === 'mostrarCrearTarea') {
            return { ...estado, estadoTareas: EstadoModal.Creando, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificarTarea') {
            return { ...estado, estadoTareas: EstadoModal.Modificando, valorModificando: accion.tarea };
        } else if (accion.tipo === 'cerrarModalTarea') {
            return { ...estado, estadoTareas: EstadoModal.Cerrado, valorModificando: null };
        } else if (accion.tipo === 'insertTarea') {
            let nuevasTareas: any[] = Array.from(estado.tareas);
            let indice = nuevasTareas.findIndex((r: any) => r.Nombre === accion.valor.Nombre);
            if (indice > -1) {
                let tareaVieja = nuevasTareas[indice];
                nuevasTareas.splice(indice, 1, { ...accion.valor, Orden: tareaVieja.Orden });
            } else {
                let max = nuevasTareas.reduce((a: any, b: any) => Math.max(a.Orden ?? 0, b.Orden ?? 0), 0);
                nuevasTareas.push({ ...accion.valor, Orden: max + 1 });
            }
            return {
                ...estado, estadoTareas: EstadoModal.Cerrado, valorModificando: null,
                tareas: nuevasTareas
            }
        } else if (accion.tipo === 'deleteTarea') {
            let nuevasTareas = estado.tareas.filter((r: any) => r.Nombre !== accion.valor);
            return { ...estado, tareas: nuevasTareas };
        } else if (accion.tipo === 'subirTarea') {
            return { ...estado, tareas: cambiarOrdenTarea(estado.tareas, accion.valor.Nombre, 'subir') };
        } else if (accion.tipo === 'bajarTarea') {
            return { ...estado, tareas: cambiarOrdenTarea(estado.tareas, accion.valor.Nombre, 'bajar') };
        } else if (accion.tipo === 'setTipos') {
            return {
                ...estado, tiposReferencia: accion.tiposReferencia ?? [],
                tiposTarea: accion.tiposTarea ?? [], cargando: false
            };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, cargando: accion.valor };
        } else if (accion.tipo === 'setTiposReferencia') {
            return { ...estado, tiposReferencia: accion.valor ?? [], cargando: false };
        } else if (accion.tipo === 'setTiposTarea') {
            return { ...estado, tiposTarea: accion.valor ?? [] };
        }
        return estado;
    }, {
        estadoPrincipal: EstadoModal.Cerrado, referencias: [], tareas: [],
        estadoReferencias: EstadoModal.Cerrado, tiposReferencia: [],
        estadoTareas: EstadoModal.Cerrado, tiposTarea: [], cargando: false
    });
    React.useEffect(() => {
        if (estado.estadoPrincipal !== EstadoModal.Cerrado) {
            async function cargarTipos() {
                try {
                    let tiposTarea = await api.getTareas();
                    let tiposReferencia = await api.getTiposReferencia();
                    updateEstado({ tipo: 'setTipos', tiposTarea: tiposTarea, tiposReferencia: tiposReferencia });
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar listado de tareas y referencias', error);
                        mostrarError('Error al cargar listado de tareas y referencias');
                        updateEstado({ tipo: 'cerrar' });
                    }
                }
            }
            cargarTipos();
        }
        //eslint-disable-next-line
    }, [estado.estadoPrincipal]);
    const onTareasChanged = React.useCallback((tareas: any[]) => updateEstado({ tipo: 'setTiposTarea', valor: tareas }), [updateEstado]);
    async function cargarTiposReferencia(referenciaSeleccionada?: any) {
        try {
            updateEstado({ tipo: 'setCargando', valor: true });
            let tiposReferencia = await api.getTiposReferencia();
            updateEstado({ tipo: 'setTiposReferencia', valor: tiposReferencia });
            if (referenciaSeleccionada) {
                formikReferenciaRef.current?.setFieldValue('Nombre', referenciaSeleccionada.Nombre);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar listado de referencias', error);
                mostrarError('Error al cargar listado de referencias');
                updateEstado({ tipo: 'setCargando', valor: false });
            }
        }
    }
    React.useImperativeHandle(ref, () => ({
        mostrarCrear: () => {
            return new Promise<any>((resolve, reject) => {
                updateEstado({ tipo: 'mostrarCrear', resolve: resolve, reject: reject });
            });
        }, mostrarModificar: (plantilla: any) => {
            return new Promise<any>((resolve, reject) => {
                updateEstado({
                    tipo: 'mostrarModificar', referencias: plantilla.Referencias,
                    tareas: plantilla.Tareas, nombre: plantilla.Nombre, resolve: resolve, reject: reject
                });
            });
        }
    }));
    const cerrar = () => {
        api.cancelCurrentTokens();
        estado.reject();
        updateEstado({ tipo: 'cerrar' });
    }
    const cerrarModalABMReferencias = () => {
        updateMostrarModalABMReferencias(false);
        cargarTiposReferencia();
    }
    async function submit(values: { Nombre: string }, actions: FormikHelpers<any>) {
        if (estado.estadoPrincipal === EstadoModal.Creando && props.plantillasExistentes.includes(values.Nombre)) {
            actions.setFieldError('Nombre', 'Ya existe una plantilla con este nombre');
            return;
        }
        if (estado.referencias.length === 0 && estado.tareas.length === 0) {
            mostrarError('Debe ingresar al menos una referencia o tarea');
            return;
        }
        let plantilla = {
            Nombre: values.Nombre,
            Referencias: estado.referencias,
            Tareas: estado.tareas,
            EmpresaId:  (storage?.getItem('empresaActual') || userInfo.empresaActual),
            NroClienteAlpha: userInfo.nroClienteAlpha
        }
        try {
            await api.insertPlantillaCaratula(plantilla);
            estado.resolve(plantilla);
            updateEstado({ tipo: 'cerrar' });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al guardar plantilla de caratula', error);
                mostrarError('Error al guardar plantilla de caratula');
            }
        }
    }
    function submitReferencia(values: { Nombre: string, Valor?: string }, actions: FormikHelpers<any>) {
        if (estado.estadoReferencias === EstadoModal.Creando &&
            estado.referencias.map((r: any) => r.Nombre).includes(values.Nombre)) {
            actions.setFieldError('Nombre', 'Ya se agregó esta referencia a esta plantilla');
        } else {
            updateEstado({ tipo: 'insertReferencia', valor: values });
        }
        actions.setSubmitting(false);
    }
    function submitTarea(values: { Nombre: string, Reportable: boolean }, actions: FormikHelpers<any>) {
        if (estado.estadoTareas === EstadoModal.Creando &&
            estado.tareas.map((r: any) => r.Nombre).includes(values.Nombre)) {
            actions.setFieldError('Nombre', 'Ya se agregó esta tarea a esta plantilla');
        } else {
            updateEstado({ tipo: 'insertTarea', valor: values });
        }
        actions.setSubmitting(false);
    }
    return <>
        <MyModal show={estado.estadoPrincipal !== EstadoModal.Cerrado} onHide={cerrar}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    {estado.estadoPrincipal === EstadoModal.Modificando ? 'Modificar plantilla' : 'Crear plantilla'}
                </Modal.Header>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Nombre': Yup.string().nullable().required('Debe ingresar el nombre de la plantilla')
                })} initialValues={{ Nombre: estado.nombre ?? '' }} onSubmit={submit}>
                    {({ isSubmitting, submitForm }) => (<>
                        <Modal.Body>
                            <MyForm blocking={estado.cargando}>
                                <Form.Group>
                                    <MyFormControl type="text" label="Nombre" name="Nombre"
                                        readOnly={estado.estadoPrincipal === EstadoModal.Modificando}></MyFormControl>
                                </Form.Group>
                            </MyForm>
                            <BlockUi blocking={estado.cargando}>
                                <Tabs id="tabsCrearEditarPlantillaCaratula">
                                    <Tab eventKey="referencias" title="Referencias">
                                        <div className="mt-2">
                                            <GrillaSync datos={estado.referencias} campos={[{ titulo: 'Nombre', propiedad: 'Nombre', clave: true }, { titulo: 'Valor', propiedad: 'Valor' }]}
                                                eventoAgregar={() => updateEstado({ tipo: 'mostrarCrearReferencia' })}
                                                eventoDetalle={(r: any) => updateEstado({ tipo: 'mostrarModificarReferencia', referencia: r })}
                                                eventoEliminar={(r: any) => {
                                                    updateMensajeDialogo(`¿Está seguro que desea eliminar la referencia ${r.Nombre}?`);
                                                    dialogoRef.current!.mostrar().then(() => updateEstado({ tipo: 'deleteReferencia', valor: r.Nombre })).catch(() => { });
                                                }}></GrillaSync>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tareas" title="Tareas">
                                        <div className="mt-2">
                                            <GrillaSync datos={estado.tareas.sort((a: any, b: any) => (a.Orden ?? 0) - (b.Orden ?? 0))}
                                                campos={[{ titulo: 'Nombre', propiedad: 'Nombre', clave: true },
                                                { titulo: 'Reportable', propiedad: 'Reportable', plantillaFormato: (valor: any) => valor ? 'Sí' : 'No' },
                                                { titulo: 'Lleva Estado', propiedad: 'LlevaEstado', plantillaFormato: (valor: any) => valor !== false ? 'Sí' : 'No' }]}
                                                eventoAgregar={() => updateEstado({ tipo: 'mostrarCrearTarea' })}
                                                eventoDetalle={(r: any) => updateEstado({ tipo: 'mostrarModificarTarea', tarea: r })}
                                                eventoEliminar={(r: any) => {
                                                    updateMensajeDialogo(`¿Está seguro que desea eliminar la tarea ${r.Nombre}?`);
                                                    dialogoRef.current!.mostrar().then(() => updateEstado({ tipo: 'deleteTarea', valor: r.Nombre })).catch(() => { });
                                                }} eventoSubir={(r: any) => {
                                                    updateEstado({ tipo: 'subirTarea', valor: r });
                                                }} eventoBajar={(r: any) => {
                                                    updateEstado({ tipo: 'bajarTarea', valor: r });
                                                }}></GrillaSync>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </BlockUi>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={cerrar}>
                                Cancelar
                            </Button>
                            <Button disabled={estado.cargando || isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>)}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.estadoReferencias !== EstadoModal.Cerrado} onHide={() => updateEstado({ tipo: 'cerrarModalReferencia' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    {estado.estadoReferencias === EstadoModal.Modificando ? 'Modificar referencia' : 'Agregar referencia'}
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikReferenciaRef} validationSchema={Yup.object({
                        'Nombre': Yup.string().nullable().required('Debe seleccionar una referencia'),
                        'Valor': Yup.string().nullable()
                    })} initialValues={{ Nombre: estado.valorModificando?.Nombre ?? '', Valor: estado.valorModificando?.Valor ?? '' }}
                        onSubmit={submitReferencia}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                {estado.estadoReferencias === EstadoModal.Modificando ?
                                    <MyFormControl type="text" name="Nombre" label="Nombre" readOnly plaintext></MyFormControl>
                                    : <MySelectConOptionABM name="Nombre" label="Nombre" labelOptionABM="Nueva referencia..."
                                        onSelectABM={() => updateMostrarModalABMReferencias(true)}
                                        options={estado.tiposReferencia.map((item: any) => ({ value: item.Nombre, label: item.Nombre }))}></MySelectConOptionABM>}
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="Valor" label="Valor"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrarModalReferencia' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikReferenciaRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.estadoTareas !== EstadoModal.Cerrado} onHide={() => updateEstado({ tipo: 'cerrarModalTarea' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    {estado.estadoTareas === EstadoModal.Modificando ? 'Modificar tarea' : 'Agregar tarea'}
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikTareaRef} validationSchema={Yup.object({
                        'Nombre': Yup.string().nullable().required('Debe seleccionar una tarea'),
                        'Reportable': Yup.boolean().nullable(),
                        'LlevaEstado': Yup.boolean().nullable()
                    })} initialValues={{
                        Nombre: estado.valorModificando?.Nombre ?? '',
                        Reportable: estado.valorModificando?.Reportable ?? false,
                        LlevaEstado: estado.valorModificando !== null && estado.valorModificando !== undefined ? (estado.valorModificando.LlevaEstado ?? true) : false
                    }}
                        onSubmit={submitTarea}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                {estado.estadoTareas === EstadoModal.Modificando ?
                                    <MyFormControl type="text" name="Nombre" label="Tarea" readOnly plaintext></MyFormControl> :
                                    <MySelectConOptionABM name="Nombre" label="Tarea" labelOptionABM="Nueva tarea..."
                                        onSelectABM={() => updateMostrarModalABMTareas(true)}
                                        options={estado.tiposTarea.map((t: any) => ({ label: t.Nombre, value: t.Nombre }))}
                                        onValueChange={(option) => {
                                            let valor = option as string | null | undefined;
                                            if (!isNullOrWhiteSpace(valor)) {
                                                let tarea = estado.tiposTarea.find((t: any) => t.Nombre === valor);
                                                if (tarea) {
                                                    formikTareaRef.current?.setValues({
                                                        'Nombre': tarea.Nombre,
                                                        'Reportable': tarea.ReportablePorDefecto,
                                                        'LlevaEstado': tarea.LlevaEstado ?? true
                                                    });
                                                }
                                            }
                                        }}></MySelectConOptionABM>}
                            </Form.Group>
                            <Form.Group>
                                <MyFormCheck name="Reportable" label="Reportable"></MyFormCheck>
                            </Form.Group>
                            <Form.Group>
                                {/* Poner id que sea distinto al del ABM de tareas*/}
                                <MyFormCheck id="LlevaEstado2" name="LlevaEstado" label="Lleva Estado"></MyFormCheck>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrarModalTarea' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikTareaRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
        <MyModal show={mostrarModalABMReferencias} onHide={cerrarModalABMReferencias}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Referencias Caratula
                </Modal.Header>
                <Modal.Body>
                    <ReferenciasCaratula onReferenciaSeleccionada={(item: any) => {
                        updateMostrarModalABMReferencias(false);
                        cargarTiposReferencia(item);
                    }}></ReferenciasCaratula>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cerrarModalABMReferencias}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalABMTareas} onHide={() => updateMostrarModalABMTareas(false)}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Tareas
                </Modal.Header>
                <Modal.Body>
                    <Tareas onTareasChanged={onTareasChanged}
                        onSelectTarea={(tarea: any) => {
                            updateMostrarModalABMTareas(false);
                            if (tarea) {
                                formikTareaRef.current?.setValues({
                                    'Nombre': tarea.Nombre,
                                    'Reportable': tarea.ReportablePorDefecto,
                                    'LlevaEstado': tarea.LlevaEstado ?? true
                                });
                            }
                        }}></Tareas>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalABMTareas(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>;
});