import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface propsModalConfirmarCancelarValidacion {
    showModalConfirmarCancelar: boolean | undefined;
    cancelarValidar: (() => void)
    handleCloseModalConfirmarCancelar: () => void;
    handleCloseModalValidar: () => void;
}

const ModalConfirmarCancelarValidacion = ({ showModalConfirmarCancelar, handleCloseModalValidar, cancelarValidar, handleCloseModalConfirmarCancelar }: propsModalConfirmarCancelarValidacion) => {
    return (
        <>
            <Modal show={showModalConfirmarCancelar} onHide={handleCloseModalValidar} dialogClassName="modal-cancelar"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Cancelar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <h4 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 5, alignContent: 'center', alignItems: 'center' }}>¿Desea cancelar el tratamiento?</h4>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                            <Button variant='danger' onClick={() => cancelarValidar()} style={{ width: 80 }}>Si</Button>
                            <Button variant='secondary' onClick={() => handleCloseModalConfirmarCancelar()} style={{ width: 80 }}>No</Button>

                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>)
}

export default ModalConfirmarCancelarValidacion