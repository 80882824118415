import React from 'react'
import { Button, Col, Container, Form, Modal } from 'react-bootstrap'

interface propsModalNuevoPerfil {
    showModalNuevoPerfil: boolean | undefined;
    handleCloseModalNuevoPerfil: (() => void)
    setNuevoPerfil_InputBoxValue: (arg: string) => void;
    nuevoPerfil_InputBoxValue: string
    crearNuevoPerfil: (arg: string) => void;
}
const ModalNuevoPerfil = ({ showModalNuevoPerfil, handleCloseModalNuevoPerfil, nuevoPerfil_InputBoxValue, setNuevoPerfil_InputBoxValue, crearNuevoPerfil }: propsModalNuevoPerfil) => {
    return (
        <>
            <Modal show={showModalNuevoPerfil} onHide={handleCloseModalNuevoPerfil} dialogClassName="modal-nuevo_perfil"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" keyboard={false} style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Nuevo Perfil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <p style={{ fontSize: 20, fontStyle: 'italic', textAlign: 'center' }}>Ingrese un nombre para el nuevo perfil.</p>
                        <Form.Group as={Col} md="12" lg="12">
                            <Form.Control
                                style={{ height: 38 }}
                                type="text"
                                name="nuevoPerfil_InputBoxValue"
                                value={nuevoPerfil_InputBoxValue}
                                placeholder='Ingrese un nombre..'
                                required
                                onChange={(e) => setNuevoPerfil_InputBoxValue(e?.target?.value)}
                            />
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                            <Button variant='success' style={{ width: 170 }} disabled={nuevoPerfil_InputBoxValue?.length === 0} onClick={() => crearNuevoPerfil(nuevoPerfil_InputBoxValue)}>Crear nuevo perfil</Button>
                            <Button onClick={() => handleCloseModalNuevoPerfil()}>Cerrar</Button>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default ModalNuevoPerfil