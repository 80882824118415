import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApi } from 'ApiHooks';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import React, { useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Modal, Container, Col, Form, Toast, Spinner, Tabs, Tab } from 'react-bootstrap';
import { MySelect, OnValueChangeArgument } from 'FormikHooks';
import './style-modal.css'
import { Formik } from 'formik';
import { convertirDatosGenericosAOption, optionLabelConCodigo } from 'Utilidades';
import { Licencia, ObtenerUsuariosAFIPResponse } from 'modelos/Advanced';
import { faFileContract, faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ModalConvertirLicenciaProps {
    styleLink: boolean
    despachanteCuit?: string
    nroSimi?: string
    despachanteNombre?: any
}

const ModalConvertirLicencia = ({ styleLink, nroSimi, despachanteCuit, despachanteNombre }: ModalConvertirLicenciaProps) => {

    const [show, setShow] = useState<boolean>(false);
    const [cargando, updateCargando] = useState<boolean>(false);
    const [usuariosAFIP, SetUsuariosAFIP] = useState<ObtenerUsuariosAFIPResponse[]>()
    const [CUITSeleccionado, setCUITSeleccionado] = useState<OnValueChangeArgument>('')
    const [simi, setSimi] = useState<string>('')
    const [subRegimen, setSubRegimen] = useState<OnValueChangeArgument>('')
    const [mostrarEnProgreso, setMostrarEnProgreso] = useState<any>([])
    const [mostrarForm, setMostrarForm] = useState<boolean>(true)
    let isRunning = useRef<boolean>(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let api = useAdvAPI();
    let apiSintia = useApi();

    const ObtenerUsuariosAFIP = async () => {
        try {
            updateCargando(true)
            let usuariosAFIP = await api.ObtenerUsuariosAFIP()
            SetUsuariosAFIP(usuariosAFIP)
            updateCargando(false);
        } catch (error) {
            console.log('error')
            updateCargando(false);
        }
    }

    const ConvertirLicenciaGeneral = async () => {
        try {
            updateCargando(true)
            await api.ConvertirLicenciaGeneral(simi, subRegimen!?.toString(), CUITSeleccionado!?.toString())
            setMostrarForm(false)
            isRunning.current = false
            ObtenerConversionesLicencias()
            setSimi('')
            setCUITSeleccionado('')
            setSubRegimen('')
            updateCargando(false);
        } catch (error) {
            console.log('error')
            updateCargando(false);
        }
    }

    const ObtenerConversionesLicencias = async () => {
        if (isRunning?.current) return;
        try {
            let conversiones: Licencia[] = await api.ObtenerConversionesLicencia();

            if (conversiones?.length > 0) {
                setMostrarEnProgreso(conversiones);
                updateCargando(false);
                if (conversiones.some(licencia => licencia?.EstadoConversionLicenciaID !== 2 && licencia?.EstadoConversionLicenciaID !== 3)) {
                    ObtenerConversionesLicencias(); // Recursión
                }
            } else {
                updateCargando(false);
            }
        } catch (error) {
            console.log('error');
            updateCargando(false);
        }
    };

    const nuevaLicencia = () => {
        setMostrarEnProgreso([])
        isRunning.current = true
        setMostrarForm(true)
    }

    const verProgresos = () => {
        setMostrarForm(false)
        isRunning.current = false
        ObtenerConversionesLicencias()
    }

    useEffect(() => {
        ObtenerUsuariosAFIP()
        if (nroSimi && despachanteCuit) {
            setCUITSeleccionado(despachanteCuit)
            setSimi(nroSimi)
        }
        // eslint-disable-next-line
    }, [show])

      useEffect(() => {
              if(usuariosAFIP!?.length === 1){
                setCUITSeleccionado(usuariosAFIP?.[0]?.CUIT)
              }
          }, [usuariosAFIP])

    return (
        <>
            {styleLink ? <button onClick={handleShow} className="styleLink">Convertir Licencia</button> : <Button onClick={handleShow} style={{ marginLeft: 10 }} variant='primary'><FontAwesomeIcon icon={faFileContract} style={{ fontSize: 20, marginRight: 3 }} />Convertir Licencia</Button>}
            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Convertir Licencia</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            {mostrarForm ? <Formik
                                onSubmit={(values) => {
                                }}
                                initialValues={{
                                    CUIT: usuariosAFIP!?.length === 1 ? usuariosAFIP?.[0]?.CUIT : '',
                                    CodigoSubregimen: '',
                                    numSIMI: ''
                                }}
                            >
                                {() => (
                                    <Form noValidate >
                                        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Form.Group as={Col} md="10" lg="10">
                                                <Form.Label>Seleccione Despachante</Form.Label>
                                                <Form.Control as="select" defaultValue={usuariosAFIP!?.length === 1 ? usuariosAFIP?.[0]?.CUIT : ''} onChange={(e) => {
                                                    setCUITSeleccionado(e?.target?.value)
                                                }}>
                                                    <option value={despachanteCuit ? despachanteCuit : ''}>{despachanteCuit ? `${despachanteCuit} - ${despachanteNombre}` : 'Seleccione..'}</option>
                                                    {usuariosAFIP!?.map((usuario) => (
                                                        <option value={usuario?.CUIT}>{usuario?.CUIT} - {usuario?.Nombre}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group as={Col} md="10" lg="10">
                                                <Form.Label>Número SIMI</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    minLength={16}
                                                    maxLength={16}
                                                    name="numSIMI"
                                                    aria-label='Ingrese SIMI..'
                                                    value={simi}
                                                    onChange={(e) => setSimi(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="10" lg="10">
                                                <MySelect name="CodigoSubregimen" label="Subregimen" getOptionLabel={optionLabelConCodigo}
                                                    options={() => apiSintia.getSubregimenes().then(convertirDatosGenericosAOption)}
                                                    onValueChange={(e) => setSubRegimen(e)} ></MySelect>
                                            </Form.Group>
                                        </Container>
                                    </Form>
                                )}
                            </Formik> :
                                <Tabs defaultActiveKey="errores">
                                    <Tab eventKey="progreso" title="En Progreso">
                                      {mostrarEnProgreso?.length > 0 ? <div className='container-cards' style={{ marginTop: 20, maxHeight: 400, overflowY: 'scroll' }}>
                                            { mostrarEnProgreso?.map((licencia: Licencia) => licencia?.EstadoConversionLicenciaID !== 2 && licencia?.EstadoConversionLicenciaID !== 3 && <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid', }}>
                                                <Toast.Body>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                                                        <Spinner animation='border' variant='success' />
                                                        <p style={{ fontWeight: 'bold' }}>Procesando..</p>
                                                        <p><b>NroDespacho:</b> {licencia?.NroDespacho}</p>
                                                        <p><b>CuitDespachante:</b> {licencia?.CuitDespachante}</p>
                                                        <small>Subregimen: {licencia?.Subregimen} </small>
                                                    </div>
                                                </Toast.Body>
                                            </Toast>)}
                                        </div> : <div>
                                            <p style={{fontSize:20, fontWeight:'bold'}}>Sin conversiones en progreso</p>
                                            </div>}
                                    </Tab>
                                    <Tab eventKey="finalizadas" title="Finalizadas">
                                    { mostrarEnProgreso?.length > 0 ?  <div className='container-cards' style={{ marginTop: 20, maxHeight: 400, overflowY: 'scroll' }}>
                                            {mostrarEnProgreso?.map((licencia: Licencia) => licencia?.EstadoConversionLicenciaID === 2 &&
                                                <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid' }}>
                                                    <Toast.Header closeButton={false}>
                                                        <strong className="mr-auto" style={{ color: 'black', fontSize: 12 }}>NroDespacho: {licencia?.NroDespacho} </strong>
                                                        <small>Subregimen: {licencia?.Subregimen} </small>
                                                    </Toast.Header>
                                                    <Toast.Body>
                                                        <div>
                                                            <p><b>CuitDespachante:</b> {licencia?.CuitDespachante}</p>
                                                            <p><b>DescripcionProgreso:</b> {licencia?.DescripcionProgreso} </p>
                                                        </div>
                                                    </Toast.Body>
                                                </Toast>)}
                                        </div> : <div>
                                        <p style={{fontSize:20, fontWeight:'bold'}}>Sin Licencias Finalizadas</p>

                                            </div>}
                                    </Tab>
                                    <Tab eventKey="errores" title="Con Errores">
                                    {  mostrarEnProgreso?.length > 0 ?  <div className='container-cards' style={{ marginTop: 20, maxHeight: 400, overflowY: 'scroll' }}>
                                            { mostrarEnProgreso?.map((licencia: Licencia) => licencia?.EstadoConversionLicenciaID === 3 &&
                                                <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid' }}>
                                                    <Toast.Header closeButton={false}>
                                                        <strong className="mr-auto" style={{ color: 'black', fontSize: 12 }}>NroDespacho: {licencia?.NroDespacho} </strong>
                                                        <small>Subregimen: {licencia?.Subregimen} </small>
                                                    </Toast.Header>
                                                    <Toast.Body>
                                                        <div>
                                                            <p><b>CuitDespachante:</b> {licencia?.CuitDespachante}</p>
                                                            <p><b>Error:</b> {licencia?.Error} </p>
                                                        </div>
                                                    </Toast.Body>
                                                </Toast>)}
                                        </div> : <div>
                                        <p style={{fontSize:20, fontWeight:'bold'}}>Sin Licencias con errores</p>

                                            </div>}
                                    </Tab>
                                </Tabs>
                            }
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                                {mostrarForm && <Button onClick={() => ConvertirLicenciaGeneral()} variant={CUITSeleccionado?.length === 0 || simi?.length < 16 || subRegimen?.length === 0 ? 'secondary' : 'success'} disabled={CUITSeleccionado?.length === 0 || simi?.length < 16 || subRegimen?.length === 0}>Convertir Licencia</Button>}
                            </div>
                        </Modal.Body>}
                    <Modal.Footer>
                        <div style={{ marginRight: 'auto' }}>
                            {!mostrarForm ? <Button variant="success" onClick={() => nuevaLicencia()} >Nueva Licencia</Button> : <Button variant="warning" onClick={() => verProgresos()} ><FontAwesomeIcon icon={faSpinner} style={{ fontSize: 20, marginRight: 3 }} />Ver Progresos</Button>}
                        </div>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalConvertirLicencia