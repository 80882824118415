import { faCheckCircle, faExclamationCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Table } from 'react-bootstrap'
import Loader from 'react-loaders'

interface ModalValidarDespachoProps {
    perfilSeleccionadoID: Number | null;
    tipoVetanaMaria: string | null;
    opcionesVentanaMaria: Array<any> | any;
    opcionSeleccionadaVentanaMaria: any;
    setOpcionSeleccionadaVentanaMaria: ((args: string) => void);
    mensajeVentanaMaria: string | null;
    tipoBotonVentanaMaria: string | null;
    textoVentanaMaria: string | null;
    handleCloseModalValidar: (() => void);
    handleCloseModalValidarVerPDF: (() => void);
    mensajeValidacionError: any;
    idDespacho: string | null;
    tipoEventoMariaID: string | null;
    ventanaMariaID: string | null;
    setTipoVentanaMaria: ((args: string | null) => void);
    updateCargando: Dispatch<SetStateAction<boolean>>;
    obtenerEstadoValidacion: () => Promise<void>;
    showModalValidar: boolean;
    cargandoDespachoValidacion: boolean;
    mensajeDespachoProgreso: string | null;
    tituloVentanaMaria: string | null;
    handleShowModalConfirmarCancelar: (() => void);
    nombrePerfilSeleccionado: string | null;
    tituloValidarDespacho: string
    cancelarValidar: ((args: number) => void);
    handleShowModalSeleccionarPerfil: (() => void);
}


const ModalValidarDespacho = ({ perfilSeleccionadoID, tipoVetanaMaria, nombrePerfilSeleccionado, opcionesVentanaMaria, opcionSeleccionadaVentanaMaria, setOpcionSeleccionadaVentanaMaria, mensajeVentanaMaria, tipoBotonVentanaMaria, textoVentanaMaria, handleCloseModalValidar, mensajeValidacionError, idDespacho, tipoEventoMariaID, ventanaMariaID, setTipoVentanaMaria, updateCargando, obtenerEstadoValidacion, showModalValidar, cargandoDespachoValidacion, mensajeDespachoProgreso, tituloVentanaMaria, handleShowModalConfirmarCancelar, tituloValidarDespacho, cancelarValidar, handleShowModalSeleccionarPerfil,handleCloseModalValidarVerPDF }: ModalValidarDespachoProps) => {

    let api = useAdvAPI();
    const [recordarPreguntaCheck, setRecordarPreguntaCheck] = useState<boolean>(true)
    const [ventanaMaria_InputBoxValue, setVentanaMaria_InputBoxValue] = useState<string | any>(null)
    const [mensajeError, setMensajeError] = useState<string | any>([])
    const [contador, setContador] = useState(600)

    const procesarErrores = (errorStr: string) => {
        return errorStr?.split('|').filter(e => e?.trim() !== "")?.map(e => {
            let partes = e?.split('#-#');
            let item = partes[0]?.trim();
            let causante = partes[1]?.trim();
            let errorMsg = partes[2]?.trim();
            return {
                itemID: item,
                causante: causante,
                error: errorMsg
            };
        });
    }

    useEffect(() => {
        if (mensajeValidacionError?.length > 0) {
            setMensajeError(procesarErrores(mensajeValidacionError))
        }
    }, [mensajeValidacionError])


    useEffect(() => {
        if (
            tipoVetanaMaria === 'VentanaMaria_ListView' ||
            tipoVetanaMaria === 'VentanaMaria_MessageBox' ||
            tipoVetanaMaria === 'VentanaMaria_InputBox'
        ) {
            const inicio = Date.now()
            const tiempoFinal = inicio + contador * 1000

            const intervalo = setInterval(() => {
                const tiempoActual = Date.now();
                const tiempoRestante = Math.max(0, Math.ceil((tiempoFinal - tiempoActual) / 1000))

                setContador(tiempoRestante)

                if (tiempoRestante <= 0) {
                    clearInterval(intervalo)
                    cancelarValidar(0)
                }
            }, 1000)

            return () => clearInterval(intervalo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoVetanaMaria])


    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
          event.preventDefault();
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    useEffect(() => {
        setContador(600)
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "F5" && showModalValidar) {
                event.preventDefault()
                handleShowModalConfirmarCancelar()
            }
        }

        window.addEventListener("keydown", handleKeyDown)

        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalValidar])

    const tableValidarDespachoOpciones = () => {
        switch (tipoVetanaMaria) {
            case 'VentanaMaria_ListView':
                return <div>
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Seleccione una opción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {opcionesVentanaMaria?.map((item: string, index: React.Key | null | undefined) => (
                                    <tr style={{ backgroundColor: opcionSeleccionadaVentanaMaria === item ? '#0d6efd' : 'white' }} className="cursor-pointer" key={index} onClick={() => setOpcionSeleccionadaVentanaMaria(item)}>
                                        <td title={item} style={{ color: opcionSeleccionadaVentanaMaria === item ? 'white' : 'black', fontSize: 15 }}>{item?.length > 100 ? item?.substring(0, 97) + "..." : item}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 5 }}>
                        <Button variant='success' style={{ width: 150 }} disabled={opcionSeleccionadaVentanaMaria?.length === 0} onClick={() => enviarEventoDespacho(opcionSeleccionadaVentanaMaria, "Seleccionar la opción marcada", 'VentanaMaria_ListView')}>Seleccionar la opción marcada</Button>
                        <Button variant='secondary' style={{ width: 150 }} disabled={opcionSeleccionadaVentanaMaria?.length === 0} onClick={() => enviarEventoDespacho(opcionSeleccionadaVentanaMaria, "No seleccionar ninguna", 'VentanaMaria_ListView')}>No seleccionar ninguna</Button>
                    </div>
                </div>;
            case 'VentanaMaria_MessageBox':
                return <>
                    <Container style={{ width: 350, justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -30 }}>
                            <FontAwesomeIcon style={{ marginTop: 30, marginRight: 10, fontSize: 50, color: mensajeVentanaMaria?.startsWith("Error") ? '#e83846' : '#383be8' }} icon={mensajeVentanaMaria?.startsWith("Error") ? faTimesCircle : faQuestionCircle} />
                            <p style={{ fontSize: 20, fontStyle: 'italic', textAlign: 'center', }}>{mensajeVentanaMaria}</p>

                        </div>
                        {tipoBotonVentanaMaria === 'Aceptar' ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <Button variant='success' style={{ width: 90 }} onClick={() => enviarEventoDespacho(null, 'Aceptar', 'VentanaMaria_MessageBox')}>Aceptar</Button>
                        </div> : <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 20, marginTop: 25 }}>
                            <Button variant="success" style={{ width: 70 }} onClick={() => enviarEventoDespacho(null, '&Sí', 'VentanaMaria_MessageBox')}>
                                Sí
                            </Button>
                            <Button variant="secondary" style={{ width: 70 }} onClick={() => enviarEventoDespacho(null, '&No', 'VentanaMaria_MessageBox')}>
                                No
                            </Button>
                        </div>}
                    </Container>
                </>;
            case 'VentanaMaria_InputBox':
                return <>
                    <Container>
                        <p style={{ fontSize: 20, fontStyle: 'italic', textAlign: 'center' }}>{textoVentanaMaria}</p>
                        <Form.Group as={Col} md="12" lg="12">
                            <Form.Control
                                style={{ height: 38 }}
                                type="text"
                                name="ventanaMaria_InputBoxValue"
                                value={ventanaMaria_InputBoxValue}
                                placeholder='Ingrese un valor..'
                                required
                                onChange={(e) => setVentanaMaria_InputBoxValue(e?.target?.value)}
                            />
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                            <Button variant='success' style={{ width: 170 }} disabled={ventanaMaria_InputBoxValue?.length === 0} onClick={() => enviarEventoDespacho(ventanaMaria_InputBoxValue, 'Validar lo ingresado', 'VentanaMaria_InputBox')}>Validar lo ingresado</Button>
                        </div>
                    </Container>
                </>
            case 'Validacion de Item Finalizada.':
                return <>
                    <Container>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon style={{ color: 'green', fontSize: 30, }} icon={faCheckCircle} />
                            <h2>Validación de Item Finalizada.</h2>
                            <Button variant='success'  onClick={() => handleCloseModalValidarVerPDF()}>Ver impresión Malvina</Button>
                            <Button variant='danger' style={{ width: 70, marginTop:10 }} onClick={() => handleCloseModalValidar()}>Cerrar</Button>
                        </div>
                    </Container>
                </>
            case 'Error':
                return <>
                    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon style={{ color: 'red', fontSize: 30 }} icon={faExclamationCircle} />
                        <p style={{ fontSize: 20, fontStyle: 'italic', textAlign: 'center' }}>
                            {mensajeError?.length! > 0 ? <>
                                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    {mensajeError?.[0]?.error === undefined ? <Container>
                                        <p>{mensajeError?.[0]?.itemID}</p>
                                    </Container> :
                                        <Table responsive striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: 15 }}>Item.</th>
                                                    <th style={{ fontSize: 15 }}>Causante</th>
                                                    <th style={{ fontSize: 15 }}>Mensaje error</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mensajeError.map((item: { itemID: string; causante: string; error: string; }, index: React.Key | null | undefined) => (
                                                    <tr key={index}>
                                                        <td>{item?.itemID}</td>
                                                        <td>{item?.causante}</td>
                                                        <td>{item?.error}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>}
                                </div>
                            </>
                                : 'Ocurrió un error del servidor'}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <Button variant='success' style={{ width: 70 }} onClick={() => {
                                setMensajeError([])
                                handleCloseModalValidar()
                            }}>Cerrar</Button>
                        </div>
                    </Container>
                </>;
            case 'ErrorValidacion':
                return <>
                    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon style={{ color: 'red', fontSize: 30 }} icon={faExclamationCircle} />
                        <p style={{ fontSize: 20, fontStyle: 'italic', textAlign: 'center' }}>

                            {mensajeError?.length! > 0 ? <>
                                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    {mensajeError?.[0]?.error === undefined ? <Container>
                                        <p>{mensajeError?.[0]?.itemID}</p>
                                    </Container> :
                                        <Table responsive striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: 15 }}>Item.</th>
                                                    <th style={{ fontSize: 15 }}>Causante</th>
                                                    <th style={{ fontSize: 15 }}>Mensaje error</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mensajeError.map((item: { itemID: string; causante: string; error: string; }, index: React.Key | null | undefined) => (
                                                    <tr key={index}>
                                                        <td>{item?.itemID}</td>
                                                        <td>{item?.causante}</td>
                                                        <td>{item?.error}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>}
                                </div>
                            </>
                                : 'Ocurrió un error del servidor'}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <Button variant='success' style={{ width: 70 }} onClick={() => {
                                setMensajeError([])
                                handleCloseModalValidar()
                            }}>Cerrar</Button>
                        </div>
                    </Container>
                </>;
            default:
                break;
        }

    }

    const enviarEventoDespacho = async (argumentosVentanaMaria: string | null, botonSeleccionado: string, tipoEventoDespacho: string) => {
        let argumentoVentanaMaria

        switch (tipoEventoDespacho) {
            case 'VentanaMaria_ListView':
                argumentoVentanaMaria = [
                    botonSeleccionado,
                    argumentosVentanaMaria
                ];
                break;
            case 'VentanaMaria_MessageBox':
                argumentoVentanaMaria = [
                    botonSeleccionado
                ];
                break;
            case 'VentanaMaria_InputBox':
                argumentoVentanaMaria = [
                    botonSeleccionado, argumentosVentanaMaria
                ];
                break;

            default:
                break;
        }

        const bodyEnviarEventoDespacho = {
            "despachoID": Number(idDespacho),
            "evento": tipoEventoMariaID,
            "ventanaMariaID": ventanaMariaID,
            "cambiarPerfil": false,
            "perfilID": recordarPreguntaCheck ? perfilSeleccionadoID : null,
            "item": null,
            "argumentosVentanaMaria": argumentoVentanaMaria,
            "documentos": null,
            "cargaBultos": null,
            "certificaciones": null
        }
        setTipoVentanaMaria('')
        setContador(600)
        try {
            updateCargando(true)
            await api.EnviarEventoDespacho(bodyEnviarEventoDespacho)
            setVentanaMaria_InputBoxValue('')
            setOpcionSeleccionadaVentanaMaria('')
            obtenerEstadoValidacion()
            updateCargando(false);
        } catch (error) {
        }
    }

    const switchRecordarPregunta = () => {
        return <>
            {perfilSeleccionadoID !== null ?
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    {nombrePerfilSeleccionado?.length! > 0 && <Form.Check
                        type="switch"
                        id="save-switch"
                        label="Recordar"
                        checked={recordarPreguntaCheck}
                        onChange={(e) => setRecordarPreguntaCheck(e.target.checked)}
                    />}
                    {nombrePerfilSeleccionado?.length! > 0 && <p style={{ fontSize: 12, fontWeight: 'bold', marginLeft: 20, marginRight: 80 }}>PERFIL: {nombrePerfilSeleccionado}</p>
                    }                </div> : <div style={{ marginRight: '50%', marginTop: -10 }}>
                    <Button onClick={() => {
                        cancelarValidar(1)
                        handleShowModalSeleccionarPerfil()
                    }} >Seleccionar Perfil</Button></div>}
        </>
    }

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        return [
            String(hours).padStart(2, '0'),
            String(minutes).padStart(2, '0'),
            String(secs).padStart(2, '0')
        ].join(':');
    };


    useEffect(() => {
        if (!showModalValidar) {
            setMensajeError([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!showModalValidar])

    return (
        <>
            <Modal backdrop="static" keyboard={false}
                centered show={showModalValidar} onHide={handleCloseModalValidar} size={mensajeError?.length > 0 ? 'lg' : undefined}
            >
                {tipoVetanaMaria !== 'Validacion de Item Finalizada.' && <Modal.Header closeButton={false}
                >
                    <Modal.Title style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ textAlign: 'left', marginLeft: '5px' }}>{tituloValidarDespacho}</div>
                        {
                            (tipoVetanaMaria === 'VentanaMaria_MessageBox' ||
                                tipoVetanaMaria === 'VentanaMaria_InputBox' ||
                                tipoVetanaMaria === 'VentanaMaria_ListView') &&
                            <div style={{ textAlign: 'right', marginRight: '5px', color: '#0528f0' }}>
                                Tiempo: {formatTime(contador)}
                            </div>
                        }
                    </Modal.Title>
                </Modal.Header>}
                <Modal.Body>
                    <>
                        <Col>
                            {cargandoDespachoValidacion && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                                <p style={{ fontSize: 20, fontWeight: 'bold' }}>{mensajeDespachoProgreso}</p>
                                <Loader active={cargandoDespachoValidacion} type='ball-beat' />
                            </div>}
                        </Col>
                        {!cargandoDespachoValidacion && tableValidarDespachoOpciones()}
                        {(tipoVetanaMaria === 'VentanaMaria_InputBox' || tipoVetanaMaria === 'VentanaMaria_ListView') && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                            <Button variant='danger' onClick={() => handleShowModalConfirmarCancelar()}>Cancelar el tratamiento</Button>
                        </div>}
                    </>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: 20 }}>
                        {((tipoVetanaMaria === 'VentanaMaria_ListView' || tipoVetanaMaria === 'VentanaMaria_MessageBox' || tipoVetanaMaria === 'VentanaMaria_InputBox') && switchRecordarPregunta())}
                        <p>[ESC] Cancelar </p>
                    </div>
                </Modal.Body>
            </Modal></>
    )
}

export default ModalValidarDespacho