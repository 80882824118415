import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Container, Modal } from 'react-bootstrap';
import '../ConvertirLicenciaModal/style-modal.css'

interface ModalAlertasUsuarioProps {
    cuitDespachante: string | undefined
}

const ModalAlertasUsuario = ({ cuitDespachante }: ModalAlertasUsuarioProps) => {

    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    const [mensajeAlerta, setMensajeAlerta] = useState('')
    let api = useAdvAPI();

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => setShow(true);

    const obtenerNotificacionesUsuarios = async () => {
        updateCargando(true)
        try {
            await api.obtenerNotificacionesUsuarios(Number(cuitDespachante)).then((resp) => {
                resp?.[0]?.Mensaje?.length > 0 ?
                setMensajeAlerta(resp?.[0]?.Mensaje) : setMensajeAlerta('')})
            updateCargando(false);
        } catch (error) {
            updateCargando(false);
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (show) {
            obtenerNotificacionesUsuarios()
        }
     // eslint-disable-next-line
    }, [show])


    return (
        <>
            <Button title='Alertas' variant="primary" onClick={handleShow} style={{ fontSize: 15, color: 'white' }}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faExclamationTriangle} />Alertas</Button>
            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Alertas</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    <Modal.Body>
                        <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                            {mensajeAlerta !== '' ? <div style={{ overflowY: mensajeAlerta !== '' ? 'scroll' : undefined, height: 270, border: '1px solid #000', backgroundColor: 'white', color: 'black' }}>
                                <p>{mensajeAlerta}</p>
                            </div> : <p style={{fontWeight:'bold', fontSize:30}}>Sin mensajes de alerta</p>}
                        </Container>
                        <Container style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignContent: 'center', justifyItems: 'center', alignItems: 'center', marginTop: 15 }}>
                            <Button variant="danger" onClick={handleClose}>
                                Cerrar
                            </Button>

                        </Container>
                    </Modal.Body>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalAlertasUsuario