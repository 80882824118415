import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Col, Container, Form, Modal } from 'react-bootstrap';

interface subItemProps {
    cantidadDeclarada: string
    cantidadEstadistica: string
    unitario: string
    fobTotal: string
    itemNro: string
    subItemNro: string
    setReloadForm: (args: boolean) => void
    handleShow: boolean
    setShowModalEditarSubItem: Dispatch<SetStateAction<number | null>>
}

const ModalEditarSubItem = ({ cantidadDeclarada, cantidadEstadistica, unitario, fobTotal, itemNro, subItemNro, handleShow, setShowModalEditarSubItem }: subItemProps) => {

    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    const [fobTotalValue, setFOBTotalValue] = useState(fobTotal)
    const [unitarioValue, setUnitarioValue] = useState(unitario)

    const handleClose = () => {
        setShow(false)
        setShowModalEditarSubItem(null)
    }

    useEffect(() => {
        if (handleShow) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [handleShow]);

    const updateInfo = async () => {
        updateCargando(true)
        // try {
        //     await api.updateCPL({
        //         "ID": id,
        //         "ICPL": ICPL,
        //         "NART": NART,
        //         "CCPL": CCPL,
        //         "ICPLDIF": ICPLDIF,
        //         "MCPL": MCPLValue,
        //         "AutomatizadorMaria_DDTID": AutomatizadorMaria_DDTID
        //     })
        //     updateCargando(false)
        //     setReloadForm(true)
        //     setShow(false)
        // } catch (error) {
        //     updateCargando(false)
        //     setErrorCarga('Ocurrío un error al editar')
        //     console.log('error', error)
        // }
    }
    useEffect(() => {

    }, [show])


    return (
        <>
            {/* <Button title='Editar' variant="link" onClick={handleShow} style={{ fontSize: 20 }}><FontAwesomeIcon icon={faEdit} /></Button> */}
            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Item {itemNro}-Subitem {subItemNro}</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            <Container>
                                {/* {errorCarga?.length > 0 && <Alert key={'danger'} variant={'danger'} style={{ marginTop: 5 }}>
                                    {errorCarga}
                                </Alert>} */}
                                <Form.Row>
                                    <Form.Group as={Col} md="3" lg="3">
                                        <Form.Label>Cantidad Declarada</Form.Label>
                                        <Form.Control
                                            style={{ height: 37, marginTop: 0 }}
                                            type="number"
                                            name="MCPL"
                                            value={cantidadDeclarada}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" lg="3"  >
                                        <Form.Label>Cantidad Estadistica</Form.Label>
                                        <Form.Control
                                            style={{ height: 37, marginTop: 0 }}
                                            type="number"
                                            name="MCPL"
                                            value={cantidadEstadistica}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="5" lg="3"  >
                                        <Form.Label>Unitario</Form.Label>
                                        <Form.Control
                                            style={{ height: 37, marginTop: 0 }}
                                            type="number"
                                            name="MCPL"
                                            value={unitarioValue}
                                            onChange={(e) => setUnitarioValue(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="5" lg="3"  >
                                        <Form.Label>FOB Total</Form.Label>
                                        <Form.Control
                                            style={{ height: 37, marginTop: 0 }}
                                            type="number"
                                            name="MCPL"
                                            value={fobTotalValue}
                                            onChange={(e) => setFOBTotalValue(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Container>
                        </Modal.Body>}
                    <Modal.Footer>
                        <Button variant="success" onClick={() => updateInfo()}>
                            Guardar
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalEditarSubItem