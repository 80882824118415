import { useApi } from 'ApiHooks';
import { TabCaratula } from 'Enums';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react'
import { Card, Container, Table } from 'react-bootstrap';

interface PropsFacturacionTable {
    interno: string
    activeTabKey: string
}

interface Factura {
    EmpresaId: string;
    Carpeta: string;
    Tipo: string;
    Numero: string;
    Fecha: string;
    Referencia: string;
    Total: number;
    ModificadoPor: number;
    NombreModificadoPor: string;
    ModificadoEl: string;
}

enum TiposFacturas {
    Rendicion_Gastos = 'CRG',
    Adelantos = 'AN',

}

const FacturacionTable = ({ interno, activeTabKey }: PropsFacturacionTable) => {

    let api = useApi();

    const [documentosFacturas, setDocumentosFacturas] = useState([])
    const [facturasCRG, setFacturasCRG] = useState([])
    const [facturasAdelantos, setFacturasAdelantos] = useState([])
    const [hasFetchedCaratula, setHasFetchedCaratula] = useState(false);

    function formatNumber(num: number, decimales?: number) {
        let formattedNum = new Intl.NumberFormat('es-ES', {
            minimumFractionDigits: decimales ?? 2,
            maximumFractionDigits: decimales ?? 2
        }).format(num);

        return formattedNum;
    }
    const getCaratula = async () => {
        try {
            let caratula = await api.getCaratula(interno);
            setDocumentosFacturas(caratula?.DocumentosFacturacion?.filter((el: Factura) => el.Tipo !== TiposFacturas.Adelantos && el.Tipo !== TiposFacturas.Rendicion_Gastos))
            setFacturasCRG(caratula?.DocumentosFacturacion?.filter((el: Factura) => el.Tipo === TiposFacturas.Rendicion_Gastos))
            setFacturasAdelantos(caratula?.DocumentosFacturacion?.filter((el: Factura) => el.Tipo === TiposFacturas.Adelantos))
        } catch (error) {
        }
    }
    useEffect(() => {
        if (activeTabKey === TabCaratula.Facturacion && !hasFetchedCaratula) {
            getCaratula();
            setHasFetchedCaratula(true);
        }
        //eslint-disable-next-line
    }, [activeTabKey]);


    return (
        <>
            <hr />
            <Container>
                <Card>
                    <Card.Header style={{ backgroundColor: '#393a3b' }}><p style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }}>Adelantos</p></Card.Header>
                    <Card.Body>
                        <div style={{ height: 150, overflowY: 'scroll' }}>
                            <Table responsive className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Numero</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Referencia</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>{facturasAdelantos?.map((factura: Factura) =>
                                (
                                    <tr>
                                        <th scope="row">{factura?.Numero}</th>
                                        <th scope="row">{DateTime.fromISO(factura?.Fecha).toFormat('dd/MM/yyyy')}</th>
                                        <th scope="row">{factura?.Referencia}</th>
                                        <th scope="row" style={{ textAlign: 'right' }}>{formatNumber(factura?.Total, 2)}</th>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
            <hr />
            <Container style={{ marginTop: 5 }}>
                <Card>
                    <Card.Header style={{ backgroundColor: '#393a3b' }}><p style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }}>Rendición de Gastos</p></Card.Header>
                    <Card.Body>
                        <div style={{ height: 150, overflowY: 'scroll' }}>
                            <Table responsive className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Numero</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Referencia</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>{facturasCRG?.map((factura: Factura) =>
                                (
                                    <tr>
                                        <th scope="row">{factura?.Numero}</th>
                                        <th scope="row">{DateTime.fromISO(factura?.Fecha).toFormat('dd/MM/yyyy')}</th>
                                        <th scope="row">{factura?.Referencia}</th>
                                        <th scope="row" style={{ textAlign: 'right' }}>{formatNumber(factura?.Total, 2)}</th>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
            <hr />
            <Container style={{ marginTop: 5 }}>
                <Card>
                    <Card.Header style={{ backgroundColor: '#393a3b' }}><p style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }}>Documentos Facturas</p></Card.Header>
                    <Card.Body>
                        <div style={{ height: 150, overflowY: 'scroll' }}>
                            <Table responsive className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Numero</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Referencia</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>{documentosFacturas?.map((factura: Factura) =>
                                (
                                    <tr>
                                        <th scope="row">{factura?.Tipo}</th>
                                        <th scope="row">{factura?.Numero}</th>
                                        <th scope="row">{DateTime.fromISO(factura?.Fecha).toFormat('dd/MM/yyyy')}</th>
                                        <th scope="row">{factura?.Referencia}</th>
                                        <th scope="row" style={{ textAlign: 'right' }}>{formatNumber(factura?.Total, 2)}</th>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default FacturacionTable