import { ObtenerItemVistaResponse } from 'modelos/Advanced'
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Button, Form, Modal, Table } from 'react-bootstrap'

interface propsModalSelectMultipleItems {
    showModalModificarItems: boolean
    handleCloseModalModificarItems: () => void
    cargando: boolean
    tableDataItems: ObtenerItemVistaResponse[]
    selectedItems: {
        [key: string]: boolean;
    }
    setSelectedItems: React.Dispatch<React.SetStateAction<{
        [key: string]: boolean;
    }>>
    insertDatosGlobalesInfComp: any
}

const ModalSelectMultipleItems = ({ showModalModificarItems, handleCloseModalModificarItems, cargando, tableDataItems, selectedItems, setSelectedItems, insertDatosGlobalesInfComp }: propsModalSelectMultipleItems) => {

    const [selectAll, setSelectAll] = useState(true)

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        const newSelectedItems: { [key: string]: boolean } = {};
        tableDataItems!?.forEach(item => {
            newSelectedItems[item.NART] = newSelectAll;
        });
        setSelectAll(newSelectAll);
        setSelectedItems(newSelectedItems);
    };

    const handleCheckboxChange = (NART: string) => {
        const newSelectedItems = {
            ...selectedItems,
            [NART]: !selectedItems[NART]
        };
        setSelectedItems(newSelectedItems);

        const allSelected = Object.values(newSelectedItems).every(value => value);
        setSelectAll(allSelected);
    }

    useEffect(() => {
        if (showModalModificarItems) {
            const newSelectedItems: { [key: string]: boolean } = {};
            tableDataItems!?.forEach(item => {
                newSelectedItems[item.NART] = true; 
            });
    
            setSelectedItems(newSelectedItems);
            setSelectAll(true);
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalModificarItems, tableDataItems]);

    return (
        <>
            <Modal show={showModalModificarItems} onHide={handleCloseModalModificarItems} size='lg' backdrop="static"
                centered
                keyboard={false}
                style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                <BlockUi blocking={cargando}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Lista ITEMS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ height: 500, width: '100%', overflowY: 'scroll' }}>
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>
                                            <Form.Check type="checkbox" name="SelectAll" checked={selectAll}
                                                onChange={handleSelectAllChange} />
                                        </th>
                                        <th>ITEM</th>
                                        <th>NCM</th>
                                        <th>Unidad</th>
                                        <th>Cantidad</th>
                                        <th>FOB</th>
                                        <th>Lista</th>
                                        <th>Origen</th>
                                        <th>Ventajas</th>
                                        <th>Info</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableDataItems?.map((row: ObtenerItemVistaResponse, index: number) => (
                                        <>
                                            <tr>
                                                <td>
                                                    <Form.Check type="checkbox" name={row?.NART} checked={selectedItems[row.NART] || false}
                                                        onChange={() => handleCheckboxChange(row.NART)} />
                                                </td>
                                                <td>{row.NART}</td>
                                                <td>{row.IESPNCE}</td>
                                                <td>{row.CARTUNTDCL}</td>
                                                <td>{row.CARTUNTDCL}</td>
                                                <td>{row.MARTFOB}</td>
                                                <td>{row.LISTA}</td>
                                                <td>{row.CARTPAYPRC}</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModalModificarItems}>
                            Volver
                        </Button>
                        <Button variant="primary" onClick={() => insertDatosGlobalesInfComp()}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal></>)
}

export default ModalSelectMultipleItems