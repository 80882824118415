
import React, { useContext, useEffect, useRef, useState, useReducer } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "App";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import BlockUi from "react-block-ui";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormCheck, MyFormControl } from "../../FormikHooks";
import { Alineacion, GrillaSync } from "Grilla";
import { strCmp } from "Utilidades";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useApi } from "ApiHooks";


enum EstadoModal {
    Cerrado,
    Creando,
    Modificando
}

export function Tareas(props: {
    onTareasChanged: (tareas: any[]) => void,
    onSelectTarea?: (tarea: any) => void
}) {
    const { onTareasChanged } = props;
    let [tareas, updateTareas] = useState<any[] | null>(null);
    let [cargando, updateCargando] = useState(true);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    let formikRef = useRef<FormikProps<any>>(null);
    let [modal, updateModal] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModal.Cerrado, valorModificando: null };
        } else if (accion.tipo === 'mostrarCrear') {
            return { estadoModal: EstadoModal.Creando, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { estadoModal: EstadoModal.Modificando, valorModificando: accion.valor };
        }
        return estado;
    }, { estadoModal: EstadoModal.Cerrado, valorModificando: null });
    useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getTareas();
                updateTareas(respuesta?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(true)) {
                    console.error('Error al cargar tareas', error);
                    mostrarError('Error al cargar tareas');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (tareas !== null && tareas !== undefined) {
            onTareasChanged(tareas);
        }
    }, [tareas, onTareasChanged]);
    async function eventoEliminar(item: any) {
        let nombreTarea = item.Nombre;
        updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar la tarea ${nombreTarea}?`);
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                await api.deleteTarea(item.Id);
                updateTareas((t: any) => t?.filter((c: any) => c.Id !== item.Id)
                    ?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar tarea', error);
                    mostrarError('Error al eliminar tarea');
                }
                if (!api.isUnmounted()) {
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    const eventoModificar = (item: any) => updateModal({ tipo: 'mostrarModificar', valor: item });
    const eventoSeleccionar = props.onSelectTarea ?? eventoModificar;
    return <>
        <BlockUi blocking={cargando}>
            <GrillaSync datos={tareas ?? []} campos={[{ propiedad: 'Id', visible: false, clave: true },
            { titulo: 'Nombre', propiedad: 'Nombre' },
            {
                titulo: 'Reportable', propiedad: 'ReportablePorDefecto', alineacion: Alineacion.Centro,
                plantillaFormato: (valor: any) => {
                    if (valor) {
                        return <FontAwesomeIcon size="lg" icon={faPrint}></FontAwesomeIcon>
                    } else {
                        return '';
                    }
                }
            },
            { titulo: 'Lleva Estado', propiedad: 'LlevaEstado', plantillaFormato: (valor: any) => valor !== false ? 'Sí' : 'No' }]}
                eventoAgregar={() => updateModal({ tipo: 'mostrarCrear' })}
                eventoDetalle={eventoSeleccionar}
                eventoModificar={props.onSelectTarea ? eventoModificar : undefined}
                eventoEliminar={eventoEliminar} />
        </BlockUi>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogoConfirmar} textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
        <MyModal show={modal.estadoModal !== EstadoModal.Cerrado}
            onHide={() => updateModal({ tipo: 'cerrar' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{modal.estadoModal === EstadoModal.Modificando ? 'Modificar Tarea' : 'Crear Tarea'}</h2>
                </Modal.Header>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
                    'ReportablePorDefecto': Yup.boolean(),
                    'LlevaEstado': Yup.boolean()
                })} initialValues={{
                    'Nombre': modal.valorModificando?.Nombre ?? '',
                    'ReportablePorDefecto': modal.valorModificando ? (modal.valorModificando.ReportablePorDefecto ?? false) : true,
                    'LlevaEstado': modal.valorModificando ? (modal.valorModificando.LlevaEstado ?? true) : false
                }} onSubmit={async (nuevaTarea: any, actions) => {
                    let tareasExistentes = tareas;
                    if (modal.valorModificando) {
                        tareasExistentes = tareas!.filter((t: any) => t.Id !== modal.valorModificando.Id);
                    }
                    if (tareasExistentes!.map((t: any) => t.Nombre).includes(nuevaTarea.Nombre)) {
                        actions.setFieldError('Nombre', `La tarea con nombre ${nuevaTarea.Nombre} ya fue ingresada`);
                    } else {
                        try {
                            nuevaTarea = {
                                ...nuevaTarea,
                                Id: modal.valorModificando?.Id,
                                NroClienteAlpha: userInfo.nroClienteAlpha,
                                EmpresaId:  (storage?.getItem('empresaActual') || userInfo.empresaActual),
                            };
                            let { exito, error, nuevaTarea: nuevaTareaConId } = await api.insertTarea(nuevaTarea);
                            if (exito) {
                                let nuevasTareas = Array.from(tareas ?? []);
                                if (modal.valorModificando) {
                                    nuevasTareas = nuevasTareas.filter((r: any) => r.Id !== modal.valorModificando.Id);
                                }
                                nuevasTareas.push(nuevaTareaConId);
                                updateTareas(nuevasTareas.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)));
                                updateModal({ tipo: 'cerrar' });
                            } else {
                                mostrarError(error);
                            }
                        } catch (error) {
                            if (!api.isCancel(error)) {
                                console.error('Error al guardar tarea', error);
                                mostrarError('Error al guardar tarea');
                            }
                        }
                    }
                }}>{({ submitForm, isSubmitting }) => <>
                    <Modal.Body>
                        <MyForm>
                            <Form.Group>
                                <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormCheck name="ReportablePorDefecto" label="Reportable (por defecto)"></MyFormCheck>
                            </Form.Group>
                            <Form.Group>
                                <MyFormCheck name="LlevaEstado" label="Lleva estado"></MyFormCheck>
                            </Form.Group>
                        </MyForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateModal({ tipo: 'cerrar' })}>
                            Cancelar
                        </Button>
                        <Button disabled={isSubmitting} onClick={submitForm}>
                            Ingresar
                        </Button>
                    </Modal.Footer>
                </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
    </>;
}