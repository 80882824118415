import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, ProgressBar } from 'react-bootstrap'
import Loader from 'react-loaders'

export interface ModalVistaPreviaArchivoProps {
    showModalVistaArchivo: boolean
    handleCloseModalVisualizarArchivo: () => void
    cargando: boolean
    progress: number | undefined
    base64Archivo: any
    archivoExtension: string
}

const ModalVistaPreviaArchivo = ({
    showModalVistaArchivo,
    base64Archivo,
    handleCloseModalVisualizarArchivo,
    progress,
    archivoExtension,
    cargando,
}: ModalVistaPreviaArchivoProps) => {

    const extensionesSoportadas = ['pdf', 'png', 'jpg', 'jpeg']
    const esArchivoSoportado = extensionesSoportadas.includes(archivoExtension.toLowerCase())

    const [PDF, setPDF] = useState('')

    const abrirEnNuevaVentana = () => {
        if (archivoExtension === 'pdf' && base64Archivo) {
            const byteCharacters = atob(base64Archivo);
            const byteNumbers = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const blob = new Blob([byteNumbers], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        }
    };

    const base64ToBlob = (base64: string, contentType = 'application/pdf') => {
        const byteCharacters = atob(base64);
        const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    };

    useEffect(() => {
        if (base64Archivo?.length > 0) {
            const blob = base64ToBlob(base64Archivo);
            const blobURL = URL.createObjectURL(blob);
            setPDF(blobURL)
        }
    }, [base64Archivo])

    return (
        <Modal
            animation={false}
            backdrop="static"
            show={showModalVistaArchivo}
            onHide={handleCloseModalVisualizarArchivo}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
            style={{ width: '100%' }}
        >
            <Modal.Header closeButton={false}>
                <Modal.Title>Visualizar Archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 5,
                        alignContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {progress === 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <p style={{ fontWeight: 'bold' }}>Obteniendo archivo..</p>
                            <Loader active={cargando} type="ball-beat" />
                        </div>
                    )}
                    {base64Archivo ? (
                        esArchivoSoportado ? (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: '1px solid #ccc',
                                    margin: '20px auto',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {archivoExtension === 'pdf' ?
                                    <object
                                        aria-label='PDF'
                                        data={PDF}
                                        type="application/pdf"
                                        width="100%"
                                        height="600px"
                                        style={{ border: "none" }}

                                    />
                                    : <img
                                        src={`data:image/png;base64,${base64Archivo}`}
                                        alt="Vista previa"
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                        }}
                                    />}
                            </div>
                        ) : (
                            <p style={{ fontWeight: 'bold', color: 'red' }}>
                                El archivo con extensión <strong>{archivoExtension}</strong> no es soportado.
                            </p>
                        )
                    ) : (
                        <ProgressBar
                            style={{ width: 270, height: 25 }}
                            striped
                            variant={progress === 100 ? 'success' : 'primary'}
                            now={progress}
                            label={
                                <p style={{ fontSize: 15 }}>
                                    {progress === 100 ? 'Descarga completa' : `${progress}%`}
                                </p>
                            }
                        />
                    )}
                </Col>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {archivoExtension === 'pdf' && base64Archivo && (
                        <Button onClick={abrirEnNuevaVentana} variant="primary">
                            Abrir en nueva ventana
                        </Button>
                    )}
                    <Button style={{ marginTop: 10 }} onClick={handleCloseModalVisualizarArchivo} variant="danger">
                        Cerrar
                    </Button>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalVistaPreviaArchivo
