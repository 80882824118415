import { Formik, Field, FormikProps, FormikHelpers } from "formik";
import React from "react";
import { Alert, Tabs, Tab, Form, Col, Button } from "react-bootstrap";
import { AppContext, NotFoundComponent } from "../../App";
import { MyForm, MyFormControl, MyAsyncSelect, OnValueChangeArgument, MySelect, MyTextarea } from "../../FormikHooks";
import { IngresoSufijos, IngresoSufijosRef } from "../../IngresoSufijos";
import { useDecodedParams, convertirDatosGenericosAOption, optionLabelConCodigo, isNullOrWhiteSpace, toFixedDecimal } from "../../Utilidades";
import * as Yup from 'yup';
import { useHistory } from "react-router";
import { DateTime } from "luxon";
import { EstadoNcmArticuloProcesoExcel } from "Enums";
import { useApi, TipoLock } from "ApiHooks";

function enCeroSiEsVacio(valor: string | null | undefined) {
    return isNullOrWhiteSpace(valor) ? '0' : valor;
}

export function EditarArticuloProcesoExcel() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let sufijosRef = React.useRef<IngresoSufijosRef>(null);
    let history = useHistory();
    let { nroLinea, interno } = useDecodedParams() as { nroLinea: string, interno: string };
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = React.useState(true);
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [notFound, updateNotFound] = React.useState(false);
    let articulo = React.useRef<any>(null);
    let ncmValidado = React.useRef<any>(null);
    let [labelUnidadEstadistica, updateLabelUnidadEstadistica] = React.useState('');
    let schema = React.useMemo(() => {
        return Yup.object({
            'ForzarSufijos': Yup.boolean(),
            'Codigo': Yup.string().nullable().required('Debe ingresar el codigo de articulo'),
            'CantidadEstadistica': Yup.number().when('CodigoUnidadEstadistica', {
                is: '01',
                then: Yup.number().nullable().typeError('Debe ingresar un número')
                    .required('Debe ingresar la cantidad estadistica')
                    .min(0, 'La cantidad estadistica debe ser mayor o igual a cero'),
                otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
                    .required('Debe ingresar la cantidad estadistica')
                    .moreThan(0, 'La cantidad estadistica debe ser mayor a cero'),
            }),
            'CantidadDeclarada': Yup.number().nullable().typeError('Debe ingresar un número'),
            'PrecioUnitario': Yup.number().nullable().typeError('Debe ingresar un número')
                .min(0, 'El precio unitario debe ser mayor a cero'),
            'FobTotal': Yup.number().nullable().typeError('Debe ingresar un número')
                .min(0, 'El fob total debe ser mayor a cero'),
            'CodigoUnidadEstadistica': Yup.string().nullable(),
            'Descripcion': Yup.string().nullable(),
            'DescripcionMaria': Yup.string().nullable(),
            'NCM': Yup.string().nullable().required('Debe ingresar el NCM'),
            'NCMCatalogo': Yup.string().nullable(),
            'CatalogoId': Yup.string().nullable(),
            'CodigoUnidadDeclarada': Yup.string().nullable(),
            'CodigoPaisOrigen': Yup.string().nullable(),
            'Sufijos': Yup.string().nullable(),
            'CodigoEstadoMercaderia': Yup.string().nullable(),
            'KgNeto': Yup.number().nullable().typeError('Debe ingresar un número'),
            'Filler': Yup.string().nullable(),
            'Lista': Yup.string().nullable(),
            'InsumosAConsumo': Yup.number().nullable().typeError('Debe ingresar un número'),
            'InsumosTemporales': Yup.number().nullable().typeError('Debe ingresar un número'),
            'AjusteIncluir': Yup.number().nullable().typeError('Debe ingresar un número'),
            'AjusteDeducir': Yup.number().nullable().typeError('Debe ingresar un número'),
        });
    }, []);
    async function cargarUnidadEstadistica(codigoUnidad: string) {
        let unidades = await api.getUnidades();
        let unidad = unidades.find((item: any) => item.Codigo === codigoUnidad);
        if (unidad) {
            updateLabelUnidadEstadistica(unidad.Codigo + ' - ' + unidad.Descripcion);
        } else {
            updateLabelUnidadEstadistica(codigoUnidad);
        }
    }
    async function cargarDatosNcm(ncm: string) {
        try {
            let ncmValidado = await api.validarNcmArreglandoPuntos(ncm);
            if (ncmValidado === null || ncmValidado === undefined) {
                formikRef.current?.setFieldValue('CodigoUnidadEstadistica', '', true);
                updateLabelUnidadEstadistica('');
            } else {
                formikRef.current?.setFieldValue('Ncm', ncmValidado.Ncm.POSICION_ARANCELARIA, true);
                formikRef.current?.setFieldValue('CodigoUnidadEstadistica', ncmValidado.Ncm.m012, false);
                await cargarUnidadEstadistica(ncmValidado.Ncm.m012);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al validar NCM');
                mostrarError('Error al validar NCM');
            }
        }
    }
    async function cargarCatalogos() {
        try {
            let respuesta = await api.getCatalogos();
            return respuesta.map((c: any) => ({ value: c.Codigo, label: c.Descripcion }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar catalogos', error);
                mostrarError('Error al cargar catalogos');
            }
            return [];
        }
    }
    React.useEffect(() => {
        async function cargar() {
            try {
                if (!interno || !nroLinea) {
                    return;
                }
                articulo.current = await api.getArticuloProcesoExcel(interno, nroLinea);
                if (!articulo.current) {
                    updateNotFound(true);
                    return;
                }
                formikRef.current?.resetForm({
                    values: {
                        'ForzarSufijos': false,
                        'Codigo': articulo.current.Codigo,
                        'NCM': articulo.current.EstadoNCM === EstadoNcmArticuloProcesoExcel.Catalogo ? articulo.current.NCMCatalogo : articulo.current.NCM,
                        'NCMCatalogo': articulo.current.NCMCatalogo,
                        'CatalogoId': articulo.current.CatalogoId,
                        'Descripcion': articulo.current.Descripcion,
                        'DescripcionMaria': articulo.current.DescripcionMaria,
                        'CodigoUnidadDeclarada': articulo.current.CodigoUnidadDeclarada,
                        'CodigoPaisOrigen': articulo.current.CodigoPaisOrigen,
                        'CodigoPaisProcedencia': articulo.current.CodigoPaisProcedencia,
                        'Sufijos': articulo.current.Sufijos,
                        'CodigoEstadoMercaderia': articulo.current.EstadoMercaderia,
                        'PrecioUnitario': articulo.current.PrecioUnitario,
                        'CantidadEstadistica': toFixedDecimal(articulo.current.CantidadEstadistica, 2),
                        'CantidadDeclarada': toFixedDecimal(articulo.current.CantidadDeclarada, 2),
                        'FobTotal': toFixedDecimal(articulo.current.FobTotal, 2),
                        'KgNeto': toFixedDecimal(articulo.current.KgNeto, 2),
                        'Filler': articulo.current.Filler,
                        'Lista': articulo.current.Lista,
                        'InsumosAConsumo': toFixedDecimal(articulo.current.InsumosAConsumo, 2),
                        'InsumosTemporales': toFixedDecimal(articulo.current.InsumosTemporales, 2),
                        'AjusteIncluir': toFixedDecimal(articulo.current.AjusteIncluir, 2),
                        'AjusteDeducir': toFixedDecimal(articulo.current.AjusteDeducir, 2),
                    }
                });
                cargarDatosNcm(articulo.current.NCM);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar articulo proceso Excel', error);
                    mostrarError('Error al cargar articulo Proceso Excel');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    async function getMarca(IdCatalogo: string) {
        updateCargando(true);
        try {
            let respuesta = await api.getCatalogo(IdCatalogo);
            updateCargando(false);
            return respuesta.SufijoAA;
        } catch (error) {
            if (api.isCancel(error)) {
                throw error;
            }
            console.error('Error al buscar marca de catalogo', error);
            updateCargando(false);
            return null;
        }
    }
    async function preValidacion() {
        let values = formikRef.current!.values;
        if (isNullOrWhiteSpace(values.Ncm)) {
            formikRef.current!.setFieldError('NCM', 'Debe ingresar el ncm');
            formikRef.current!.setFieldTouched('NCM', true, false);
            return false;
        }
        ncmValidado.current = await api.validarNcmArreglandoPuntos(values.Ncm);
        if (ncmValidado.current) {
            formikRef.current!.setFieldValue('CodigoUnidadEstadistica', ncmValidado.current.Ncm.m012);
            await cargarUnidadEstadistica(ncmValidado.current.Ncm.m012);
        } else {
            formikRef.current!.setFieldError('NCM', 'El ncm ingresado no es valido');
            formikRef.current!.setFieldTouched('NCM', true, false);
            return false;
        }
        return true;
    }
    async function submit(values: any, helpers: FormikHelpers<any>) {
        let marca = articulo.current.Marca;
        try {
            if (isNullOrWhiteSpace(marca) && !isNullOrWhiteSpace(articulo.current.CatalogoId)) {
                marca = await getMarca(articulo.current.CatalogoId);
            }
        } catch (error) {
            if (api.isCancel(error)) {
                return;
            }
        }
        return sufijosRef.current?.mostrar(!values.ForzarSufijos, ncmValidado.current.Ncm.POSICION_ARANCELARIA, values.Sufijos, marca, articulo.current.Modelo, articulo.current.Codigo)
            .then(async sufijosFinal => {
                try {
                    let puedeGuardar = await api.obtenerLock(TipoLock.CaratulaProcesoExcel, interno);
                    if (puedeGuardar) {
                        let { exito, error } = await api.updateArticuloProcesoExcel({
                            Carpeta: interno,
                            LineaNro: nroLinea,
                            NCM: ncmValidado.current.Ncm.POSICION_ARANCELARIA,
                            Sufijos: sufijosFinal,
                            Descripcion: values.Descripcion,
                            DescripcionMaria: values.DescripcionMaria,
                            KgNeto: enCeroSiEsVacio(toFixedDecimal(values.KgNeto, 2)),
                            CantidadEstadistica: enCeroSiEsVacio(toFixedDecimal(values.CantidadEstadistica, 2)),
                            CodigoUnidadDeclarada: values.CodigoUnidadDeclarada,
                            ModificadoPor: userInfo.claims.nameid,
                            ModificadoEl: DateTime.local().toISO(),
                        });
                        await api.eliminarLock(TipoLock.CaratulaProcesoExcel, interno);
                        if (exito) {
                            //volver
                            //history.push('/procesoExcel/caratulas/' + encodeURIComponent(interno) + '/articulos');
                            history.goBack();

                        } else {
                            updateMensajeErrorAlert(error);
                        }
                    } else {
                        updateMensajeErrorAlert('No se pudo guardar el articulo porque otra persona está usando la caratula');
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al guardar articulo proceso excel', error);
                        mostrarError('Error al guardar articulo Proceso Excel');
                    }
                }
            }).catch(() => { });
    }
    async function onClickBotonSubmit(forzarSufijos: boolean) {
        try {
            formikRef.current?.setFieldValue('ForzarSufijos', forzarSufijos);
            updateCargando(true);
            let preValidacionOK = await preValidacion();
            updateCargando(false);
            if (preValidacionOK) {
                formikRef.current?.submitForm();
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al validar ncm', error);
                mostrarError('Error al validar ncm');
                updateCargando(false);
            }
        }
    }
    return notFound ? (<NotFoundComponent></NotFoundComponent>) : (<>
        
        <Alert key="info" variant="info">
            <b>Modificar Articulo</b> {articulo.current?.Codigo} <b>Interno:</b> {interno} {' - '}
            <Button size="sm" onClick={() => history.goBack()} variant="primary">Volver</Button>
        </Alert>



        {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
        <Formik initialValues={{
            Ncm: '', ForzarSufijos: false
        }} innerRef={formikRef} validationSchema={schema} onSubmit={submit}>
            <MyForm blocking={cargando}>
                <Field type="hidden" name="ForzarSufijos"></Field>
                <Tabs id="tabsCrearEditarSubitem">
                    <Tab eventKey="principal" title="Datos">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MySelect isDisabled name="CatalogoId" label="Catalogo" options={cargarCatalogos}
                                    getOptionLabel={optionLabelConCodigo} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl type="text" name="Codigo" label="Codigo Articulo" readOnly></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyTextarea name="Descripcion" label="Descripción Catalogo"></MyTextarea>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyTextarea name="DescripcionMaria" label="Descripción SEDI"></MyTextarea>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyAsyncSelect name="NCM" label="Posición"
                                    loadOptions={api.cargarNcm()} onValueChange={(option: OnValueChangeArgument) => {
                                        if (option) {
                                            let ncm = option as string;
                                            cargarDatosNcm(ncm);
                                        } else {
                                            formikRef.current?.setFieldValue('CodigoUnidadEstadistica', '');
                                            updateLabelUnidadEstadistica('');
                                        }
                                    }}></MyAsyncSelect>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl type="text" name="NCMCatalogo" label="Posición Catalogo" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl name="Filler" type="text" label="Filler" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl name="Lista" type="text" label="Lista" readOnly></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MySelect name="CodigoUnidadDeclarada" label="Unidad Declarada" options={() => api.getUnidades().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl type="number" name="CantidadDeclarada" label="Cantidad Declarada" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl type="number" name="PrecioUnitario" label="Precio Unitario" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl type="number" name="FobTotal" label="Total" readOnly></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Field type="hidden" name="CodigoUnidadEstadistica"></Field>
                                <Form.Label htmlFor="txtUnidadEstadistica">Unidad Estadistica</Form.Label>
                                <Form.Control id="txtUnidadEstadistica" type="text" disabled value={labelUnidadEstadistica}></Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl type="number" name="CantidadEstadistica" label="Cantidad Estadistica"></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl type="number" name="KgNeto" label="Kg Neto" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}></Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MySelect name="CodigoPaisOrigen" label="Pais Origen" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo} isDisabled />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MySelect name="CodigoPaisProcedencia" label="Pais Procedencia" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo} isDisabled />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MySelect name="CodigoEstadoMercaderia" label="Estado Mercaderia" options={() => api.getEstadosMercaderia().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo} isDisabled />
                            </Form.Group>
                            <Form.Group as={Col}></Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormControl name="AjusteIncluir" type="number" label="Ajuste Incluir" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl name="AjusteDeducir" type="number" label="Ajuste Deducir" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl name="InsumosAConsumo" type="number" label="Insumos A Consumo" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl name="InsumosTemporales" type="number" label="Insumos Temporales" readOnly></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyTextarea name="Sufijos" label="Sufijos" readOnly
                                    onKeyDown={async (e: any) => {
                                        if (e.key === 'F8') {
                                            let marca = articulo.current.Marca;
                                            try {
                                                let IdCatalogo = formikRef.current?.values?.IdCatalogo;
                                                if (isNullOrWhiteSpace(marca) && !isNullOrWhiteSpace(IdCatalogo)) {
                                                    marca = await getMarca(IdCatalogo);
                                                }
                                            } catch (error) {
                                                if (api.isCancel(error)) {
                                                    return;
                                                }
                                            }
                                            sufijosRef.current?.mostrar(false, formikRef.current?.values?.Ncm,
                                                formikRef.current?.values?.Sufijos, marca,
                                                formikRef.current?.values?.IdArticulo)?.then(ingreso => {
                                                    formikRef.current?.setFieldValue('Sufijos', ingreso);
                                                }).catch(() => { });

                                        }
                                    }}></MyTextarea>
                            </Form.Group>
                        </Form.Row>
                    </Tab>
                </Tabs>
                <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                <Button onClick={() => onClickBotonSubmit(false)} className="mt-2 ml-2 mb-2">Guardar</Button>
                <Button onClick={() => onClickBotonSubmit(true)} className="ml-2 mt-2 mb-2">Guardar con sufijos nuevos</Button>
            </MyForm>
        </Formik>
        <IngresoSufijos ref={sufijosRef}></IngresoSufijos>
    </>)
}