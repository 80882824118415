import React from "react";
import BlockUi from "react-block-ui";
import { Col, Form, Button, Nav, Table, Modal, Tabs, Tab } from "react-bootstrap";
import Worker from "worker-loader!Paginas/Catalogos/CargaMasiva/CargaMasivaWorker"; //eslint-disable-line import/no-webpack-loader-syntax
import * as Comlink from "comlink";
import XLSX from "xlsx";
import { BaseSelect as Select } from "BaseSelect";
import { Formik, useFormikContext, ErrorMessage, FormikHelpers, FormikProps } from "formik";
import { MyForm, MyFormControl, MySelect, MyFormCheck, SelectOption } from "../../FormikHooks";
import * as Yup from "yup";
import { AppContext, NotFoundComponent } from "../../App";
import { isInRole, isNullOrWhiteSpace, reemplazarCaracteresInvalidosSufijos, spliceString, strCmp, toFixedDecimal } from "../../Utilidades";
import bsCustomFileInput from "bs-custom-file-input";
import Decimal from "decimal.js";
import ConversorUnidadMedida from "../../ConversorUnidadMedida";
import { DateTime } from "luxon";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { MyModal } from "MyModal";
import { GrillaSync } from "Grilla";
import { AltaBajaMapeosPais, AltaBajaMapeosPaisRef } from "Paginas/Catalogos/CargaMasiva/AltaBajaMapeosPais";
import { AltaBajaMapeosUnidad, AltaBajaMapeosUnidadRef } from "Paginas/Catalogos/CargaMasiva/AltaBajaMapeosUnidad";
import { AltaBajaMapeosEstadoMerc, AltaBajaMapeosEstadoMercRef } from "Paginas/Catalogos/CargaMasiva/AltaBajaMapeosEstadoMerc";
import { useApi, TipoLock } from "ApiHooks";
import { storageFactory } from "storage-factory";

const storage = storageFactory(() => localStorage);


class ErrorProcesoExcel extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ErrorProcesoExcel';
    }
}

export enum CamposProcesoExcel {
    NumeroFactura = 1,
    CodigoArticulo,
    Descripcion,
    Ncm,
    UnidadDeclarada,
    Cantidad,
    Total,
    PaisOrigen,
    PaisProcedencia,
    KgNeto,
    FechaEmbarque,
    FechaArribo,
    DescripcionMaria,
    Marca,
    Modelo,
    FechaVencimiento,
    Presentacion,
    KgNetoUnitario,
    EstadoMercaderia,
    Filler,
    DJCP,
    PrecioUnitario,
    InsumosAConsumo,
    InsumosTemporales,
    Sufijos,
    SufijosParcial,
    SufijoABAI,
    CantidadEstadistica,
    AjusteIncluir,
    AjusteDeducir,
    Otros,
    SufijoAS, //agrego estos campos , no estaban (javierg)
    SufijoZA, //agrego estos campos , no estaban (javierg)
    SufijoZB, //agrego estos campos , no estaban (javierg)
    SufijoZC, //agrego estos campos , no estaban (javierg)
    Lista //agrego estos campos , no estaban (javierg)
}

const opcionesCampos = [
    { value: CamposProcesoExcel.NumeroFactura, label: 'Nro. Factura' },
    { value: CamposProcesoExcel.CodigoArticulo, label: 'Codigo Articulo' },
    { value: CamposProcesoExcel.Descripcion, label: 'Descripción Catalogo' },
    { value: CamposProcesoExcel.Ncm, label: 'NCM' },
    { value: CamposProcesoExcel.UnidadDeclarada, label: 'Unidad Declarada ' },
    { value: CamposProcesoExcel.Cantidad, label: 'Cantidad' },
    { value: CamposProcesoExcel.Total, label: 'Total' },
    { value: CamposProcesoExcel.PaisOrigen, label: 'País Origen' },
    { value: CamposProcesoExcel.PaisProcedencia, label: 'País Procedencia' },
    { value: CamposProcesoExcel.KgNeto, label: 'Kg Neto Total' },
    { value: CamposProcesoExcel.FechaEmbarque, label: 'Fecha Embarque' },
    { value: CamposProcesoExcel.FechaArribo, label: 'Fecha Arribo' },
    { value: CamposProcesoExcel.DescripcionMaria, label: 'Descripción María' },
    { value: CamposProcesoExcel.Marca, label: 'Marca' },
    { value: CamposProcesoExcel.Modelo, label: 'Modelo' },
    { value: CamposProcesoExcel.FechaVencimiento, label: 'Fecha Vencimiento' },
    { value: CamposProcesoExcel.Presentacion, label: 'Presentación' },
    { value: CamposProcesoExcel.KgNetoUnitario, label: 'Kg Unitario' },
    { value: CamposProcesoExcel.EstadoMercaderia, label: 'Estado Mercadería' },
    { value: CamposProcesoExcel.Filler, label: 'Filler' },
    { value: CamposProcesoExcel.Lista, label: 'Lista' },
    { value: CamposProcesoExcel.DJCP, label: 'DJCP' },
    { value: CamposProcesoExcel.PrecioUnitario, label: 'Precio Unitario' },
    { value: CamposProcesoExcel.InsumosAConsumo, label: 'Insumos A Consumo' },
    { value: CamposProcesoExcel.InsumosTemporales, label: 'Insumos Temporales' },
    { value: CamposProcesoExcel.Sufijos, label: 'Sufijos' },
    { value: CamposProcesoExcel.SufijosParcial, label: 'Sufijos Parcial' },
    { value: CamposProcesoExcel.SufijoABAI, label: 'Sufijo AB/AI' },
    { value: CamposProcesoExcel.CantidadEstadistica, label: 'Cantidad Estadistica' },
    { value: CamposProcesoExcel.AjusteIncluir, label: 'Ajuste Incluir' },
    { value: CamposProcesoExcel.AjusteDeducir, label: 'Ajuste Deducir' },
    { value: CamposProcesoExcel.Otros, label: 'Otros' },
    { value: CamposProcesoExcel.SufijoAS, label: 'Sufijo AS' },
    { value: CamposProcesoExcel.SufijoZA, label: 'Sufijo ZA' },
    { value: CamposProcesoExcel.SufijoZB, label: 'Sufijo ZB' },
    { value: CamposProcesoExcel.SufijoZC, label: 'Sufijo ZC' }
];

function ultimaColumnaHoja(hoja: XLSX.Sheet) {
    if (!hoja['!ref']) {
        return 0;
    }
    let rango = XLSX.utils.decode_range(hoja['!ref']);
    let ultimaColumna = 0;
    for (let columna = rango.s.c; columna <= Math.min(rango.s.c + 50, rango.e.c); columna++) {
        if (columna > ultimaColumna) {
            ultimaColumna = columna;
        }
    }
    return ultimaColumna;
}

const FormCol = (props: React.PropsWithChildren<any>) => {
    let { className, children, ...otrosProps } = props;
    className = (className ?? '') + ' col d-flex flex-column justify-content-end';
    return <div className={className} {...otrosProps}>
        {children}
    </div>
}

function TablaExcel(props: { hoja: XLSX.Sheet }) {
    let formikContext = useFormikContext();
    React.useEffect(() => {
        if (props.hoja['!ref']) {
            let ultimaColumna = ultimaColumnaHoja(props.hoja);
            let values = formikContext.values as any;
            if (values.campos.length > ultimaColumna) {
                formikContext.setFieldValue('campos', values.campos.slice(0, ultimaColumna + 1));
            }
        }
        //eslint-disable-next-line
    }, [props.hoja]);
    if (!props.hoja['!ref']) {
        return (<p className="lead">Esta hoja está vacía</p>);
    }
    let rango = XLSX.utils.decode_range(props.hoja['!ref']);
    let columnasHead = [];
    let filasBody = [];
    let ultimaColumna = 0;
    let negrita = true;
    for (let fila = rango.s.r; fila <= Math.min(rango.s.r + 50, rango.e.r); fila++) {
        let columnas = [];
        for (let columna = rango.s.c; columna <= Math.min(rango.s.c + 50, rango.e.c); columna++) {
            if (columna > ultimaColumna) {
                ultimaColumna = columna;
            }
            let celda = props.hoja[XLSX.utils.encode_cell({ c: columna, r: fila })];
            columnas.push(<td className={negrita ? 'font-weight-bold' : ''} key={columna}>{celda?.w || celda?.v}</td>);
        }
        filasBody.push((<tr key={fila}>{columnas}</tr>));
        negrita = false;
    }
    for (let i = 0; i <= ultimaColumna; i++) {
        let idCampo = `campos[${i}]`;
        let values = formikContext.values as any;
        columnasHead.push(<th key={i}><Select name={idCampo} id={idCampo} options={opcionesCampos.sort((a, b) => strCmp(a.label, b.label))}
            onBlur={() => formikContext.setFieldTouched(idCampo)}
            onChange={(option: any) => formikContext.setFieldValue(idCampo, option?.value)}
            value={opcionesCampos.find(c => c.value === values.campos[i]) ?? null}
            isClearable placeholder="Seleccionar..."></Select></th>)
    }

    return (
        <div className="table-responsive" style={{ height: '40vh' }}>
            <Table bordered size="sm" className="tablaExcel">
                <thead>
                    <tr>
                        {columnasHead}
                    </tr>
                </thead>
                <tbody>
                    {filasBody}
                </tbody>
            </Table>
        </div>);
}

function getCamposDeCaratula(camposCaratula: any) {
    let cantidadCampos = Math.max.apply(null, camposCaratula.map((c: any) => c.Columna));
    let campos = Array(cantidadCampos).fill(undefined);
    for (let campo of camposCaratula) {
        campos[campo.Columna] = CamposProcesoExcel[campo.Campo];
    }
    return campos;
}
function getCamposDeModelo(camposModelo: any) {
    let cantidadCampos = Math.max.apply(null, camposModelo.map((c: any) => c.Columna));
    let campos = Array(cantidadCampos).fill(undefined);
    for (let campo of camposModelo) {
        campos[campo.Columna] = campo.Campo;
    }
    return campos;
}
type SeleccionModeloRef = { mostrar: () => Promise<string> };
const SeleccionModelo = React.forwardRef((props: any, ref: any) => {
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { mostrar: true, cargando: true, modelos: [], resolve: accion.resolve, reject: accion.reject, mensajeDialogo: '' };
        } else if (accion.tipo === 'esconder') {
            return { mostrar: false, cargando: false, modelos: [], mensajeDialogo: '' };
        } else if (accion.tipo === 'setModelos') {
            return { ...estado, cargando: false, modelos: accion.valor };
        } else if (accion.tipo === 'setMensajeDialogo') {
            return { ...estado, mensajeDialogo: accion.valor };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, cargando: accion.valor };
        }
        return { ...estado };
    }, { mostrar: false, cargando: false, modelos: [], mensajeDialogo: '' });
    React.useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getModelosProcesoExcel();
                updateEstado({ tipo: 'setModelos', valor: respuesta.map((nombre: string) => ({ Nombre: nombre })) });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar modelos', error);
                    mostrarError('Error al cargar modelos');
                    if (estado.reject) {
                        estado.reject();
                    }
                    updateEstado({ tipo: 'esconder' });
                }
            }
        }
        if (estado.mostrar) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estado.mostrar]);
    React.useImperativeHandle(ref, () => ({
        mostrar: () => {
            return new Promise<string>((resolve, reject) => {
                updateEstado({ tipo: 'mostrar', resolve: resolve, reject: reject });
            });
        }
    }));
    async function eventoEliminar(item: any) {
        updateEstado({ tipo: 'setMensajeDialogo', valor: `¿Está seguro que desea eliminar el modelo ${item.Nombre}?` });
        if (await dialogoRef.current!.mostrar()) {
            try {
                updateEstado({ tipo: 'setCargando', valor: true });
                await api.deleteModeloProcesoExcel(item.Nombre);
                updateEstado({ tipo: 'setModelos', valor: estado.modelos.filter((modelo: any) => modelo.Nombre !== item.Nombre) });
                updateEstado({ tipo: 'setCargando', valor: false });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar modelo', error);
                    mostrarError('Error al eliminar modelo');
                    updateEstado({ tipo: 'setCargando', valor: false });
                }
            }
        }
    }
    const cerrar = () => {
        api.cancelCurrentTokens();
        if (estado.reject) {
            estado.reject();
        }
        updateEstado({ tipo: 'esconder' });
    }



    return <>
        <MyModal show={estado.mostrar} onHide={cerrar}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Seleccionar Modelo
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={estado.cargando}>
                        <p>Haga click en un modelo para seleccionarlo</p>
                        <GrillaSync campos={[{ titulo: 'Nombre Modelo', propiedad: 'Nombre', clave: true }]} datos={estado.modelos}
                            eventoDetalle={(item: any) => {
                                estado.resolve(item.Nombre);
                                updateEstado({ tipo: 'esconder' });
                            }} eventoEliminar={eventoEliminar} />
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cerrar}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje={estado.mensajeDialogo} resolverPromesaAlCancelar textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </>
});

export function ImportExcel(props: {
    interno?: string,
    onImport: (campos: Array<CamposProcesoExcel>,
        catalogo: string, caratula: any, articulos: any[], valoresPaisesSinMapeo: string[],
        valoresUnidadesSinMapeo: string[], valoresEstadosMercSinMapeo: string[], configuracion: any) => void,
}) {
    let [notFound, updateNotFound] = React.useState(false);
    let internoProps = React.useRef(props.interno);
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let altaBajaMapeosPaisRef = React.useRef<AltaBajaMapeosPaisRef>(null);
    let altaBajaMapeosUnidadRef = React.useRef<AltaBajaMapeosUnidadRef>(null);
    let altaBajaMapeosEstadoMercRef = React.useRef<AltaBajaMapeosEstadoMercRef>(null);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikCrearCatalogoRef = React.useRef<FormikProps<any>>(null);
    let formikCrearModeloRef = React.useRef<FormikProps<any>>(null);
    let seleccionModeloRef = React.useRef<SeleccionModeloRef>(null);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cargarExcel') {
            return { ...estado, cargando: true, mensajeCarga: "Cargando Excel..." };
        } else if (accion.tipo === 'errorFaltaArchivo') {
            return { ...estado, cargando: false, errorArchivo: 'Debe seleccionar un archivo Excel' };
        } else if (accion.tipo === 'errorLectura') {
            return { ...estado, cargando: false, errorArchivo: 'Hubo un error al leer el archivo' };
        } else if (accion.tipo === 'cancelarCarga') {
            return { ...estado, cargando: false, errorArchivo: '' };
        } else if (accion.tipo === 'excelCargado') {
            return {
                ...estado, cargando: false, workbook: accion.workbook,
                hojaActual: accion.workbook.SheetNames[0]
            };
        } else if (accion.tipo === 'cambiarHoja') {
            return { ...estado, hojaActual: accion.hoja };
        } else if (accion.tipo === 'primeraCarga') {
            return {
                cargando: false, mensajeCarga: "", catalogos: accion.catalogos, paises: accion.paises, unidades: accion.unidades,
                estadosMerc: accion.estadosMerc, mapeoPaises: accion.mapeoPaises, mapeoUnidades: accion.mapeoUnidades,
                mapeoEstadosMerc: accion.mapeoEstadosMerc,
                optionsCatalogos: accion.catalogos.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion })).sort((a: any, b: any) => strCmp(a.value, b.value)),
                optionsUnidades: accion.unidades.map((unidad: any) => ({ value: unidad.Codigo, label: unidad.Descripcion })),
                habilitarComboCatalogos: accion.caratula ? !isNullOrWhiteSpace(accion.caratula.Catalogo) : accion.configuracion.CruzarConCatalogoEnProcesoExcel ?? false,
                caratula: accion.caratula, mostrarModalCrearCatalogo: false,
                catalogoInsertado: '', configuracion: accion.configuracion, mostrarModalCrearModelo: false,
                mensajeExito: null
            };
        } else if (accion.tipo === 'setHabilitarComboCatalogos') {
            return { ...estado, habilitarComboCatalogos: accion.valor };
        } else if (accion.tipo === 'setMensajeDialogo') {
            return { ...estado, mensajeDialogo: accion.valor };
        } else if (accion.tipo === 'setMostrarModalCrearCatalogo') {
            return { ...estado, mostrarModalCrearCatalogo: accion.valor };
        } else if (accion.tipo === 'setMostrarModalCrearModelo') {
            return { ...estado, mostrarModalCrearModelo: accion.valor };
        } else if (accion.tipo === 'setMensajeExito') {
            return { ...estado, mensajeExito: accion.valor };
        } else if (accion.tipo === 'insertCatalogo') {
            let catalogos = Array.from(estado.catalogos);
            let optionsCatalogos = Array.from(estado.optionsCatalogos);
            catalogos.push(accion.catalogo);
            optionsCatalogos.push({ value: accion.catalogo.Codigo, label: accion.catalogo.Descripcion });
            optionsCatalogos = optionsCatalogos.sort((a: any, b: any) => strCmp(a.Codigo, b.Codigo));
            return { ...estado, catalogos: catalogos, optionsCatalogos: optionsCatalogos, catalogoInsertado: accion.catalogo.Codigo };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, cargando: accion.valor };
        }
        return { ...estado };
    }, { cargando: true, mensajeCarga: "Cargando catalogos...", catalogoInsertado: '' });
    let updateEstadoSiNoCancelado = (accion: any) => {
        if (!api.isUnmounted()) {
            updateEstado(accion);
        }
    };


    React.useEffect(() => {
        async function cargar() {
            try {
                let catalogos = await api.getCatalogos();
                let paises = await api.getPaises();
                let unidades = await api.getUnidades();
                let estadosMerc = await api.getEstadosMercaderia();
                let mapeoPaises = await api.busquedaMapeosPais();
                let mapeoUnidades = await api.busquedaMapeosUnidad();
                let mapeoEstadosMerc = await api.busquedaMapeosEstadoMercaderia();
                let caratula = null;
                if (internoProps.current) {
                    let respuesta = await api.getCaratulaProcesoExcel(internoProps.current);
                    if (!respuesta) {
                        updateNotFound(true);
                        return;
                    }
                    caratula = respuesta.Caratula;
                }
                let configuracion = await api.getConfiguracionSintia();
                updateEstado({
                    tipo: 'primeraCarga', catalogos: catalogos, paises: paises,
                    unidades: unidades, estadosMerc: estadosMerc, mapeoPaises: mapeoPaises,
                    mapeoUnidades: mapeoUnidades, mapeoEstadosMerc: mapeoEstadosMerc,
                    caratula: caratula, configuracion: configuracion
                });

            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar datos', error);
                    mostrarError('Error al cargar datos');
                }
            }
        }
        bsCustomFileInput.init();
        cargar();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        if (!isNullOrWhiteSpace(estado.catalogoInsertado)) {
            formikRef.current?.setFieldValue('catalogo', estado.catalogoInsertado);
        }
    }, [estado.catalogoInsertado]);
    function excelSeleccionado(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files || event.target.files.length === 0) {
            updateEstado({ tipo: 'errorFaltaArchivo' });
            return;
        }
        const reader = new FileReader();
        reader.onload = async loadEvent => {
            if (!loadEvent.target) {
                console.error('Loadevent.target es null');
                updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
                return;
            }
            if (!loadEvent.target.result) {
                console.error('Loadevent.target.result es null');
                updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
                return;
            }
            const leerExcel = Comlink.wrap<(buffer: ArrayBuffer) => any>(new Worker());
            try {
                let workbook = await leerExcel(Comlink.transfer(loadEvent.target.result as ArrayBuffer,
                    [loadEvent.target.result as ArrayBuffer]));
                updateEstadoSiNoCancelado({ tipo: 'excelCargado', workbook: workbook });
            } catch (error) {
                console.error('Error al leer Excel', error);
                updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
            }
        }
        reader.onerror = errorEvent => {
            console.error('Error al leer el archivo excel', errorEvent.target?.error);
            updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
        }
        updateEstado({ tipo: 'cargarExcel' });
        reader.readAsArrayBuffer(event.target.files[0]);
    }
    function hojaSeleccionada(nombreHoja: string | null, event: React.SyntheticEvent<unknown>) {
        if (nombreHoja) {
            updateEstado({ tipo: 'cambiarHoja', hoja: nombreHoja });
        }
    }
    let validationSchema = null;
    if (estado.workbook) {
        let hoja = estado.workbook.Sheets[estado.hojaActual];
        let ultimaFila = Number.POSITIVE_INFINITY;
        if (hoja['!ref']) {
            let rango = XLSX.utils.decode_range(hoja['!ref']);
            ultimaFila = rango.e.r;
        }
        validationSchema = Yup.object({
            campos: Yup.array().required('Debe seleccionar los campos que corresponden a cada columna del Excel')
                .test('codigo-articulo', 'Debe seleccionar la columna con el codigo de articulo',
                    (valores: any) => valores?.includes(CamposProcesoExcel.CodigoArticulo))
                .test('cantidad', 'Debe seleccionar la columna con la cantidad',
                    (valores: any) => valores?.includes(CamposProcesoExcel.Cantidad))
                .test('total', 'Debe seleccionar la columna Precio Unitario o total Fob',
                    (valores: any) => valores?.includes(CamposProcesoExcel.PrecioUnitario) || valores?.includes(CamposProcesoExcel.Total))
                .test('sufijos', 'El campo Sufijos no puede seleccionarse con ninguno de los siguientes campos: Marca,Modelo,Presentación,Sufijos Parcial,Sufijo AB/AI',
                    (valores: any) => {
                        return !(valores?.includes(CamposProcesoExcel.Sufijos) && (valores?.includes(CamposProcesoExcel.Marca) ||
                            valores?.includes(CamposProcesoExcel.Modelo) || valores?.includes(CamposProcesoExcel.Presentacion) ||
                            valores?.includes(CamposProcesoExcel.SufijosParcial) || valores?.includes(CamposProcesoExcel.SufijoABAI)));
                    }).test('ncm', 'Si selecciona el campo Sufijos completo debe seleccionar también el campo NCM',
                        (valores: any) => {
                            if (valores?.includes(CamposProcesoExcel.Sufijos)) {
                                return valores?.includes(CamposProcesoExcel.Ncm);
                            }
                            return true;
                        }).test('sin-duplicados', 'No puede seleccionar el mismo campo en dos columnas distintas',
                            (valores: any) => {
                                if (valores === null || valores === undefined) {
                                    return true;
                                }
                                let lista: any[] = [];
                                let valido = true;
                                valores.forEach((valor: any) => {
                                    if (valor !== null && valor !== undefined) {
                                        if (lista.includes(valor)) {
                                            valido = false;
                                        } else {
                                            lista.push(valor);
                                        }
                                    }
                                });
                                return valido;
                            }),
            catalogo: Yup.string().when('cruzarConCatalogo', {
                is: true,
                then: Yup.string().nullable().required('Debe seleccionar el catalogo'),
                otherwise: Yup.string().nullable()
            }),
            cruzarConCatalogo: Yup.boolean().nullable(),
            primeraFila: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar la primera fila')
                .integer('La primera fila debe ser un número entero')
                .min(1, 'La primera fila debe ser mayor o igual a 1')
                .max(ultimaFila, 'La primera fila debe ser menor o igual a ' + ultimaFila),
            ultimaFila: Yup.number().nullable().typeError('Debe ingresar un número')
                .integer('Debe ser un número entero')
                .moreThan(Yup.ref('primeraFila'), 'La ultima fila debe ser mayor a la primera fila'),
            marca: Yup.string().nullable(),
            forzarMarca: Yup.boolean().nullable(),
            presentacion: Yup.string().nullable(),
            forzarPresentacion: Yup.boolean().nullable(),
            unidadDeclarada: Yup.string().nullable(),
            darFormatoNcm: Yup.boolean().nullable(),
            eliminarGuionesNroArticulo: Yup.boolean().nullable(),
            interno: Yup.string().nullable().required('Debe ingresar el interno')
        });
    };
    function importarArticulos(interno: string, catalogo: string, primeraFila: number,
        ultimaFila: number | undefined, unidadDeclarada: string | undefined, marca: string, forzarMarca: boolean,
        presentacion: string, forzarPresentacion: boolean, formatoNcm: boolean, borrarGuiones: boolean,
        campos: Array<CamposProcesoExcel>) {
        let caratula = {
            Carpeta: interno,
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId:  (storage?.getItem('empresaActual') || userInfo.empresaActual),
            CreadoPor: userInfo.claims.nameid,
            CreadoEl: DateTime.local().toISO(),
            ModificadoPor: userInfo.claims.nameid,
            ModificadoEl: DateTime.local().toISO(),
            Campos: campos.map((valor, indice) => {
                if (valor !== null && valor !== undefined) {
                    return { Campo: CamposProcesoExcel[valor], Columna: indice };
                }
                return null;
            }).filter(valor => valor !== null && valor !== undefined),
            Catalogo: catalogo,
            DarFormatoNcm: formatoNcm,
            EliminarGuionesNroArticulo: borrarGuiones,
            Marca: marca,
            ForzarMarca: forzarMarca,
            Presentacion: presentacion,
            ForzarPresentacion: forzarPresentacion,
            UnidadPorDefecto: unidadDeclarada,
            PrimeraFila: primeraFila,
            UltimaFila: ultimaFila
        };
        let articulos: any[] = [];
        let valoresPaisesSinMapeo: string[] = [];
        let valoresUnidadesSinMapeo: string[] = [];
        let valoresEstadosMercSinMapeo: string[] = [];
        let hoja = estado.workbook.Sheets[estado.hojaActual] as XLSX.Sheet;
        if (!hoja['!ref']) {
            throw new ErrorProcesoExcel('La hoja está vacía. Seleccione otra hoja.');
        }
        let rango = XLSX.utils.decode_range(hoja['!ref']);
        ultimaFila = ultimaFila ?? rango.e.r;
        if (ultimaFila > rango.e.r) {
            ultimaFila = rango.e.r;
        }
        for (let i = primeraFila; i <= ultimaFila; i++) {
            //saltear filas vacias
            let filaVacia = true;
            for (let j = 0; j < campos.length; j++) {
                let celda = hoja[XLSX.utils.encode_cell({ c: j, r: i })];
                if (celda !== undefined && (celda.t !== 'z' || (celda.t === 's' && isNullOrWhiteSpace(celda.v)))) {
                    filaVacia = false;
                }
            }
            if (filaVacia) {
                continue;
            }
            let articulo = {
                Carpeta: interno,
                LineaNro: i,
                CatalogoId: catalogo,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId:  (storage?.getItem('empresaActual') || userInfo.empresaActual),
                CreadoPor: userInfo.claims.nameid,
                CreadoEl: DateTime.local().toISO(),
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO()
            } as any;
            campos.forEach((valor: CamposProcesoExcel, indice: number) => {
                let celda = hoja[XLSX.utils.encode_cell({ c: indice, r: i })];
                switch (valor) {
                    case CamposProcesoExcel.Cantidad:
                        if (celda?.t === 'n') {
                            if (celda.v <= 0) {
                                throw new ErrorProcesoExcel('La cantidad de un articulo debe ser mayor a cero');
                            }
                            //articulo.CantidadDeclarada = celda.v;
                            articulo.CantidadDeclarada = toFixedDecimal(celda.v, 2);


                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.CodigoArticulo:
                        let codigo = celda?.w?.trim();
                        if (borrarGuiones) {
                            codigo = codigo?.replace(/[-－]+/g, '');
                        } else {
                            codigo = codigo?.replace(/－+/g, '-');
                        }
                        articulo.Codigo = codigo;
                        break;
                    case CamposProcesoExcel.Descripcion:
                        articulo.Descripcion = celda?.w?.trim();
                        break;
                    case CamposProcesoExcel.DescripcionMaria:
                        articulo.DescripcionMaria = celda?.w?.trim();
                        break;
                    case CamposProcesoExcel.FechaArribo:
                        debugger;
                        break;
                    case CamposProcesoExcel.FechaEmbarque:
                        debugger;
                        break;
                    case CamposProcesoExcel.KgNeto:
                        if (celda?.t === 'n') {
                            //articulo.KgNeto = celda?.v;
                            articulo.KgNeto = toFixedDecimal(celda?.v, 2);
                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.Marca:
                        articulo.Marca = reemplazarCaracteresInvalidosSufijos(celda?.w?.trim());
                        break;
                    case CamposProcesoExcel.Modelo:
                        articulo.Modelo = reemplazarCaracteresInvalidosSufijos(celda?.w?.trim());
                        break;
                    case CamposProcesoExcel.Presentacion:
                        articulo.Presentacion = reemplazarCaracteresInvalidosSufijos(celda?.w?.trim());
                        break;
                    case CamposProcesoExcel.FechaVencimiento:
                        articulo.FechaVencimiento = celda?.w?.trim();
                        break;
                    case CamposProcesoExcel.Ncm:
                        articulo.Ncm = celda?.w?.replace(/\s+/g, '')?.toUpperCase();
                        if (formatoNcm && articulo.Ncm?.length === 12) {
                            let ncm = articulo.Ncm;
                            ncm = spliceString(ncm, 4, '.');
                            ncm = spliceString(ncm, 7, '.');
                            articulo.Ncm = spliceString(ncm, 10, '.');
                        }
                        break;
                    case CamposProcesoExcel.NumeroFactura:
                        articulo.FacturaNro = celda?.w?.trim();
                        break;
                    case CamposProcesoExcel.Total:
                        if (celda?.t === 'n') {
                            if (celda.v <= 0) {
                                throw new ErrorProcesoExcel('El total FOB debe ser mayor a cero');
                            }
                            //articulo.FobTotal = celda.v;
                            articulo.FobTotal = toFixedDecimal(celda.v, 2);

                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.PaisOrigen:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            let valor = celda.w.trim();
                            let pais = estado.paises.find((pais: any) => pais.Codigo === valor);
                            if (pais) {
                                articulo.CodigoPaisOrigen = valor;
                            } else {
                                if (!valoresPaisesSinMapeo.includes(valor)) {
                                    let mapeo = estado.mapeoPaises.find((mapeo: any) => mapeo.Valor === valor);
                                    if (mapeo) {
                                        articulo.CodigoPaisOrigen = mapeo.CodigoPais;
                                    } else {
                                        valoresPaisesSinMapeo.push(valor);
                                    }
                                }
                                articulo.ValorPaisOrigenExcel = valor;
                            }
                        }
                        break;
                    case CamposProcesoExcel.PaisProcedencia:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            let valor = celda.w.trim();
                            let pais = estado.paises.find((pais: any) => pais.Codigo === valor);
                            if (pais) {
                                articulo.CodigoPaisProcedencia = valor;
                            } else {
                                if (!valoresPaisesSinMapeo.includes(valor)) {
                                    let mapeo = estado.mapeoPaises.find((mapeo: any) => mapeo.Valor === valor);
                                    if (mapeo) {
                                        articulo.CodigoPaisProcedencia = mapeo.CodigoPais;
                                    } else {
                                        valoresPaisesSinMapeo.push(valor);
                                    }
                                }
                                articulo.ValorPaisProcedenciaExcel = valor;
                            }
                        }
                        break;
                    case CamposProcesoExcel.KgNetoUnitario:
                        if (celda?.t === 'n') {
                            articulo.KgNetoUnitario = celda?.v;
                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.UnidadDeclarada:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            let valor = celda.w.trim();
                            let unidad = estado.unidades.find((unidad: any) => unidad.Codigo === valor);
                            if (unidad) {
                                articulo.CodigoUnidadDeclarada = valor;
                            } else {
                                if (!valoresUnidadesSinMapeo.includes(valor)) {
                                    let mapeo = estado.mapeoUnidades.find((mapeo: any) => mapeo.Valor === valor);
                                    if (mapeo) {
                                        articulo.CodigoUnidadDeclarada = valor.CodigoUnidad;
                                    } else {
                                        valoresUnidadesSinMapeo.push(valor);
                                    }
                                }
                                articulo.ValorUnidadDeclaradaExcel = valor;
                            }
                        }
                        break;
                    case CamposProcesoExcel.EstadoMercaderia:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            let valor = celda.w.trim();

                            let estadoMerc = estado.estadosMerc.find((estadoMerc: any) => estadoMerc.Codigo === valor);

                            if (estadoMerc) {
                                articulo.EstadoMercaderia = valor;
                            } else {
                                if (!valoresEstadosMercSinMapeo.includes(valor)) {
                                    let mapeo = estado.mapeoEstadosMerc.find((mapeo: any) => mapeo.Valor === valor);
                                    if (mapeo) {
                                        articulo.EstadoMercaderia = mapeo.CodigoEstadoMercaderia;
                                    } else {
                                        valoresEstadosMercSinMapeo.push(valor);
                                    }
                                }
                                articulo.ValorEstadoMercaderiaExcel = valor;
                            }
                        }
                        break;
                    case CamposProcesoExcel.Filler:
                        articulo.Filler = celda?.w?.trim();
                        break;
                    case CamposProcesoExcel.Lista:
                        articulo.Lista = celda?.w?.trim().toUpperCase();
                        break;
                    case CamposProcesoExcel.DJCP:
                        articulo.DJCP = celda?.w?.trim();
                        break;
                    case CamposProcesoExcel.PrecioUnitario:
                        if (celda?.t === 'n') {
                            //articulo.PrecioUnitario = celda.v;
                            articulo.PrecioUnitario = toFixedDecimal(celda.v, 5);
                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.InsumosAConsumo:
                        if (celda?.t === 'n') {
                            articulo.InsumosAConsumo = celda.v;
                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.InsumosTemporales:
                        if (celda?.t === 'n') {
                            articulo.InsumosTemporales = celda.v;
                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.AjusteIncluir:
                        if (celda?.t === 'n') {
                            //articulo.AjusteIncluir = celda.v;
                            articulo.AjusteIncluir = toFixedDecimal(celda.v, 2);
                        }
                        break;
                    case CamposProcesoExcel.AjusteDeducir:
                        if (celda?.t === 'n') {
                            //articulo.AjusteDeducir = celda.v;
                            articulo.AjusteDeducir = toFixedDecimal(celda.v, 2);
                        }
                        break;
                    case CamposProcesoExcel.Sufijos:
                        articulo.Sufijos = celda?.w?.trim();
                        if (!isNullOrWhiteSpace(articulo.Sufijos) && !articulo.Sufijos.endsWith('-')) {
                            articulo.Sufijos += '-';
                        }
                        articulo.SufijosCompletoSeleccionado = true;
                        break;
                    case CamposProcesoExcel.SufijosParcial:
                        articulo.SufijosParcial = celda?.w?.trim();
                        break;
                    case CamposProcesoExcel.SufijoABAI:
                        articulo.SufijoABAI = reemplazarCaracteresInvalidosSufijos(celda?.w?.trim());
                        break;
                    case CamposProcesoExcel.SufijoAS:
                        articulo.SufijoAS = reemplazarCaracteresInvalidosSufijos(celda?.w?.trim());
                        break;
                    case CamposProcesoExcel.SufijoZA:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            if (celda?.t === 'n') {
                                articulo.SufijoZA = celda?.v;
                            }
                        }
                        break;
                    case CamposProcesoExcel.SufijoZB:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            if (celda?.t === 'n') {
                                articulo.SufijoZB = celda?.v;
                            }
                        }
                        break;
                    case CamposProcesoExcel.SufijoZC:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            if (celda?.t === 'n') {
                                articulo.SufijoZC = celda?.v;
                            }
                        }
                        break;
                    case CamposProcesoExcel.CantidadEstadistica:
                        if (celda?.t === 'n') {
                            //articulo.CantidadEstadistica = celda.v;
                            articulo.CantidadEstadistica = toFixedDecimal(celda.v, 2);
                        } else {
                            throw new ErrorProcesoExcel('Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i }));
                        }
                        break;
                    case CamposProcesoExcel.Otros:
                        articulo.Otros = celda?.w?.trim();
                        break;
                }
            });
            if (campos.includes(CamposProcesoExcel.PrecioUnitario) && !campos.includes(CamposProcesoExcel.Total)) {
                articulo.FobTotal = toFixedDecimal(new Decimal(articulo.CantidadDeclarada).mul(articulo.PrecioUnitario), 2);
            }
            if (isNullOrWhiteSpace(articulo.CodigoUnidadDeclarada) && !isNullOrWhiteSpace(unidadDeclarada)) {
                articulo.CodigoUnidadDeclarada = unidadDeclarada;
            }
            if (!campos.includes(CamposProcesoExcel.KgNeto)) {
                if (campos.includes(CamposProcesoExcel.KgNetoUnitario)) {
                    articulo.KgNeto = toFixedDecimal(new Decimal(articulo.CantidadDeclarada).mul(articulo.KgNetoUnitario), 2);
                } else if (!isNullOrWhiteSpace(articulo.CodigoUnidadDeclarada) && ConversorUnidadMedida.sePuedeConvertir('01', articulo.CodigoUnidadDeclarada)) {
                    articulo.KgNeto = toFixedDecimal(new Decimal(articulo.CantidadDeclarada).mul(ConversorUnidadMedida.obtenerEscala('01', articulo.CodigoUnidadDeclarada)), 2);
                }
            }
            if (isNullOrWhiteSpace(articulo.Marca) || forzarMarca) {
                articulo.Marca = marca;
            }
            if (isNullOrWhiteSpace(articulo.Presentacion) || forzarPresentacion) {
                articulo.Presentacion = presentacion;
            }
            articulos.push(articulo);
        }
        return {
            caratula: caratula, articulos: articulos, valoresPaisesSinMapeo: valoresPaisesSinMapeo,
            valoresUnidadesSinMapeo: valoresUnidadesSinMapeo,
            valoresEstadosMercSinMapeo: valoresEstadosMercSinMapeo
        };
    }
    async function submit(values: any) {
        try {
            try {
                if (!estado.caratula) {
                    let caratula = await api.getCaratulaProcesoExcel(values.interno);
                    if (caratula) {
                        updateEstado({ tipo: 'setMensajeDialogo', valor: `El interno ${values.interno} ya fue cargado. ¿Desea sobreescribirlo?` });
                        if (!await dialogoRef.current!.mostrar()) {
                            updateEstado({ tipo: 'cancelarCarga' });
                            return;
                        }
                    }
                } else {
                    updateEstado({ tipo: 'setMensajeDialogo', valor: `Al volver a cargar el Excel va a perder los cambios hechos en el interno ${values.interno}. ¿Desea continuar?` });
                    if (!await dialogoRef.current!.mostrar()) {
                        updateEstado({ tipo: 'cancelarCarga' });
                        return;
                    }
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al verificar si interno fue cargado', error);
                    mostrarError('Error al verificar si interno fue cargado');
                    updateEstado({ tipo: 'cancelarCarga' });
                    return;
                }
            }
            let resultado = importarArticulos(values.interno, values.cruzarConCatalogo ? values.catalogo : undefined,
                values.primeraFila, values.ultimaFila, values.unidadDeclarada, values.marca, values.forzarMarca, values.presentacion,
                values.forzarPresentacion, values.darFormatoNcm ?? false, values.eliminarGuionesNroArticulo ?? false, values.campos);
            if (!resultado.articulos) {
                mostrarError('El excel no tiene ningún artículo');
            } else {
                props.onImport(values.campos, values.catalogo, resultado.caratula, resultado.articulos, resultado.valoresPaisesSinMapeo, resultado.valoresUnidadesSinMapeo, resultado.valoresEstadosMercSinMapeo, values.cruzarConCatalogo);
            }
        } catch (error) {
            if (error instanceof ErrorProcesoExcel) {
                mostrarError(error.message);
            } else {
                console.error('Error al importar articulos del excel', error);
                mostrarError('Error al importar articulos del excel');
                updateEstado({ tipo: 'cancelarCarga' });
            }
        }
    }
    async function crearCatalogo(values: { Codigo: string, Descripcion: string, SufijoAA: string }, helpers: FormikHelpers<any>) {
        try {
            if (estado.catalogos.map((c: any) => c.Codigo).includes(values.Codigo)) {
                helpers.setFieldError('Codigo', `El catalogo ${values.Codigo} ya existe`);
                return;
            }
            let puedeModificar = await api.obtenerLock(TipoLock.Catalogo, values.Codigo);
            if (puedeModificar) {
                let { exito, error } = await api.insertCatalogo(values);
                await api.eliminarLock(TipoLock.Catalogo, values.Codigo);
                if (exito) {
                    updateEstado({ tipo: 'insertCatalogo', catalogo: values });
                    updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: false });
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`El catalogo ${values.Codigo} ya existe`);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear catalogo', error);
                mostrarError('Error al crear catalogo');
            }
        }
    }
    async function crearModelo(values: { Nombre: string }) {
        try {
            let respuesta = await api.getModelosProcesoExcel();
            if (respuesta.includes(values.Nombre)) {
                updateEstado({ tipo: 'setMensajeDialogo', valor: `El modelo ${values.Nombre} ya existe. ¿Desea sobreescribirlo?` });
                if (!await dialogoRef.current!.mostrar()) {
                    return;
                }
            }
            await api.insertModeloProcesoExcel({
                Nombre: values.Nombre,
                NroFilaEncabezado: formikRef.current!.values.primeraFila,
                Campos: formikRef.current!.values.campos.map((valor: any, indice: number) => {
                    if (valor !== null && valor !== undefined) {
                        return { Campo: valor, Columna: indice };
                    }
                    return null;
                }).filter((valor: any) => valor !== null && valor !== undefined)
            });
            updateEstado({ tipo: 'setMostrarModalCrearModelo', valor: false });
            updateEstado({ tipo: 'setMensajeExito', valor: 'Se ha creado el modelo exitosamente' });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Hubo un error al crear el modelo', error);
                mostrarError('Hubo un error al crear el modelo');
            }
        }
    }
    function onClickSeleccionarModelo() {
        seleccionModeloRef.current!.mostrar().then(async (nombreModelo: string) => {
            try {
                updateEstado({ tipo: 'setCargando', valor: true });
                let respuesta = await api.getModeloProcesoExcel(nombreModelo);
                if (respuesta) {
                    let hoja = estado.workbook.Sheets[estado.hojaActual] as XLSX.Sheet;
                    let cantidadColumnasEnModelo = Math.max.apply(null, respuesta.Campos.map((c: any) => c.Columna));
                    if (cantidadColumnasEnModelo > ultimaColumnaHoja(hoja)) {
                        mostrarError('No se puede cargar el modelo porque el archivo Excel no tiene suficientes columnas');
                    } else {
                        formikRef.current?.setFieldValue('primeraFila', respuesta.NroFilaEncabezado);
                        formikRef.current?.setFieldValue('campos', getCamposDeModelo(respuesta.Campos));
                    }
                } else {
                    mostrarError(`Otro usuario ya ha borrado el modelo ${nombreModelo}. Seleccione otro modelo`);
                }
                updateEstado({ tipo: 'setCargando', valor: false });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar modelo', error);
                    mostrarError('Hubo un error al cargar el modelo');
                    updateEstado({ tipo: 'setCargando', valor: false });
                }
            }
        }).catch(() => { });
    }
    let esOperador = isInRole(userInfo, 'Operador');


    return notFound ? <NotFoundComponent></NotFoundComponent> : (<>
        <BlockUi keepInView blocking={estado.cargando} message={estado.mensajeCarga}>
            <div style={{ minHeight: '5em' }}>
                <div className="mb-2">
                    <Button variant="secondary" className="mr-2" onClick={() => altaBajaMapeosPaisRef.current!.mostrar()}>Asignaciones de países</Button>
                    <Button variant="secondary" className="mr-2" onClick={() => altaBajaMapeosUnidadRef.current!.mostrar()}>Asignaciones de unidades</Button>
                    <Button variant="secondary" onClick={() => altaBajaMapeosEstadoMercRef.current!.mostrar()}>Asignaciones de estados mercadería</Button>
                </div>
                <Form>
                    <Form.File custom>
                        <Form.File.Input accept=".xls,.xlsx" isInvalid={estado.errorArchivo} onChange={excelSeleccionado}></Form.File.Input>
                        <Form.File.Label data-browse="Seleccionar">Archivo Excel</Form.File.Label>
                        <Form.Control.Feedback type="invalid">{estado.errorArchivo}</Form.Control.Feedback>
                    </Form.File>
                </Form>
                {estado.workbook && (
                    <Formik innerRef={formikRef} initialValues={{
                        campos: estado.caratula ? getCamposDeCaratula(estado.caratula.Campos) : [],
                        interno: estado.caratula?.Carpeta ?? '',
                        ////                        interno: ((usarAutonumerico) && isNullOrWhiteSpace(estado.caratula?.Carpeta)) ? 'AUTONUMERICO' : estado.caratula?.Carpeta ?? '',
                        catalogo: isNullOrWhiteSpace(estado.caratula?.Catalogo) ? '' : estado.caratula.Catalogo,
                        primeraFila: estado.caratula?.PrimeraFila ?? 1,
                        ultimaFila: estado.caratula?.UltimaFila,
                        cruzarConCatalogo: estado.caratula ? !isNullOrWhiteSpace(estado.caratula.Catalogo) : estado.configuracion.CruzarConCatalogoEnProcesoExcel ?? false,
                        marca: estado.caratula?.Marca,
                        forzarMarca: estado.caratula?.ForzarMarca ?? false,
                        presentacion: estado.caratula?.Presentacion,
                        forzarPresentacion: estado.caratula?.ForzarPresentacion ?? false,
                        unidadDeclarada: estado.caratula?.UnidadPorDefecto ?? '',
                        darFormatoNcm: estado.caratula?.DarFormatoNCM ?? false,
                        eliminarGuionesNroArticulo: estado.caratula?.EliminarGuionesNroArticulo ?? false
                    }} validationSchema={validationSchema} onSubmit={submit}>
                        <MyForm className="mt-2">
                            <Tabs id="tabsCrearCaratulaProcesoExcel">
                                <Tab eventKey="principal" title="Abrir Excel">
                                    <Form.Row>
                                        <Form.Group as={FormCol}>
                                            <MyFormControl type="text" name="interno" label="Interno" readOnly={((estado.caratula !== null && estado.caratula !== undefined))}></MyFormControl>
                                        </Form.Group>
                                        <Col>
                                            <Form.Row>
                                                <Form.Group as={FormCol}>
                                                    <MyFormCheck name="cruzarConCatalogo" label="Cruzar con catalogo" onCheckedChange={checked => updateEstado({ tipo: 'setHabilitarComboCatalogos', valor: checked })}></MyFormCheck>
                                                    <MySelect name="catalogo" label="Catalogo"
                                                        options={estado.optionsCatalogos ?? []} isDisabled={!estado.habilitarComboCatalogos}
                                                        getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></MySelect>
                                                </Form.Group>
                                                <Form.Group as={FormCol}>
                                                    <Button disabled={esOperador} className="w-100" onClick={() => updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: true })}>Crear catalogo</Button>
                                                </Form.Group>
                                            </Form.Row>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={FormCol}>
                                            <MySelect name="unidadDeclarada" label="Unidad Declarada Por Defecto"
                                                options={estado.optionsUnidades ?? []}
                                                getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={FormCol}>
                                            <MyFormCheck name="darFormatoNcm" label="Dar formato a NCM"></MyFormCheck>
                                            <MyFormCheck name="eliminarGuionesNroArticulo" label="Eliminar guiones de número de artículo" className="mt-2"></MyFormCheck>
                                        </Form.Group>
                                        <Form.Group as={FormCol}>
                                            <MyFormControl type="text" name="marca" label="Marca"></MyFormControl>
                                            <MyFormCheck name="forzarMarca" label="Forzar" className="mt-2"></MyFormCheck>
                                        </Form.Group>
                                        <Form.Group as={FormCol}>
                                            <MyFormControl type="text" name="presentacion" label="Presentación"></MyFormControl>
                                            <MyFormCheck name="forzarPresentacion" label="Forzar" className="mt-2"></MyFormCheck>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="opciones" title="Opciones">
                                    <Form.Row>
                                        <Form.Group as={FormCol}>
                                            <MyFormControl type="number" name="primeraFila" label="Fila del encabezado"></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={FormCol}>
                                            <MyFormControl type="number" name="ultimaFila" label="Última fila"></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                            </Tabs>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Button type="submit" className="mt-2" disabled={!estado.workbook.Sheets[estado.hojaActual]['!ref']}>Procesar Datos</Button>
                                    <Button className="mt-2 ml-2" variant="info" onClick={onClickSeleccionarModelo} disabled={!estado.workbook.Sheets[estado.hojaActual]['!ref']}>Cargar Modelo</Button>
                                    <Button className="mt-2 ml-2" variant="info" onClick={async () => {
                                        let errores = await formikRef.current!.validateForm();
                                        let errorPrimeraFila = errores.primeraFila;
                                        if (Array.isArray(errorPrimeraFila)) {
                                            errorPrimeraFila = errorPrimeraFila.join('\r\n');
                                        }
                                        errorPrimeraFila = errorPrimeraFila as string;
                                        if (!isNullOrWhiteSpace(errorPrimeraFila)) {
                                            mostrarError(errorPrimeraFila);
                                            return;
                                        }
                                        let errorCampos = errores.campos;
                                        if (Array.isArray(errorCampos)) {
                                            errorCampos = errorCampos.join('\r\n');
                                        }
                                        errorCampos = errorCampos as string;
                                        if (isNullOrWhiteSpace(errorCampos)) {
                                            updateEstado({ tipo: 'setMostrarModalCrearModelo', valor: true });
                                        } else {
                                            mostrarError(errorCampos);
                                        }
                                    }} disabled={!estado.workbook.Sheets[estado.hojaActual]['!ref']}>Guardar Modelo</Button>
                                </Form.Group>
                            </Form.Row>
                            <ErrorMessage name="campos">
                                {msg => (<div className="invalid-feedback" style={{ display: 'block' }}> {msg}</div>)}
                            </ErrorMessage>
                            <Nav variant="tabs" activeKey={estado.hojaActual} onSelect={hojaSeleccionada} className="my-2">
                                {estado.workbook.SheetNames.map((nombreHoja: string) => (
                                    <Nav.Item key={nombreHoja}>
                                        <Nav.Link eventKey={nombreHoja}>{nombreHoja}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <TablaExcel hoja={estado.workbook.Sheets[estado.hojaActual]}></TablaExcel>
                        </MyForm>
                    </Formik>
                )}
            </div>
        </BlockUi>
        <MyModal show={estado.mostrarModalCrearCatalogo} onHide={() => updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: false })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    Crear catalogo
                </Modal.Header>
                <Formik innerRef={formikCrearCatalogoRef} onSubmit={crearCatalogo} validationSchema={Yup.object({
                    'Codigo': Yup.string().nullable().required('Debe ingresar el codigo'),
                    'Descripcion': Yup.string().nullable(),
                    'SufijoAA': Yup.string().nullable()
                })} initialValues={{ 'Codigo': '', 'Descripcion': '', 'SufijoAA': '' }}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyFormControl type="text" name="Codigo" label="Codigo"></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormControl type="text" name="Descripcion" label="Descripción"></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormControl type="text" name="SufijoAA" label="Sufijo AA"></MyFormControl>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: false })}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}

                </Formik>

            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.mostrarModalCrearModelo} onHide={() => updateEstado({ tipo: 'setMostrarModalCrearModelo', valor: false })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    Crear Modelo
                </Modal.Header>
                <Formik innerRef={formikCrearModeloRef} onSubmit={crearModelo} validationSchema={Yup.object({
                    'Nombre': Yup.string().nullable().required('Debe ingresar el nombre del modelo'),
                })} initialValues={{ 'Nombre': '' }}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'setMostrarModalCrearModelo', valor: false })}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>

            </Modal.Dialog>
        </MyModal>
        <MyModal show={!!estado.mensajeExito} onHide={() => updateEstado({ tipo: 'setMensajeExito', valor: null })}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead text-success">{estado.mensajeExito}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'setMensajeExito', valor: null })}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <SeleccionModelo ref={seleccionModeloRef}></SeleccionModelo>
        <DialogoConfirmar ref={dialogoRef} mensaje={estado.mensajeDialogo} resolverPromesaAlCancelar textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
        <AltaBajaMapeosPais ref={altaBajaMapeosPaisRef}></AltaBajaMapeosPais>
        <AltaBajaMapeosUnidad ref={altaBajaMapeosUnidadRef}></AltaBajaMapeosUnidad>
        <AltaBajaMapeosEstadoMerc ref={altaBajaMapeosEstadoMercRef}></AltaBajaMapeosEstadoMerc>
    </>)
}