import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Form, Modal } from 'react-bootstrap';

interface editarFacturasProps {
    LDVDREFDOC: string
    ID: string,
    DespachoId: string,
    CDVDDOC: string,
    IDVD: string,
    NART: string,
    CDVDPRSDOC: string
    setReloadForm: (args: boolean) => void;
}

const ModalEditarFacturas = ({ LDVDREFDOC, ID, DespachoId, CDVDDOC, IDVD, NART, CDVDPRSDOC, setReloadForm }: editarFacturasProps) => {

    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    const [LDVDREFDOCValue, setLDVDREFDOCValue] = useState('')

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => setShow(true);

    const updateDVD = async () => {
        updateCargando(true)
        try {
            await api.updateDVD({
                ID: ID,
                DespachoId: DespachoId,
                CDVDDOC: CDVDDOC,
                IDVD: IDVD,
                LDVDREFDOC: LDVDREFDOCValue,
                NART: NART,
                CDVDPRSDOC: CDVDPRSDOC
            })
            updateCargando(false)
            setReloadForm(true)
        } catch (error) {
            updateCargando(false);
            console.log('error', error)
        }
    }
    let api = useAdvAPI();

    useEffect(() => {
        setLDVDREFDOCValue(LDVDREFDOC)

    // eslint-disable-next-line
    }, [show])



    return (
        <>
         <FontAwesomeIcon onClick={handleShow} style={{ fontSize: 18, color: 'blue', cursor:'pointer', marginRight:15 }}icon={faEdit} />
            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Editar</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    <Modal.Body>
                        <Form.Label>Número de Factura:</Form.Label>
                        <Form.Control
                            style={{ height: 37, marginTop: 0 }}
                            type="text"
                            name="LDVDREFDOC"
                            value={LDVDREFDOCValue}
                            onChange={(e) => setLDVDREFDOCValue(e.target.value)}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button variant="success" onClick={() => updateDVD()}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalEditarFacturas