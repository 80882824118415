import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Button, Col, Modal } from 'react-bootstrap'

interface propsModalPDFMaria {
    showModalPDFMaria: boolean;
    cargando: boolean;
    updateCargando: ((arg: boolean) => void)
    setShowModalPDFMaria: ((arg: boolean) => void)
    despachoID: Number
    estadoDespacho: string
    nroDespacho: string | null
    nroProvisorio: string | null
}

const ModalPDFMariaVisualizar = ({ showModalPDFMaria, cargando, updateCargando, setShowModalPDFMaria, despachoID, estadoDespacho, nroDespacho, nroProvisorio }: propsModalPDFMaria) => {

    let api = useAdvAPI();

    const [error, setError] = useState('')
    const [PDF, setPDF] = useState<string | undefined>()
    const descargarPDFMaria = async () => {
        try {
            updateCargando(true)
            let response = await api.DescargarPDFMaria(despachoID!)
            const base64String = response?.ArchivoBase64;
            if (response?.ArchivoBase64 === null) {
                updateCargando(false);
                setError('No existe el .PDF actual')
            } else {
                const byteCharacters = atob(base64String);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                // Crear un Blob a partir del array de bytes
                const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
                // Crear un enlace en memoria y descargar el archivo
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${nroProvisorio}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                updateCargando(false);
                setShowModalPDFMaria(false)
            }


        } catch (error) {
            updateCargando(false);
            setShowModalPDFMaria(false)
            console.log('error')
        }
    }

    const DescargarPDFOficializado = async () => {
        try {
            updateCargando(true)
            let response = await api.DescargarPDFOficializado(nroDespacho)
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nroDespacho}.pdf`);
            document.body.appendChild(link);
            link.click();
            link?.parentNode!.removeChild(link);
            updateCargando(false);
            setShowModalPDFMaria(false)
        } catch (error) {
            updateCargando(false);
            setError('Error al generar o no se encontró el PDF.')
            // setShowModalPDFMaria(false)
            console.log('error')
        }
    }

    const base64ToBlob = (base64: string, contentType = 'application/pdf') => {
        const byteCharacters = atob(base64);
        const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    };

    const visualizarPDFOficializado = async () => {
        try {
            updateCargando(true)
            let response = await api.DescargarPDFOficializado(nroDespacho)
            const blob = base64ToBlob(response);
            setPDF(URL.createObjectURL(blob))
            updateCargando(false)
        } catch (error) {
            updateCargando(false)
        }
    }

    const visualizarPDFMaria = async () => {
        try {
            updateCargando(true)
            let response = await api.DescargarPDFMaria(despachoID!)
            const base64String = response?.ArchivoBase64;
            const blob = base64ToBlob(base64String);
            setPDF(URL.createObjectURL(blob))
            updateCargando(false)
        } catch (error) {
            updateCargando(false)
        }
    }

    useEffect(() => {
        estadoDespacho === 'Oficializado' ?
            visualizarPDFOficializado()
            : visualizarPDFMaria()

    // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [estadoDespacho])


    return (
        <>
            <Modal show={showModalPDFMaria}
                dialogClassName="modal-pdf-maria"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="xl"
                style={{ width: '100%' }}
                backdrop="static"
                onHide={() => setShowModalPDFMaria(false)} >
                <BlockUi blocking={cargando} message={<b>Descargando PDF..</b>}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ver Impresión Malvina</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {error === '' ?
                            <Col
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    padding: 5,
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: '1px solid #ccc',
                                    margin: '20px auto',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                    <object
                                        aria-label='PDF'
                                        data={PDF}
                                        type="application/pdf"
                                        width="100%"
                                        height="600px"
                                    />
                                </div>
                                <Button style={{ marginLeft: 20, width: 100, height: 40, marginTop: 10 }} onClick={() => {
                                    estadoDespacho === 'Oficializado' ?
                                        DescargarPDFOficializado()
                                        : descargarPDFMaria()
                                }}>Descargar</Button>
                                <Button variant='danger' style={{marginTop:10, marginLeft:20}} onClick={() => {
                                    setError('')
                                    setShowModalPDFMaria(false)
                                }}>Cerrar</Button>
                            </Col> : <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ fontSize: 20, color: 'red', fontWeight: 'bold', marginLeft: 120, marginBottom: 20 }}> {error}</p>
                                <Button onClick={() => {
                                    setError('')
                                    setShowModalPDFMaria(false)
                                }}>Cerrar</Button>
                            </div>}
                    </Modal.Body>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalPDFMariaVisualizar