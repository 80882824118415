import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "../../App";
import imgPaso1 from "Imagenes/paso1.png";
import imgPaso2 from "Imagenes/paso2.png";
import imgPaso3 from "Imagenes/paso3.png";
import imgPaso4 from "Imagenes/paso4.png";
import imgPaso5 from "Imagenes/paso5.png";
import imgPaso6 from "Imagenes/paso6.png";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { useDecodedParams, isNullOrWhiteSpace } from "Utilidades";
import { DialogoConfirmar, DialogoConfirmarRef, DialogoSiNoCancelar } from "DialogoConfirmar";
import { EstadoCaratula, PestañaCaratula, TabCaratula } from "../../Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { GenerarTxtMaria, GenerarTxtMariaRef } from "./GenerarTxtMaria";
import styled from 'styled-components';
import { storageFactory } from "storage-factory";
import { EditarCarpeta, EditarCarpetaRef } from "Paginas/Caratulas/EditarCarpeta";
import { TareasCaratula } from "Paginas/Caratulas/TareasCaratula";
import { Presupuestos } from "Paginas/Caratulas/Presupuestos";
//eslint-disable-next-line
import { TipoLock, useApi } from "ApiHooks";
import { DateTime } from "luxon";
import { ResumenCaratula } from "Paginas/Caratulas/ResumenCaratula";
import ArchivosAdjuntosCaratula from "Paginas/Caratulas/ArchivosAdjuntosCaratula/ArchivosAdjuntosCaratula";
import FacturacionTable from "Paginas/Caratulas/FacturacionTable/FacturacionTable";

enum OrdenArmadoItems {
    Ncm = 0,
    Carga
}

enum AgrupamientoDescripcionSimi {
    NoUsarDescripcion = 0,
    MezclarDescripcion,
    UsarDescripcion,
    UsarDescripcionMaria
}

const OpcionesArmadoItems = styled.div`
    width: 36rem;
    border: 1px solid #00aa92;
    border-radius: 5px;
    padding-left: 1rem;
    margin-left: 4rem;
`;

const storage = storageFactory(() => localStorage);

export function PantallaPrincipalCaratula() {
    let { interno } = useDecodedParams() as { interno: string };
    let history = useHistory<{ mostrarTabInicial?: boolean, tabAMostrar?: TabCaratula }>();
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let editarCarpetaRef = useRef<EditarCarpetaRef>(null);
    // let archivosAdjuntosRef = useRef<ArchivosAdjuntosCaratulaRef>(null);
    let [cargando, updateCargando] = useState(true);
    let [cargandoArmadoItems, updateCargandoArmadoItems] = useState(false);
    let [notFound, updateNotFound] = useState(false);
    let [estado, updateEstado] = useState({
        estadoCaratula: undefined,
        bloqueada: false,
        caratulaCompleta: false,
        subitemsCompletos: false,
        mensajeErrorCaratula: '',
        subregimen: '',
        faltanBultos: false,
        llevaBultos: false
    });
    let [claveResumen, updateClaveResumen] = useState(0);
    let [claveTareas, updateClaveTareas] = useState(0);
    let [claveCarpeta, updateClaveCarpeta] = useState(0);
    let [activeTabKey, updateActiveTabKey] = useState(TabCaratula.Operacion);
    let ordenPorDefecto = storage.getItem('OrdenItems') ?? OrdenArmadoItems.Ncm.toString();
    let agrupamientoPorDefecto = storage.getItem('AgrupamientoDescripcionSimi') ?? AgrupamientoDescripcionSimi.UsarDescripcionMaria.toString();
    let [opcionesArmadoItems, updateOpcionesArmadoItems] = useReducer((estado: any, accion: any) => {
        let nuevoEstado = { ...estado };
        nuevoEstado[accion.tipo] = accion.valor;
        return nuevoEstado;
    }, {
        calcularMulta: false, calcularRegimenMineria: false, resumenVentajas: false, informacionAdicionalCatalogo: false,
        orden: ordenPorDefecto, rearmarItems: false, agrupamientoDescripcionSimi: agrupamientoPorDefecto
    });
    let [mensajeDialogoArmadoItems, updateMensajeDialogoArmadoItems] = useState('');
    let dialogoArmadoItemsRef = useRef<DialogoConfirmarRef>(null);
    let dialogoSiNoCancelarRef = useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogoSiNo, updateMensajeDialogoSiNo] = useState('');
    let [mensajeDialogoBloquear, updateMensajeDialogoBloquear] = useState('');
    let dialogoBloquearRef = useRef<DialogoConfirmarRef>(null);
    let generarTxtMariaRef = useRef<GenerarTxtMariaRef>(null);
    let timeout = useRef<any>({ valor: null });
    let cargar = useCallback(async () => {
        if (!api.isUnmounted()) {
            try {
                let respuesta = await api.getEstadoCaratula(interno);
                if (!respuesta) {
                    updateNotFound(true);
                    return;
                }
                const llevaBultos = await api.subregimenLlevaBultos(respuesta.Subregimen);
                updateEstado({
                    estadoCaratula: respuesta.Estado,
                    bloqueada: respuesta.CaratulaBloqueada,
                    caratulaCompleta: respuesta.CaratulaCompleta,
                    subitemsCompletos: respuesta.SubitemsCompletos,
                    mensajeErrorCaratula: respuesta.MensajeErrorCaratula,
                    subregimen: respuesta.Subregimen,
                    faltanBultos: respuesta.FaltanBultos,
                    llevaBultos: llevaBultos
                });
                updateCargando(false);
                if (respuesta.Estado === EstadoCaratula.ArmadoItemsPendiente || respuesta.Estado === EstadoCaratula.ItemsArmados) {
                    timeout.current.valor = setTimeout(cargar, 5000);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar estado de caratula', error);
                    mostrarError('Error al cargar estado de caratula');
                }
            }
        }
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        async function cargaInicial() {
            if (history.location.state?.mostrarTabInicial) {
                try {
                    let respuesta = await api.getConfiguracionEmpresa();
                    if (respuesta.Configuracion?.PestañaCaratulaPorDefecto === PestañaCaratula.Carpeta) {
                        updateActiveTabKey(TabCaratula.Carpeta);
                    }
                } catch (error) {
                    if (api.isCancel(error)) {
                        return;
                    } else {
                        console.error('Error al cargar configuración de empresa', error);
                    }
                }
            } else if (history.location.state?.tabAMostrar) {
                updateActiveTabKey(history.location.state?.tabAMostrar);
            }
            await cargar();
        }
        cargaInicial();
        return () => {
            //eslint-disable-next-line
            if (timeout.current.valor) {
                //eslint-disable-next-line
                clearTimeout(timeout.current.valor);
            }
        }
        //eslint-disable-next-line
    }, []);
    async function onClickBotonArmadoItems() {
        if (opcionesArmadoItems.rearmarItems) {
            try {
                updateCargandoArmadoItems(true);
                let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
                if (puedeModificar) {
                    await api.borrarArmadoItems(interno);
                    iniciarArmadoItems();
                } else {
                    updateCargandoArmadoItems(false);
                    mostrarError(`No se pueden rearmar los items porque otro usuario está modificando la caratula ${interno}`);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar armado items', error);
                    mostrarError('Error al borrar armado items');
                    updateCargandoArmadoItems(false);
                }
            }
        } else {
            if (estado.estadoCaratula === EstadoCaratula.Ninguno || estado.estadoCaratula === EstadoCaratula.ErrorArmadoItems) {
                iniciarArmadoItems();
            } else if (estado.estadoCaratula === EstadoCaratula.ArmadoItemsPendiente) {
                history.push(`/caratulas/${encodeURIComponent(interno)}/armadoItems/mensajes`);
            } else {
                history.push(`/caratulas/${encodeURIComponent(interno)}/armadoItems/edit`);
            }
        }
    }
    async function mostrarDialogoArmadoItems(mensaje: string) {
        try {
            updateMensajeDialogoArmadoItems(mensaje);
            return await dialogoArmadoItemsRef.current!.mostrar();
        } catch (error) {
            //cerro dialogo confirmar, ignorar
            return null;
        }
    }
    async function iniciarArmadoItems() {
        try {
            storage.setItem('OrdenItems', opcionesArmadoItems.orden);
            storage.setItem('AgrupamientoDescripcionSimi', opcionesArmadoItems.agrupamientoDescripcionSimi);
            updateCargandoArmadoItems(true);
            let caratula = await api.getCaratula(interno);
            if (caratula.CodigoSubregimen === 'SIMI' || caratula.CodigoSubregimen === 'SIRA' || caratula.CodigoSubregimen === 'SEDI') {
                if (opcionesArmadoItems.agrupamientoDescripcionSimi.NoUsarDescripcion || caratula.ForzarDescripcionMercaderia) {
                    if (isNullOrWhiteSpace(caratula.DescripcionMercaderia)) {
                        if (!await mostrarDialogoArmadoItems('No ha ingresado descripción mercadería en la caratula. ¿Desea continuar?')) {
                            updateCargandoArmadoItems(false);
                            return;
                        }
                    }
                }

                if (opcionesArmadoItems.agrupamientoDescripcionSimi === AgrupamientoDescripcionSimi.UsarDescripcionMaria) {
                    let cantidadSubitemsSinDescripcionMaria = await api.getCantidadSubitemsSinDescripcionMaria(interno);
                    if (cantidadSubitemsSinDescripcionMaria > 0) {
                        if (!await mostrarDialogoArmadoItems(`Hay ${cantidadSubitemsSinDescripcionMaria} subitems sin Descripción María. ¿Desea continuar?`)) {
                            updateCargandoArmadoItems(false);
                            return;
                        }
                    }
                } /* else {
                    let cantidadSubitemsSinDescripcion = await api.getCantidadSubitemsSinDescripcion(interno);
                    if (cantidadSubitemsSinDescripcion > 0) {
                        if (!await mostrarDialogoArmadoItems(`Hay ${cantidadSubitemsSinDescripcion} subitems sin Descripción Catalogo. ¿Desea continuar?`)) {
                            updateCargandoArmadoItems(false);
                            return;
                        }
                    }
                } */
            }
            let subregimenes = await api.getSubregimenes();
            let calcularCoeficienteExpo = false;
            let paisCooperanteAnticipoGanancias = null;
            let subregimenesAnticipoGanancias = ["EC01", "EC03", "EC06", "EC07", "EC08", "EC09", "ECE1"];
            if (subregimenesAnticipoGanancias.includes(caratula.CodigoSubregimen)) {
                if (await mostrarDialogoArmadoItems('¿Realiza triangulación?')) {
                    paisCooperanteAnticipoGanancias = await mostrarDialogoArmadoItems('¿El país de triangulación es cooperante?') ?? false;
                }
            }
            let subregimen = subregimenes.find((s: any) => s.Codigo === caratula.CodigoSubregimen);
            if (subregimen.Tipo === 'E') {
                if (await mostrarDialogoArmadoItems('¿Calcular coeficiente de exportación?')) {
                    calcularCoeficienteExpo = true;
                }
            }

            let calcularImpuestosInternos = false;
            if (await api.caratulaTieneSubitemsConImpuestosInternos(interno)) {
                if (await mostrarDialogoArmadoItems('¿Se trata de objetos suntuarios definidos como tales en la ley 25239 (Capitulo VIII)?')) {
                    calcularImpuestosInternos = true;
                }
            }

            let codsObs = await api.caratulaTieneSubitemsConImpuestoPais(interno);
            let impuestoPaisMercaderiasCBUSKD = false;
            console.log('codsObs ' + codsObs);
            if (codsObs.length > 0) {
                for (const codobs of codsObs) {
                    console.log('codobs ' + codobs);
                    if (codobs === 5) // si es 5 debo preguntar 
                    {
                        if (await mostrarDialogoArmadoItems('¿Las mercaderias corresponden Únicamente a las totalmente armadas (CBU) y semidesarmadas (SKD)?')) {
                            impuestoPaisMercaderiasCBUSKD = true;
                        }
                    }
                }
            }


            let importador = await api.getImportador(caratula.CuitImportador);
            let agregarReduccionIva = false;
            if ((importador.PorcentajeReduccionIva ?? 0) > 0) {
                if (DateTime.fromISO(importador.FechaVencimientoReduccionIva) > DateTime.local()) {
                    agregarReduccionIva = true;
                } else {
                    try {
                        updateMensajeDialogoSiNo('El cliente tiene una reducción de IVA que se venció. ¿Desea agregar la ventaja de reducción de IVA?');
                        agregarReduccionIva = await dialogoSiNoCancelarRef.current!.mostrar();
                    } catch {
                        updateCargandoArmadoItems(false);
                        return;
                    }
                }
            }
            let agregarReduccionGanancias = false;
            if ((importador.PorcentajeReduccionGanancias ?? 0) > 0) {
                if (DateTime.fromISO(importador.FechaVencimientoReduccionGanancias) > DateTime.local()) {
                    agregarReduccionGanancias = true;
                } else {
                    try {
                        updateMensajeDialogoSiNo('El cliente tiene una reducción de ganancias que se venció. ¿Desea agregar la ventaja de reducción de ganancias?');
                        agregarReduccionGanancias = await dialogoSiNoCancelarRef.current!.mostrar();
                    } catch {
                        updateCargandoArmadoItems(false);
                        return;
                    }
                }
            }
            await api.solicitarArmadoItems(interno, opcionesArmadoItems.orden, calcularCoeficienteExpo,
                opcionesArmadoItems.calcularRegimenMineria, opcionesArmadoItems.calcularMulta,
                calcularImpuestosInternos, paisCooperanteAnticipoGanancias, opcionesArmadoItems.agrupamientoDescripcionSimi,
                agregarReduccionIva, agregarReduccionGanancias, impuestoPaisMercaderiasCBUSKD);
            history.push(`/caratulas/${encodeURIComponent(interno)}/armadoItems/mensajes`);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al iniciar armado items', error);
                mostrarError('Error al iniciar armado items');
                updateCargandoArmadoItems(false);
            }
        }
    }
    function bloquear() {
        if (estado.bloqueada) {
            updateMensajeDialogoBloquear('¿Está seguro que desea desbloquear la caratula?');
        } else {
            updateMensajeDialogoBloquear('¿Está seguro que desea bloquear la caratula?');
        }
        dialogoBloquearRef.current!.mostrar().then(async () => {
            try {
                updateCargandoArmadoItems(true);
                let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
                if (puedeModificar) {
                    await api.updateBloqueoCaratula(interno, !estado.bloqueada);
                    if (timeout.current.valor) {
                        clearTimeout(timeout.current.valor);
                    }
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargar();
                    updateCargandoArmadoItems(false);
                } else {
                    updateCargandoArmadoItems(false);
                    if (estado.bloqueada) {
                        mostrarError('No se puede desbloquear la caratula porque otro usuario la está usando');
                    } else {
                        mostrarError('No se puede bloquear la caratula porque otro usuario la está usando');
                    }
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al bloquear/desbloquear caratula', error);
                    updateCargandoArmadoItems(false);
                    if (estado.bloqueada) {
                        mostrarError('Error al desbloquear caratula');
                    } else {
                        mostrarError('Error al bloquear caratula');
                    }
                }
            }
        }).catch(() => { });
    }
    const mostrarLabelCaratulaCompleta = () => {
        if (estado.estadoCaratula === undefined) {
            return (<></>);
        } else if (estado.caratulaCompleta) {
            return (<span className="text-success font-weight-bold ml-2">COMPLETO!</span>);
        } else {
            return (<>
                <span className="text-danger font-weight-bold ml-2">INCOMPLETO!</span>
                <span className="text-danger ml-2">{estado.mensajeErrorCaratula}</span>
            </>);
        }
    }
    const mostrarLabelSubitemsCompletos = () => {
        if (estado.estadoCaratula === undefined) {
            return (<></>);
        } else if (estado.subitemsCompletos) {
            return (<span className="text-success font-weight-bold mx-2" style={{ width: '150px' }}>COMPLETO!</span>);
        } else {
            return (<span className="text-danger font-weight-bold mx-2" style={{ width: '150px' }}>INCOMPLETO!</span>);
        }
    }
    const mostrarBotonBloqueoCaratula = () => {
        if (estado.estadoCaratula === undefined) {
            return (<></>);
        } else if (estado.bloqueada) {
            return (<Button onClick={bloquear}><FontAwesomeIcon icon={faLockOpen}></FontAwesomeIcon> Desbloquear</Button>)
        } else {
            return (<Button onClick={bloquear}><FontAwesomeIcon icon={faLock}></FontAwesomeIcon> Bloquear</Button>)
        }
    }
    const mostrarLabelArmadoItems = () => {
        if (estado.estadoCaratula === EstadoCaratula.Ninguno || estado.estadoCaratula === EstadoCaratula.ArmadoItemsPendiente ||
            estado.estadoCaratula === EstadoCaratula.ErrorArmadoItems) {
            return (<span className="text-danger font-weight-bold ml-2">SIN ARMAR</span>);
        } else if (estado.estadoCaratula === EstadoCaratula.ErrorLiquidacion || estado.estadoCaratula === EstadoCaratula.ItemsArmados ||
            estado.estadoCaratula === EstadoCaratula.LiquidacionHecha) {
            return (<span className="text-success font-weight-bold ml-2">ARMADOS</span>);
        } else {
            return (<></>);
        }
    }
    
    const mostrarLabelBultos = () => {
        if (estado.estadoCaratula === undefined) {
            return (<></>);
        } else if (estado.faltanBultos) {
            return (<span className="text-danger font-weight-bold ml-2" style={{ width: '150px' }}>INCOMPLETO!</span>);
        } else {
            return (<span className="text-success font-weight-bold mx-2" style={{ width: '150px' }}>COMPLETO!</span>);

        }
    }
    //recargar tab tareas si se selecciona la plantilla en tab referencias
    const onPlantillaSeleccionadaReferencias = useCallback(() => updateClaveTareas(i => i + 1), [updateClaveTareas]);
    const onPlantillaSeleccionadaTareas = useCallback(() => updateClaveCarpeta(i => i + 1), [updateClaveCarpeta]);
    useEffect(() => {
        if (activeTabKey !== TabCaratula.Carpeta) {
            //recargar tab carpeta al salir de esa tab
            updateClaveCarpeta(i => i + 1);
        }
        if (activeTabKey === TabCaratula.Archivos) {
            // archivosAdjuntosRef.current!.cargar();
        } else if (activeTabKey === TabCaratula.Resumen) {
            updateClaveResumen(i => i + 1);
        }
    }, [activeTabKey]);


    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                history.goBack()
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line
    }, [history]);

    return notFound ? (<NotFoundComponent></NotFoundComponent>) : (<>
        <h2>Interno {interno}</h2>
        <BlockUi blocking={cargando || cargandoArmadoItems}>
            <Tabs activeKey={activeTabKey} id="tabsCaratula" onSelect={async key => {
                let continuar = true;
                if (activeTabKey === TabCaratula.Carpeta) {
                    continuar = await editarCarpetaRef.current!.preguntarGuardarCambios();
                }
                if (continuar) {
                    updateActiveTabKey(key as TabCaratula);
                }
            }}>
                <Tab eventKey={TabCaratula.Carpeta} title="Carpeta">
                    <EditarCarpeta key={claveCarpeta} ref={editarCarpetaRef} interno={interno} bloquear={estado.bloqueada} onPlantillaSeleccionada={onPlantillaSeleccionadaReferencias}></EditarCarpeta>
                </Tab>
                <Tab eventKey={TabCaratula.Operacion} title="Operación">
                    <div style={{ width: '75%', position: 'relative', left: '25%' }}>
                        <div>
                            <img src={imgPaso1} alt="Paso 1" title="Paso 1" width="50"></img>
                            <div className="d-inline-block">
                                <p className="aclaracion">Datos generales del despacho</p>
                                <div>
                                    <Button style={{ width: '200px' }} onClick={() => history.push("/caratulas/" + encodeURIComponent(interno) + "/caratula")}>Caratula</Button>
                                    {mostrarLabelCaratulaCompleta()}
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={imgPaso2} alt="Paso 2" title="Paso 2" width="50"></img>
                            <div className="d-inline-block">
                                <p className="aclaracion">Carga de articulos de la factura</p>
                                <div>
                                    <Button style={{ width: '200px' }} onClick={() => history.push("/caratulas/" + encodeURIComponent(interno) + "/subitems")}>Subitems</Button>
                                    {mostrarLabelSubitemsCompletos()}
                                    {mostrarBotonBloqueoCaratula()}
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={imgPaso3} alt="Paso 3" title="Paso 3" width="50"></img>
                            <div className="d-inline-block">
                                <p className="aclaracion">Agrupar articulos en items</p>
                                <div>
                                    <Button style={{ width: '200px' }} onClick={onClickBotonArmadoItems} disabled={!estado.caratulaCompleta || !estado.subitemsCompletos ||
                                        (estado.bloqueada && estado.estadoCaratula !== EstadoCaratula.ErrorLiquidacion && estado.estadoCaratula !== EstadoCaratula.ItemsArmados && estado.estadoCaratula !== EstadoCaratula.LiquidacionHecha)}>Armado de items</Button>
                                    {mostrarLabelArmadoItems()}
                                    <div className="d-inline-block ml-2">
                                        {(estado.estadoCaratula === EstadoCaratula.ErrorLiquidacion || estado.estadoCaratula === EstadoCaratula.ItemsArmados || estado.estadoCaratula === EstadoCaratula.LiquidacionHecha) &&
                                            <Form inline>
                                                <Form.Check custom id="checkRearmarItems" label="Rearmar items" disabled={estado.bloqueada} checked={opcionesArmadoItems.rearmarItems} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'rearmarItems', valor: e.target.checked })}></Form.Check>
                                            </Form>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(estado.estadoCaratula === EstadoCaratula.Ninguno || estado.estadoCaratula === EstadoCaratula.ErrorArmadoItems
                            || opcionesArmadoItems.rearmarItems) && (<Form>
                                <OpcionesArmadoItems>
                                    <h6>Opciones de armado de items</h6>
                                    <Form.Row>
                                        <Form.Group className="mr-2">
                                            <Form.Check custom id="checkCalcularMulta" label="Calcular multa" checked={opcionesArmadoItems.calcularMulta} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'calcularMulta', valor: e.target.checked })}></Form.Check>
                                            <Form.Check custom id="checkCalcularRegimenMineria" label="Calcular regimen mineria" checked={opcionesArmadoItems.calcularRegimenMineria} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'calcularRegimenMineria', valor: e.target.checked })}></Form.Check>
                                            <Form.Check custom id="checkResumenVentajas" label="Ver resumen de ventajas" checked={opcionesArmadoItems.resumenVentajas} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'resumenVentajas', valor: e.target.checked })}></Form.Check>
                                            <Form.Check custom id="checkInformacionAdicionalCatalogo" label="Usar información adicional del catalogo" checked={opcionesArmadoItems.informacionAdicionalCatalogo} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'informacionAdicionalCatalogo', valor: e.target.checked })}></Form.Check>
                                        </Form.Group>
                                        <Form.Group className="mr-2">
                                            <Form.Check custom type="radio" id="radioOrdenNcm" name="ordenArmadoItems" value={OrdenArmadoItems.Ncm.toString()} checked={opcionesArmadoItems.orden === OrdenArmadoItems.Ncm.toString()} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'orden', valor: e.target.value })} label="NCM"></Form.Check>
                                            <Form.Check custom type="radio" id="radioOrdenCarga" name="ordenArmadoItems" value={OrdenArmadoItems.Carga.toString()} checked={opcionesArmadoItems.orden === OrdenArmadoItems.Carga.toString()} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'orden', valor: e.target.value })} label="Por Orden de carga"></Form.Check>
                                        </Form.Group>
                                        <Form.Group>
                                            {(estado.subregimen === 'SIMI' || estado.subregimen === 'SIRA' || estado.subregimen === 'SEDI') && (<>
                                                <label className="font-weight-bold">Descripción SEDI</label>
                                                <Form.Check custom type="radio" id="radioUsarDescripcionMaria" name="descripcionSimi" label="Usar Descripción Maria" value={AgrupamientoDescripcionSimi.UsarDescripcionMaria.toString()}
                                                    checked={opcionesArmadoItems.agrupamientoDescripcionSimi === AgrupamientoDescripcionSimi.UsarDescripcionMaria.toString()} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'agrupamientoDescripcionSimi', valor: e.target.value })}></Form.Check>
                                                <Form.Check custom type="radio" id="radioUsarDescripcion" name="descripcionSimi" label="Usar Descripción Catalogo" value={AgrupamientoDescripcionSimi.UsarDescripcion.toString()}
                                                    checked={opcionesArmadoItems.agrupamientoDescripcionSimi === AgrupamientoDescripcionSimi.UsarDescripcion.toString()} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'agrupamientoDescripcionSimi', valor: e.target.value })}></Form.Check>
                                                <Form.Check custom type="radio" id="radioMezclarDescripciones" name="descripcionSimi" label="Mezclar Descripciones" value={AgrupamientoDescripcionSimi.MezclarDescripcion.toString()}
                                                    checked={opcionesArmadoItems.agrupamientoDescripcionSimi === AgrupamientoDescripcionSimi.MezclarDescripcion.toString()} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'agrupamientoDescripcionSimi', valor: e.target.value })}></Form.Check>
                                                <Form.Check custom type="radio" id="radioNoUsarDescripcion" name="descripcionSimi" label="No Usar Descripción" value={AgrupamientoDescripcionSimi.NoUsarDescripcion.toString()}
                                                    checked={opcionesArmadoItems.agrupamientoDescripcionSimi === AgrupamientoDescripcionSimi.NoUsarDescripcion.toString()} onChange={(e: any) => updateOpcionesArmadoItems({ tipo: 'agrupamientoDescripcionSimi', valor: e.target.value })}></Form.Check>
                                            </>)}
                                        </Form.Group>
                                    </Form.Row>
                                </OpcionesArmadoItems>
                            </Form>)}
                        <div>
                            <img src={imgPaso4} alt="Paso 4" title="Paso 4" width="50"></img>
                            <div className="d-inline-block">
                                <p className="aclaracion">Carga del conocimiento</p>
                                <Button disabled={!estado.llevaBultos} style={{ width: '200px' }}
                                    onClick={() => history.push('/caratulas/' + encodeURIComponent(interno) + '/bultos')}>Bultos</Button>
                                    {estado?.subregimen?.slice(0, -2) === 'EC' && mostrarLabelBultos()}
                            </div>
                        </div>
                        <div>
                            <img src={imgPaso5} alt="Paso 5" title="Paso 5" width="50"></img>
                            <div className="d-inline-block">
                                <Button style={{ width: '200px' }} disabled={estado.faltanBultos || (estado.estadoCaratula !== EstadoCaratula.ItemsArmados && estado.estadoCaratula !== EstadoCaratula.ErrorLiquidacion && estado.estadoCaratula !== EstadoCaratula.LiquidacionHecha)}
                                    onClick={() => generarTxtMariaRef.current!.mostrar().finally(() => {
                                        cargar();
                                    })}>Transferencia al Maria</Button>
                            </div>
                        </div>
                        <div>
                            <img src={imgPaso6} alt="Paso 6" title="Paso 6" width="50"></img>
                            <div className="d-inline-block">
                                <Button style={{ width: '200px' }} onClick={() => updateActiveTabKey(TabCaratula.Presupuestos)}>Presupuestar</Button>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey={TabCaratula.Tareas} title="Tareas/Notas">
                    <TareasCaratula key={claveTareas} interno={interno} bloquear={estado.bloqueada} onPlantillaSeleccionada={onPlantillaSeleccionadaTareas}></TareasCaratula>
                </Tab>
                <Tab eventKey={TabCaratula.Presupuestos} title="Presupuestos">
                    <Presupuestos interno={interno}></Presupuestos>
                </Tab>
                <Tab eventKey={TabCaratula.Archivos} title="Archivos">
                    <ArchivosAdjuntosCaratula activeTabKey={activeTabKey} interno={interno} />
                </Tab>
                <Tab eventKey={TabCaratula.Facturacion} title="Facturación">
                    <FacturacionTable activeTabKey={activeTabKey} interno={interno} />
                </Tab>
                <Tab eventKey={TabCaratula.Resumen} title="Resumen">
                    <ResumenCaratula interno={interno} key={claveResumen}></ResumenCaratula>
                </Tab>
            </Tabs>
        </BlockUi>
        <DialogoConfirmar ref={dialogoArmadoItemsRef} mensaje={mensajeDialogoArmadoItems} resolverPromesaAlCancelar textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
        <DialogoConfirmar ref={dialogoBloquearRef} mensaje={mensajeDialogoBloquear} textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
        <DialogoSiNoCancelar ref={dialogoSiNoCancelarRef} mensaje={mensajeDialogoSiNo}></DialogoSiNoCancelar>
        <GenerarTxtMaria ref={generarTxtMariaRef} interno={interno} />
    </>);
}