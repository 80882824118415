export enum EstadoCaratula {
    Ninguno = 0,
    ArmadoItemsPendiente,
    ItemsArmados,
    ErrorArmadoItems,
    LiquidacionHecha,
    ErrorLiquidacion
}

export enum EstadoDespacho {
    SinValidar = 'Sin Validar',
    Oficializado = 'Oficializado',
    Validado = 'Validado'
}

export enum MonedaConceptoPresupuesto {
    Peso = 0,
    DolarA,
    DolarB
}

export enum EstadoArticuloProcesoExcel {
    OK = 0,
    ErrorNcm,
    ErrorSufijos,
    ErrorFaltaCantEst,
    ErrorCantEstNoCoincide,
    ErrorNoExisteEnCatalogo,
    ErrorFaltaUnidadDeclarada,
    ErrorArticuloNoValidado
}

export enum EstadoNcmArticuloProcesoExcel {
    Archivo = 0,
    Catalogo,
    Error
};

export enum BaseConceptoPlantillaCalculoImportador {
    FOB = 0,
    CIF,
    BaseImponible
}

export enum MonedaConceptoPlantillaCalculoImportador {
    DolarA = 0,
    DolarB,
    Peso
}

export enum PestañaCaratula {
    Operacion = 0,
    Carpeta
}

export enum SelecionFobTotalPrecioUnitario {
    FobTotal = 0,
    PrecioUnitario
}

export enum TabCaratula {
    Carpeta = 'carpeta',
    Operacion = 'operacion',
    Tareas = 'tareas',
    Presupuestos = 'presupuestos',
    Archivos = 'archivos',
    Resumen='resumen',
    Facturacion='facturacion'
}