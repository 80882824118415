//import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import { MyForm, MyFormControl} from "FormikHooks";
import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Form, Modal } from "react-bootstrap";
import { MyModal } from "MyModal";
import { validarCUIT } from "Utilidades";
import logoAfip from './Imagenes/logo_afip.png';
import { useApi } from "ApiHooks";
import { useAdvAPI } from "./AdvancedApi";
import BlockUi from "react-block-ui";

export type LoginAFIPRef = {
    mostrar: (cuit : string) => Promise<{inicioSesion: boolean}>
};



export const LoginAFIP = React.forwardRef((props: any, ref: any) => {
    
    //const [showModal, setshowModal] = React.useState(true);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let [cuitLogin, setcuitLogin] = React.useState('');
    let [tresPasos, settresPasos] = React.useState(false);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    let [mensajeError, setMensajeError] = useState('');
    let [imagenCaptcha, setImagenCaptcha] = useState('');

    let [cargando, setCargado] = useState(false);

    let apiADV = useAdvAPI();
    let apiSintia = useApi();

    let schema = Yup.object({
        cuit: Yup.string().nullable().required('Debe ingresar CUIT/CUIL').test('cuit-valido', 'El CUIT ingresado no es válido', validarCUIT),
        token: Yup.string().nullable().required('Debe ingresar tu clave fiscal')
    });

    let initialValues = { cuit: '', clave: '', token: '', captcha: '' };

    let [estadoModal, updateEstadoModal] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, opciones: accion.opciones, error: '', resolve: accion.resolve };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, opciones: null, error: '' };
        } else if (accion.tipo === 'setError') {
            return { ...estado, error: accion.error };
        }
    }, { abierto: false, opciones: null, error: '' });
    

    useEffect(() => {
        if (cuitLogin !== '' && cuitLogin !== null && cuitLogin !== undefined)
        {               
            formikRef.current?.setFieldValue('cuit',cuitLogin);
        }    
    }, [cuitLogin])


    async function submit(submit: any) {

        setCargado(true);

        let mensaje :string;
        let resp = await apiADV.iniciarSesionAFIP(submit.cuit, submit.clave, submit.token, submit.captcha);

        mensaje='';


        if (resp.ParsedResponse?.StatusCode === null || resp.ParsedResponse?.StatusCode === undefined )
        {
            if (resp.ParsedResponse?.ResponseObject !== null) {
                    mensaje = JSON.stringify(resp.ParsedResponse);
            } else {

                mensaje = resp.ParsedResponse?.ApiMessage;
            }

            setMensajeError(mensaje);

        }

        if (resp.ParsedResponse?.StatusCode === 200)
        {
            ////////////////////
            // inicio session correctamente ahora a guardar la sesion
            var nombreCompleto: string;

            let datos = await apiADV.getConstanciaAFIP(submit.cuit);
            nombreCompleto = datos.Nombres + ' ' + datos.Apellido;
            nombreCompleto = nombreCompleto!.replaceAll('nombre:',''); //.replaceAll('\\\"','').trim();
            nombreCompleto = nombreCompleto!.replaceAll('apellido:','').replaceAll('\\\"','').trim(); //eslint-disable-line

            await apiSintia.insertSesionesAFIPHistorico(submit.cuit, nombreCompleto);

            setMensajeError('');
            setImagenCaptcha('');
            setcuitLogin('');
            funcionesPromesa.current?.resolve({ inicioSesion: true });
            updateEstadoModal({ tipo: 'cerrar' });
            props?.setShowModalSesiones(true)

        } else if (resp.ParsedResponse?.StatusCode === 100) {
            mensaje = resp.ParsedResponse!.ResponseObject.Message
            setImagenCaptcha(mensaje);
        } else if (resp.ParsedResponse?.StatusCode === 400) {
            mensaje = resp.ParsedResponse!.ResponseObject.Message
            setMensajeError(mensaje);
        } else {

            if (resp.ParsedResponse?.ResponseObject !== null) {
                    mensaje = resp.ParsedResponse?.ResponseObject.Message;
            } else {
                mensaje = resp.ParsedResponse?.ApiMessage;
            }
            setMensajeError(mensaje);
        }

        //setMensajeError(mensajesError.join('\r\n')
        setCargado(false);
    }

    React.useImperativeHandle(ref, () => ({
        mostrar: (cuit : string) => new Promise<void>((resolve, reject) => {
            funcionesPromesa.current = { resolve: resolve, reject: reject };
            setcuitLogin(cuit);
            updateEstadoModal({ tipo: 'mostrar', resolve: resolve });
        })
    }));

    const cerrar = () => {
        if (estadoModal.resolve) {
            estadoModal.resolve();
        }
        setCargado(false);
        setMensajeError('');
        setImagenCaptcha('');
        setcuitLogin('');
        funcionesPromesa.current?.resolve({ inicioSesion: false });
        updateEstadoModal({ tipo: 'cerrar' });
    }

    /*
    const modalStyles = {
        "display":"flex !important",
        "position": "absolute",
        "top": "50%"
    };*/
    return (<>
        {/*onHide={cerrar}*/}
        <MyModal show={estadoModal.abierto} onHide={cerrar} >
            <Modal.Dialog size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                {/*<Modal.Header closeButton>
                    <h2>Iniciar Sesion AFIP</h2>
</Modal.Header>*/}

                    <Container >
                        <BlockUi blocking={cargando}>
                                <Formik initialValues={initialValues}
                                    validationSchema={schema}
                                    onSubmit={submit}
                                    innerRef={formikRef}
                                    >

                                        <MyForm>

                                            <Form.Group>
                                                <br/>
                                                <img className="img-fluid img-responsive center-block" width="80%"
                                                    src={logoAfip} 
                                                    alt="logo"/>
                                            </Form.Group>
                                            <Form.Group>
                                                <MyFormControl type="text" placeholder="Ingresa tu CUIT/CUIL" style={{textAlign:'center'}}
                                                    label="CUIT/CUIL" id="cuit" name="cuit" autoFocus={true} ></MyFormControl>
                                            </Form.Group>
                                            <Form.Group >
                                                <p></p>
                                            </Form.Group>

                                            {tresPasos && (
                                                    <Form.Group >
                                                        <MyFormControl  type="password" placeholder="Ingresa tu clave fiscal" label="Tu clave" name="clave" />
                                                    </Form.Group>
                                            )}


                                            <Form.Group >
                                                <MyFormControl type="password" placeholder="Ingresa tu clave fiscal o token" label="Tu Token" name="token" />
                                            </Form.Group>


                                            {imagenCaptcha ? 
                                            <Form.Group >
                                                <img alt="Resuelve el captcha de AFIP" style={{ alignSelf: 'center' }} className="img-fluid img-responsive center-block" src={`data:image/png;base64,${imagenCaptcha}`}/>
                                                <br/>
                                                <MyFormControl type="text" placeholder="Resuelve el captcha" label="" name="captcha" />
                                            </Form.Group>
                                            : ''
                                            }

                                            {mensajeError && (<Alert variant="danger">{mensajeError}</Alert>)}
                                            <Form.Group >
                                                <div className="flex-wrap justify-content-end">
                                                    <Form.Check type="switch" id="tercerPaso" label="Usar 3 Pasos" 
                                                            onChange={(e: any) => {
                                                                settresPasos(e.target.checked ?true : false)}} >
                                                    </Form.Check>
                                                    <br/>
                                                    <Button className="btn-md mr-1" type="submit" onClick={() => {formikRef.current?.submitForm()}}  >Ingresar</Button>
                                                    <Button className="btn-md mr-1" variant="danger" onClick={cerrar}>Cancelar</Button>
                                                </div>
                                            </Form.Group>
                                            <br/>
                                            <br/>
                                        </MyForm>
                                </Formik>
                        </BlockUi>
                    </Container>
            </Modal.Dialog>
        </MyModal>
        </>)

})
