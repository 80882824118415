import { useApi } from 'ApiHooks';
import { MySelect, OnValueChangeArgument, SelectOption } from 'FormikHooks';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import { convertirDatosGenericosAOption, optionLabelConCodigo } from 'Utilidades';
import { Formik } from 'formik';
import { Certificacion, InsertCPLBody, Item, LiquidacionItem, ObtenerItemVistaResponse, SubItem } from 'modelos/Advanced';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Modal, Form, Col, Container, Card, Alert, Tabs, Tab, Table } from 'react-bootstrap';
import './styleTable.css'
import { faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalEditarInfComp from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarInfComp';
import ModalEliminarDCEXP from 'Paginas/advanced/ProvisorioTable/ModalEliminarDCEXP';
import ModalEditarSubItem from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarSubItem';
import ModalEditarSubItemSufijos from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarSubItemSufijos';
import ModalEliminarFacturasInfCompVentajas from 'Paginas/advanced/ProvisorioTable/ModalEliminarFacturasInfCompVentajas';
import ModalEditarSufijos from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarSufijos';
import ModalSelectMultipleItems from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalSelectMultipleItems';
import ModalInsertarProrrateos from 'Paginas/advanced/ProvisorioTable/ModalInsertarProrrateos';
import { EstadoDespacho } from 'Enums';

interface props {
    despachoID: string,
    id: string,
    tableDataItems?: ObtenerItemVistaResponse[]
    nroProvisorio?: MutableRefObject<string | null>;
    setReloadFormProvisorio: any
    item: any
    itemDetalle?: boolean
    estadoDespacho:string
}

const ModalFormItem = ({ despachoID, id, tableDataItems, nroProvisorio, setReloadFormProvisorio, item, itemDetalle,estadoDespacho }: props) => {

    const [show, setShow] = useState<boolean>(false);
    const [cargando, updateCargando] = useState<boolean>(false);
    const [itemFormData, setItemFormData] = useState<Item>()
    const [subItemData, setSubItemData] = useState<SubItem[]>()
    const [ventajasData, setVentajasData] = useState([])
    const [liquidacionItem, setLiquidacionItem] = useState<LiquidacionItem>()
    const [tableInformacionComp, setTableInformacionComp] = useState<any[]>([])
    const [rowIndexState, setRowIndexState] = useState<any>(null);
    const [rowBackGroundColor, setRowBackGroundColor] = useState('')
    const [showModalModificarItems, setShowModalModificarItems] = useState<boolean>(false);
    const [showModalDCEXP, setShowModalDCEXP] = useState<boolean>(false);
    const [CCPLItemCero, setCCPLItemCero] = useState<any>()
    const [MCPLItemCero, setMCPLItemCero] = useState<any>()
    const [DCEXPValue, setDCEXPValue] = useState<string | number | string[] | undefined>('')
    const [DCEXPItemValue, setDCEXPItemValue] = useState<string | number | string[] | undefined>('')
    const [mensajeExitoInsertInfItemCero, setMensajeExitoInsertInfItemCero] = useState<string>('')
    const [tabActive, setTabActive] = useState<string>("infoAdic")
    const [reloadForm, setReloadForm] = useState(false);
    const [certificacionesDataTable, setCertificacionesDataTable] = useState<Certificacion[]>()
    const [showModalEditarSubItem, setShowModalEditarSubItem] = useState<number | null>(null);
    const [listaCodSeleccionado, setListaCodSeleccionado] = useState<OnValueChangeArgument | string>('')
    const [estadoMercaderiaSeleccionado, setEstadoMercaderiaSeleccionado] = useState<OnValueChangeArgument | string>('')
    const [origenProvSeleccionado, setOrigenProvSeleccionado] = useState<OnValueChangeArgument | string>('')
    const [procDestinoSeleccionado, setProcDestinoeleccionado] = useState<OnValueChangeArgument | string>('')
    const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({})
    const [tipoOperacionInsertItems, setTipoOperacionInsertItems] = useState<number | null>(null) //0 === Inf. Adicional - 1 === Ventajas - 2 === Lista - 3=== Estado Mercaderia - 4 === origenProv - 5=== procDestino
    const [ventajaCodigoSeleccionado, setVentajaCodigoSeleccionado] = useState<OnValueChangeArgument>('')
    const [showModalInsertarProrrateos, setShowModalInsertarProrrateos] = useState<boolean>(false)
    const [typeModalProrrateos, setTypeModalProrrateos] = useState<string>('')
    const [ajusteFOBValue, setAjusteFOBValue] = useState('')
    const [kgNetoTotalValue, setKGNetoTotalValue] = useState('')
    const [porcentajeCheck, setPorcentajeCheck] = useState(false)
    const [showModalSufijosGlobales, setCloseModalSufijosGlobales] = useState(false)
    const [sufijosGlobalesValueUno, setSufijosGlobalesValueUno] = useState<OnValueChangeArgument>()
    const [sufijosGlobalesValueDos, setSufijosGlobalesValueDos] = useState<OnValueChangeArgument>()

    let infComplementariaRef = useRef<HTMLInputElement>(null)
    let formRef = useRef<HTMLFormElement>(null)



    const handleCloseModalModificarItems = () => {
        setShowModalModificarItems(false)
        setMensajeExitoInsertInfItemCero('')
        setCCPLItemCero('')
        setMCPLItemCero('')
        setDCEXPItemValue('')
        setDCEXPValue('')
        setEstadoMercaderiaSeleccionado('')
        setListaCodSeleccionado('')
        setOrigenProvSeleccionado('')
        setProcDestinoeleccionado('')
    }
    const handleShowModalModificarItems = () => {
        setShowModalModificarItems(true)
    }

    const handleShowModalDCEXP = () => {
        setShowModalDCEXP(true)
    }

    const handleCloseModalDCEXP = () => {
        setShowModalDCEXP(false)
        setDCEXPItemValue('')
        setDCEXPValue('')
    }

    const handleClose = () => {
        setReloadForm(true)
        setReloadFormProvisorio(true)
        setMensajeExitoInsertInfItemCero('')
        setVentajaCodigoSeleccionado('')
        setShow(false)
        setDCEXPItemValue('')
        setDCEXPValue('')
    }
    const handleShow = () => setShow(true);
    let api = useAdvAPI();
    let apiSintia = useApi();

    const cargarDatosForm = async () => {
        updateCargando(true)
        try {
            let itemDetalleVista = await api.getObtenerItemDetalleVista(despachoID, id)
            setItemFormData(itemDetalleVista?.Items?.[0])
            setSubItemData(itemDetalleVista?.SBT)
            setVentajasData(itemDetalleVista?.SRG)
            setTableInformacionComp(itemDetalleVista?.CPL)
            setLiquidacionItem(itemDetalleVista?.LQA)
            updateCargando(false);
        } catch (error) {
            if (!apiSintia.isCancel(error)) {
                console.error('Error al cargar vistas', error);
            }
        }
    }

    async function getOptionsInformacionComplementaria() {
        let infComps = await apiSintia.getInformacionComplementaria();
        //infComps = infComps.filter((item: any) => !filterInfoComp.includes(item.Codigo));
        return convertirDatosGenericosAOption(infComps);
    }

    const insertCPL = async (dataCPL: InsertCPLBody) => {
        updateCargando(true)
        try {
            await api.insertCPL(dataCPL)
            cargarDatosForm()
            updateCargando(false)
        } catch (error) {
            updateCargando(false)
            console.log('error', error)
        }
    }

    const insertDatosGlobalesInfComp = async () => {
        if (tipoOperacionInsertItems === 0) {
            let data = {
                "CPL": {
                    "ID": 0,
                    "ICPL": nroProvisorio?.current,
                    "NART": null,
                    "CCPL": CCPLItemCero,
                    "ICPLDIF": "D",
                    "MCPL": MCPLItemCero,
                    "AutomatizadorMaria_DDTID": Number(despachoID)
                },
                "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
            }
            updateCargando(true)
            setCCPLItemCero('')
            setMCPLItemCero('')
            handleCloseModalModificarItems()
            try {
                let resp = await api.DatosGlobalesInfoComplementaria(data)
                updateCargando(false)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó la información adicional a los ITEMS seleccionados.')
                } else {
                    setMensajeExitoInsertInfItemCero('Error al insertar la información en los ITEMS seleccionados.')
                }

            } catch (error) {
                updateCargando(false);
                setMensajeExitoInsertInfItemCero('Error al insertar la información en los ITEMS seleccionados.')
            }
        }
        if (tipoOperacionInsertItems === 1) {
            let data =
            {
                "Ventaja": {
                    "ISRG": nroProvisorio?.current,
                    "CSRG": ventajaCodigoSeleccionado,
                    "DespachoId": Number(despachoID)
                },
                "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
            }
            updateCargando(true)
            setCCPLItemCero('')
            setMCPLItemCero('')
            handleCloseModalModificarItems()
            try {
                let resp = await api.DatosGlobalesVentajas(data)
                updateCargando(false)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó la ventaja a los ITEMS seleccionados.')
                } else {
                    setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
                }

            } catch (error) {
                updateCargando(false);
                setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
            }
        }
        if (tipoOperacionInsertItems === 2) {
            let data =
            {
                "Lista": {
                    "IDDT": nroProvisorio?.current,
                    "LISTA": listaCodSeleccionado === 'vaciar' ? '' : listaCodSeleccionado,
                    "DespachoId": Number(despachoID)
                },
                "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
            }
            updateCargando(true)
            setCCPLItemCero('')
            setMCPLItemCero('')
            handleCloseModalModificarItems()
            try {
                let resp = await api.DatosGlobalesArticulosLista(data)
                updateCargando(false)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó la lista seleccionada a los ITEMS seleccionados.')
                } else {
                    setMensajeExitoInsertInfItemCero('Error al insertar la lista seleccionada en los ITEMS seleccionados.')
                }

            } catch (error) {
                updateCargando(false);
                setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
            }
        }
        if (tipoOperacionInsertItems === 3) {
            let data =
            {
                "Mercaderia": {
                    "IDDT": nroProvisorio?.current,
                    "CARTUSO": estadoMercaderiaSeleccionado,
                    "DespachoId": Number(despachoID)
                },
                "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
            }
            updateCargando(true)
            setCCPLItemCero('')
            setMCPLItemCero('')
            handleCloseModalModificarItems()
            try {
                let resp = await api.DatosGlobalesArticulosMercaderia(data)
                updateCargando(false)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó la mercadería seleccionada a los ITEMS seleccionados.')
                } else {
                    setMensajeExitoInsertInfItemCero('Error al insertar la mercadería seleccionada en los ITEMS seleccionados.')
                }

            } catch (error) {
                updateCargando(false);
                setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
            }
        }
        if (tipoOperacionInsertItems === 4) {
            let data =
            {
                "Origen": {
                    "IDDT": nroProvisorio?.current,
                    "CARTPAYORI": origenProvSeleccionado,
                    "DespachoId": Number(despachoID)
                },
                "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
            }
            updateCargando(true)
            setCCPLItemCero('')
            setMCPLItemCero('')
            handleCloseModalModificarItems()
            try {
                let resp = await api.DatosGlobalesArticulosOrigen(data)
                updateCargando(false)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó el Origen/Provincia seleccionada a los ITEMS seleccionados.')
                } else {
                    setMensajeExitoInsertInfItemCero('Error al insertar el Origen/Provincia seleccionada en los ITEMS seleccionados.')
                }

            } catch (error) {
                updateCargando(false);
                setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
            }
        }
        if (tipoOperacionInsertItems === 5) {
            let data =
            {
                "Destino": {
                    "IDDT": nroProvisorio?.current,
                    "CARTPAYPRC": procDestinoSeleccionado,
                    "DespachoId": Number(despachoID)
                },
                "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
            }
            updateCargando(true)
            setCCPLItemCero('')
            setMCPLItemCero('')
            handleCloseModalModificarItems()
            try {
                let resp = await api.DatosGlobalesArticulosDestino(data)
                updateCargando(false)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó el destino seleccionado a los ITEMS seleccionados.')
                } else {
                    setMensajeExitoInsertInfItemCero('Error al insertar el destino seleccionado en los ITEMS seleccionados.')
                }

            } catch (error) {
                updateCargando(false);
                setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
            }
        }
        if (tipoOperacionInsertItems === 6) {

            updateCargando(true)
            setCCPLItemCero('')
            setMCPLItemCero('')
            handleCloseModalModificarItems()
            try {
                let resp = await api.UpdateSufijosGlobales({
                    "Sufijo": {
                        "DespachoId": Number(despachoID),
                        "Codigo": sufijosGlobalesValueUno,
                        "Valor": sufijosGlobalesValueDos
                    },
                    "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
                })
                updateCargando(false)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó el valor de Sufijo seleccionado a los ITEMS seleccionados.')
                } else {
                    setMensajeExitoInsertInfItemCero('Error al insertar el valor de Sufijo seleccionado en los ITEMS seleccionados.')
                }

            } catch (error) {
                updateCargando(false);
                setMensajeExitoInsertInfItemCero('Error al insertar valor de Sufijo en los ITEMS seleccionados.')
            }
        }

    }

    const insertarVentajaItem = async () => {
        let data =
        {
            "Ventaja": {
                "ISRG": nroProvisorio?.current,
                "CSRG": ventajaCodigoSeleccionado,
                "DespachoId": Number(despachoID)
            },
            "NArts": [id]
        }
        updateCargando(true)
        try {
            let resp = await api.DatosGlobalesVentajas(data)
            updateCargando(false)
            if (resp?.Exito) {
                setMensajeExitoInsertInfItemCero('Se insertó la ventaja a los ITEMS seleccionados.')
                cargarDatosForm()
            } else {
                setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
            }

        } catch (error) {
            updateCargando(false);
            setMensajeExitoInsertInfItemCero('Error al insertar la ventaja en los ITEMS seleccionados.')
        }
    }

    const insertDatosGlobalesCCM = async () => {
        updateCargando(true)
        setDCEXPItemValue('')
        setDCEXPValue('')
        handleCloseModalDCEXP()
        try {
            let resp = await api.DatosGlobalesCCM({
                DespachoId: despachoID,
                IDDT: nroProvisorio?.current,
                MCCM: DCEXPValue,
                NART: DCEXPItemValue
            })
            ObtenerCertificaciones(0)
            updateCargando(false)
            if (resp?.Exito) {
                setMensajeExitoInsertInfItemCero('Se insertó el valor a los ITEMS seleccionados.')
            } else {
                setMensajeExitoInsertInfItemCero('Error al insertar el en los ITEMS seleccionados.')
            }

        } catch (error) {
            updateCargando(false);
            setMensajeExitoInsertInfItemCero('Error al insertar la información en los ITEMS seleccionados.')
        }
    }

    const ObtenerCertificaciones = async (itemID: any) => {
        updateCargando(true)
        try {
            let obtenerCertificacionesResponse = await api.ObtenerCertificaciones(despachoID, itemID)
            setCertificacionesDataTable(obtenerCertificacionesResponse)
            updateCargando(false)
        } catch (error) {
            updateCargando(false)
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (show || reloadForm) {
            cargarDatosForm()
            getOptionsInformacionComplementaria()
            setMensajeExitoInsertInfItemCero('')
        }
        if (id === "0000") {
            ObtenerCertificaciones(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, reloadForm])

    useEffect(() => {
        if (mensajeExitoInsertInfItemCero?.length > 0) {
            setAjusteFOBValue('')
            setKGNetoTotalValue('')
        }
    }, [mensajeExitoInsertInfItemCero])

    function formatNumber(num: any, decimales?: number) {
        if (num === 0) {
            return '-'
        }
        let formattedNum = new Intl.NumberFormat('es-ES', {
            minimumFractionDigits: decimales ?? 2,
            maximumFractionDigits: decimales ?? 2
        }).format(num);

        return formattedNum;
    }

    const handleCloseModalProrrateos = () => {
        setAjusteFOBValue('')
        setPorcentajeCheck(false)
        setShowModalInsertarProrrateos(false)
    }


    const convertirAParrafo = (inputString: string) => {
        // Reemplazar cada |#| por un salto de línea HTML
        if (inputString?.length > 0) {
            const contenido = inputString?.replace(/\|#\|/g, '<br>')

            // Crear una etiqueta <p> con el contenido resultante
            return `<p>${contenido}</p>`
        } else {
            return `<p>-</p>`
        }
    }

    return (
        <>
            {itemDetalle ? <tr onClick={handleShow} className="cursor-pointer " key={item?.ID}>
                <td>{id}</td>
                <td>{item?.IESPNCE !== "-" ? item?.IESPNCE : item?.IESPNCE}</td>
                <td>{item?.LISTA !== "-" ? item?.LISTA : item?.LISTA}</td>
                <td>{item?.CARTPAYORI !== "-" ? item?.CARTPAYORI : item?.CARTPAYORI}</td>
                <td>{item?.CARTPAYPRC !== "-" ? item?.CARTPAYPRC : item?.CARTPAYPRC}</td>
                <td dangerouslySetInnerHTML={{ __html: convertirAParrafo(item?.Ventajas) }}></td>
                <td dangerouslySetInnerHTML={{ __html: convertirAParrafo(item?.InfoAdicional) }}></td>
                <td>{item?.Documentos}</td>
            </tr> :
                <tr onClick={handleShow} className="cursor-pointer" key={item?.ID}>
                    <td>{id}</td>
                    <td>{item?.IESPNCE !== "-" ? item?.IESPNCE : item?.IESPNCE}</td>
                    <td>{item?.LISTA !== "-" ? item?.LISTA : item?.LISTA}</td>
                    <td>{item?.CARTPAYORI !== "-" ? item?.CARTPAYORI : item?.CARTPAYORI}</td>
                    <td>{item?.CARTPAYPRC !== "-" ? item?.CARTPAYPRC : item?.CARTPAYPRC}</td>
                    <td style={{ textAlign: 'right' }}>{item?.CARTUNTDCL !== "-" ? formatNumber(item?.CARTUNTDCL) : "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{item?.QARTUNTDCL !== 0 ? formatNumber(item?.QARTUNTDCL) : "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{item?.CARTUNTEST !== "-" ? formatNumber(item?.CARTUNTEST) : "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{item?.QARTUNTEST !== 0 ? formatNumber(item?.QARTUNTEST) : "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{item?.QARTKGRNET !== 0 ? formatNumber(item?.QARTKGRNET) : "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{formatNumber(item?.MARTFOB ?? "0,00")}</td>
                    <td style={{ textAlign: 'right' }}>{item?.MARTFLE !== 0 ? formatNumber(item?.MARTFLE) : "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{item?.MARTASS !== 0 ? formatNumber(item?.MARTASS) :  "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{item?.MARTAJUINC !== 0 ? formatNumber(item?.MARTAJUINC) :  "0,00"}</td>
                    <td style={{ textAlign: 'right' }}>{item?.MARTAJUDED ??  "0,00"}</td>
                </tr>}
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Item - Modificar - Nro. Item {id}</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando && <Modal.Body>
                        {id === "0000" ? <Container>
                            <Formik
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        alert(JSON.stringify(values, null, 2));
                                        setSubmitting(false);
                                    }, 400);
                                }}
                                initialValues={{
                                    lista: '',
                                    DCEXPValue: '',
                                    DCEXPItemValue: ''
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit} ref={formRef}>
                                        <div>
                                            <Form.Row>
                                                <Form.Group as={Col} md="3" lg="5">
                                                    <MySelect name="Lista" label="Lista" options={() => apiSintia.getLista()
                                                        .then(convertirDatosGenericosAOption)
                                                        .then(options => [
                                                            ...options,
                                                            { value: 'vaciar', label: 'Vaciar' }
                                                        ])
                                                    }
                                                        getOptionLabel={optionLabelConCodigo} onValueChange={(e) => setListaCodSeleccionado(e)} />
                                                    {listaCodSeleccionado === 'vaciar' && <p style={{ color: 'red' }}>AVISO: Si inserta Lista Vaciar confirma Vaciar el campo LISTA en los ITEMS seleccionados</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" lg="2">
                                                    <Button style={{ marginTop: 25 }} disabled={listaCodSeleccionado?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} variant="primary" onClick={() => {
                                                        setTipoOperacionInsertItems(2)
                                                        handleShowModalModificarItems()
                                                    }} >
                                                        Establecer
                                                    </Button>
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="3">
                                                    <MySelect name="CodigoPaisOrigen" label="Origen/Provincia" options={() => apiSintia.getPaises().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo} onValueChange={(e) => setOrigenProvSeleccionado(e)} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" lg="2">
                                                    <Button style={{ marginTop: 25 }} disabled={origenProvSeleccionado?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} variant="primary" onClick={() => {
                                                        setTipoOperacionInsertItems(4)
                                                        handleShowModalModificarItems()
                                                    }}>Establecer</Button>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="3" lg="5">
                                                    <MySelect name="CodigoEstadoMercaderia" label="Estado Mercaderia" options={() => apiSintia.getEstadosMercaderia().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo} onValueChange={(e) => setEstadoMercaderiaSeleccionado(e)} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" lg="2">
                                                    <Button style={{ marginTop: 25 }} disabled={estadoMercaderiaSeleccionado?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} variant="primary" onClick={() => {
                                                        setTipoOperacionInsertItems(3)
                                                        handleShowModalModificarItems()
                                                    }} >Establecer</Button>
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="3">
                                                    <MySelect name="CodProcDestino" label="Proc/Destino." options={() => apiSintia.getPaises().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo} onValueChange={(e) => setProcDestinoeleccionado(e)} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" lg="2">
                                                    <Button style={{ marginTop: 25 }} disabled={procDestinoSeleccionado?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} variant="primary" onClick={() => {
                                                        setTipoOperacionInsertItems(5)
                                                        handleShowModalModificarItems()
                                                    }}>Establecer</Button>
                                                </Form.Group>
                                            </Form.Row>
                                        </div>
                                        <Card>
                                            <Tabs
                                                defaultActiveKey={tabActive}
                                                onSelect={(e) => setTabActive(e!)}
                                                className="mb-3"
                                                variant="pills"
                                            >
                                                <Tab eventKey="infoAdic" title="Inf. Adicional" >
                                                    <Card.Body>
                                                        {mensajeExitoInsertInfItemCero?.length > 0 && <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -20 }}><Alert style={{ fontSize: 15, fontWeight: 'bold' }} variant='primary'> <FontAwesomeIcon icon={faInfoCircle} />{mensajeExitoInsertInfItemCero}</Alert ></Container>}
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" lg="3">
                                                                <MySelect name="CCPL" label="Código" onValueChange={(e) => setCCPLItemCero(e)}
                                                                    options={getOptionsInformacionComplementaria}
                                                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="5" lg="3" className='col-lg-3' >
                                                                <Form.Label>Valor:</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    name="MCPL"
                                                                    value={MCPLItemCero}
                                                                    onChange={(e) => setMCPLItemCero(e?.target?.value)}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="6" className='col-lg-3'>
                                                                <Button variant='success' className='buttonInsertar' style={{ width: 120 }} disabled={MCPLItemCero?.length === 0 || CCPLItemCero?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado}
                                                                    onClick={() => {
                                                                        setTipoOperacionInsertItems(0)
                                                                        handleShowModalModificarItems()
                                                                    }}
                                                                >Insertar</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="ventajas" title="Ventajas" >
                                                    <Card.Body>
                                                        {mensajeExitoInsertInfItemCero?.length > 0 && <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -20 }}><Alert style={{ fontSize: 15, fontWeight: 'bold' }} variant='primary'> <FontAwesomeIcon icon={faInfoCircle} />{mensajeExitoInsertInfItemCero}</Alert ></Container>}
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" lg="6">
                                                                <MySelect name="Codigo" label="Codigo Ventaja" onValueChange={(e) => setVentajaCodigoSeleccionado(e)}
                                                                    options={() => apiSintia.getVentajas().then(convertirDatosGenericosAOption)}
                                                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="6" className='col-lg-2'>
                                                                <Button variant='success' disabled={ventajaCodigoSeleccionado?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} className='buttonInsertarVentaja' style={{ width: 120, marginTop: 27 }} onClick={() => {
                                                                    setTipoOperacionInsertItems(1)
                                                                    handleShowModalModificarItems()
                                                                }}>Insertar</Button>
                                                            </Form.Group>

                                                        </Form.Row>

                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="documentos" disabled title="Documentos" >
                                                </Tab>
                                                <Tab eventKey="subItem" disabled title="Subitems" >
                                                    <Card.Body>

                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="DCEXP" title="DCEXP / CPPAC" >
                                                    <Card.Body>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" lg="5">
                                                                <Form.Label>Valor</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 38 }}
                                                                    type="text"
                                                                    name="DCEXPValue"
                                                                    value={DCEXPValue}
                                                                    onChange={(e) => setDCEXPValue(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="5" lg="4" className='col-lg-3' >
                                                                <Form.Label>Item DCEXP (opcional)</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 38 }}
                                                                    type="text"
                                                                    name="DCEXPItemValue"
                                                                    value={DCEXPItemValue}
                                                                    onChange={(e) => setDCEXPItemValue(e.target.value)}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="6" className='col-lg-3'>
                                                                <Button variant='success' className='buttonInsertar' style={{ width: 120 }} disabled={DCEXPValue!?.toString()?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado}
                                                                    onClick={handleShowModalDCEXP}
                                                                >Insertar</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            {certificacionesDataTable!?.length > 0 && <div style={{ height: 300, width: '100%', overflowY: 'scroll' }}>
                                                                <Table striped bordered hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>ITEM</th>
                                                                            <th>Código</th>
                                                                            <th>Valor</th>
                                                                            <th>Item DCEXP</th>
                                                                            <th>Acciones</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {certificacionesDataTable?.map((row: any, index: number) => (
                                                                            <>
                                                                                <tr>
                                                                                    <td>{row?.NART}</td>
                                                                                    <td>{row?.CCCM}</td>
                                                                                    <td>{row?.MCCM}</td>
                                                                                    <td>{row?.CCCMNART}</td>
                                                                                    <ModalEliminarDCEXP ID={row?.ID}
                                                                                        triggerSubmit={() => ObtenerCertificaciones(0)} nroItem={row?.CCCMNART} />
                                                                                </tr>
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>}
                                                        </Form.Row>
                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="prorrateos" title="Prorrateos" >
                                                    <Card.Body>
                                                        <Form.Row>
                                                            {mensajeExitoInsertInfItemCero?.length > 0 && <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -20 }}><Alert style={{ fontSize: 15, fontWeight: 'bold' }} variant='primary'> <FontAwesomeIcon icon={faInfoCircle} />{mensajeExitoInsertInfItemCero}</Alert ></Container>}
                                                            <Form.Group as={Col} md="6" lg="3">
                                                                <Form.Label>Ajuste a FOB</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 38 }}
                                                                    type="text"
                                                                    name="CARTCODEAN"
                                                                    value={ajusteFOBValue}
                                                                    onChange={(e) => {
                                                                        let value = e?.target?.value;
                                                                        if (/^[-%]?\d*\.?\d*%?$/.test(value) || value === "") {

                                                                            setAjusteFOBValue(value);
                                                                        }
                                                                    }}
                                                                />
                                                                <Form.Group controlId="checkPorcentaje">
                                                                    <Form.Check type="checkbox" label="Porcentaje" checked={porcentajeCheck} onChange={(e) => setPorcentajeCheck(e?.target?.checked)} />
                                                                </Form.Group>                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" className='col-lg-3'>
                                                                <Button style={{ marginTop: 28 }} disabled={ajusteFOBValue?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} variant='success' onClick={() => {
                                                                    setTypeModalProrrateos('FOB')
                                                                    setShowModalInsertarProrrateos(true)
                                                                }}>Recalcular FOB</Button>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" lg="3">
                                                                <Form.Label>KG Neto Total</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 38 }}
                                                                    type="text"
                                                                    name="CARTCODEAN"
                                                                    value={kgNetoTotalValue}
                                                                    onChange={(e) => {
                                                                        let value = e?.target?.value;
                                                                        if (/^\d*\.?\d+$/.test(value) || value === "") {
                                                                            setKGNetoTotalValue(value)
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" className='col-lg-3'>
                                                                <Button variant='success' disabled={kgNetoTotalValue?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} style={{ marginTop: 28 }} onClick={() => {
                                                                    setTypeModalProrrateos('KG')
                                                                    setShowModalInsertarProrrateos(true)
                                                                }} >Prorratear</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" lg="4">

                                                                <ModalEditarSufijos setCloseModalSufijosGlobales={setCloseModalSufijosGlobales} setSufijosGlobalesValueUno={setSufijosGlobalesValueUno} setSufijosGlobalesValueDos={setSufijosGlobalesValueDos} handleShowModalModificarItems={() => {
                                                                    setTipoOperacionInsertItems(6)
                                                                    setShowModalModificarItems(true)
                                                                }
                                                                } estadoDespacho={estadoDespacho} showModalSufijosGlobales={showModalSufijosGlobales} setReloadForm={setReloadForm} />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="liquidacion" disabled title="Liquidación" >
                                                </Tab>

                                            </Tabs>

                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        </Container> : <Container>
                            <Formik
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        alert(JSON.stringify(values, null, 2));
                                        setSubmitting(false);
                                    }, 400);
                                }}
                                initialValues={{
                                    NARTEXT: itemFormData?.NARTEXT,
                                    CARTTYP: itemFormData?.CARTTYP,
                                    itemAsociado: '-',
                                    CARTCODEAN: itemFormData?.CARTCODEAN,
                                    estado: '-',
                                    IESPNCE: itemFormData?.IESPNCE,
                                    IESPNALGAT: itemFormData?.IESPNALGAT,
                                    LISTA: itemFormData?.LISTA,
                                    CARTUSO: itemFormData?.CARTUSO,
                                    CertTipClas: '-',
                                    CARTPAYORI: itemFormData?.CARTPAYORI,
                                    CARTPAGREG: itemFormData?.CARTPAGREG,
                                    CARTCALDST: itemFormData?.CARTCALDST,
                                    CARTPAYPRC: itemFormData?.CARTPAYPRC,
                                    MARTFOB: itemFormData?.MARTFOB,
                                    MARTASS: itemFormData?.MARTASS,
                                    MARTFLE: itemFormData?.MARTFLE,
                                    MARTCOEFIC: itemFormData?.MARTCOEFIC,
                                    MARTAJUINC: itemFormData?.MARTAJUINC,
                                    MARTAJUDED: itemFormData?.MARTAJUDED,
                                    MARTUNITAR: itemFormData?.MARTUNITAR,
                                    MARTINSTMP: itemFormData?.MARTINSTMP,
                                    MARTINSCON: itemFormData?.MARTINSCON,
                                    precioOficial: '-',
                                    CARTUNTDCL: itemFormData?.CARTUNTDCL,
                                    QARTUNTDCL: itemFormData?.QARTUNTDCL,
                                    CARTUNTEST: itemFormData?.CARTUNTEST,
                                    QARTUNTEST: itemFormData?.QARTUNTEST,
                                    QARTKGRNET: itemFormData?.QARTKGRNET,
                                    QSBTUNTDCL: '-',
                                    MSBTFOB: '-',
                                    QSBTUNTEST: '-',
                                    CSBTSVL: '-',
                                    CCPL: '-',
                                    LDVDREFDOC: '-',
                                    cEspecifica: '-',
                                    MCPL: '',
                                    NART: liquidacionItem?.NART,
                                    CLQATAX: liquidacionItem?.CLQATAX,
                                    CLQATYP: liquidacionItem?.CLQATYP,
                                    CLQAMOTGAR: liquidacionItem?.CLQAMOTGAR,
                                    MLQA: liquidacionItem?.MLQA,
                                    MLQABAS: liquidacionItem?.MLQABAS,
                                    QLQACOEFIC: liquidacionItem?.QLQACOEFIC,
                                    DCEXPValue: '',
                                    DCEXPItemValue: ''
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div>
                                            <Form.Row>
                                                <Form.Group as={Col} md="3" lg="2">
                                                    <Form.Label>Nro. Item</Form.Label>
                                                    <Form.Control
                                                        style={{ height: 38 }}
                                                        type="text"
                                                        name="NARTEXT"
                                                        disabled
                                                        value={values.NARTEXT!}
                                                        onChange={handleChange}
                                                    />

                                                </Form.Group>


                                                <Form.Group as={Col} md="2" lg="2">
                                                    <MySelect
                                                        name="CARTTYP" label='Tipo'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.CARTTYP!,
                                                            label: itemFormData?.CARTTYP!,
                                                        }]}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="2">

                                                    <Form.Label>Item asociado</Form.Label>
                                                    <Form.Control
                                                        style={{ height: 38 }}
                                                        type="text"
                                                        name="itemAsociado"
                                                        disabled
                                                        value={'-'}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" lg="2">
                                                    <Form.Label>Código</Form.Label>
                                                    <Form.Control
                                                        style={{ height: 38 }}
                                                        type="text"
                                                        name="CARTCODEAN"
                                                        disabled
                                                        value={values.CARTCODEAN!}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" lg="2">
                                                    <Form.Label>Estado</Form.Label>
                                                    <Form.Control
                                                        style={{ height: 38 }}
                                                        type="text"
                                                        name="estado"
                                                        disabled
                                                        value={values.estado}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="2">
                                                    <MySelect
                                                        name="CARTUSO" label='Est.Mercad.'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.CARTUSO!,
                                                            label: itemFormData?.CARTUSO!,
                                                        }]}

                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="8" lg="5">
                                                    <MySelect
                                                        name="IESPNCE" label='Posición/Ara./DC'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.IESPNCE!,
                                                            label: itemFormData?.IESPNCE!,
                                                        }]} getOptionLabel={(value) => `${value.value} - ${value.label}`}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>

                                                <Form.Group as={Col} md="5" lg="2" style={{ marginLeft: 5 }}>
                                                    <Form.Label>Naladisa-Gatt</Form.Label>
                                                    <Form.Control
                                                        style={{ height: 38 }}
                                                        type="text"
                                                        name="IESPNALGAT"
                                                        disabled
                                                        value={values.IESPNALGAT!}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="8" lg="2">
                                                    <MySelect
                                                        name="LISTA" label='Lista'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.LISTA!,
                                                            label: itemFormData?.LISTA!,
                                                        }]} getOptionLabel={(value) => `${value.value} - ${value.label}`}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="2">
                                                    <MySelect
                                                        name="CARTPAYORI" label='Origen Pais/Prov'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.CARTPAYORI!,
                                                            label: itemFormData?.CARTPAYORI!,
                                                        }]}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="3" lg="3">
                                                    <MySelect
                                                        name="CertTipClas" label='Cert. tip y Clas'
                                                        isDisabled
                                                        options={[{
                                                            value: '-',
                                                            label: '-',
                                                        }]}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="2">
                                                    <MySelect
                                                        name="CARTPAGREG" label='Pago de Cánones.'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.CARTPAGREG!,
                                                            label: itemFormData?.CARTPAGREG!,
                                                        }]}

                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="3">
                                                    <MySelect
                                                        name="CARTCALDST" label='Cal Dist standard'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.CARTCALDST!,
                                                            label: itemFormData?.CARTCALDST!,
                                                        }]}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" lg="3">
                                                    <MySelect
                                                        name="CARTPAYPRC" label='Pais Proc/Destino'
                                                        isDisabled
                                                        options={[{
                                                            value: itemFormData?.CARTPAYPRC!,
                                                            label: itemFormData?.CARTPAYPRC!,
                                                        }]}
                                                        onValueChange={(e) => e} />
                                                </Form.Group>
                                            </Form.Row>

                                        </div>
                                        <Card>
                                            <Tabs
                                                onSelect={(e) => setTabActive(e!)}
                                                defaultActiveKey={tabActive}
                                                className="mb-3"
                                                variant="pills"
                                            >
                                                <Tab eventKey="montos" title="Montos">
                                                    <Card.Body>
                                                        <Form.Row style={{ marginTop: -25 }}>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Fob total divisa</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTFOB"
                                                                    disabled
                                                                    value={values.MARTFOB}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Monto seguro</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTASS"
                                                                    disabled
                                                                    value={values.MARTASS}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Monto flete</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTFLE"
                                                                    disabled
                                                                    value={values.MARTFLE}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Coeficiente</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTCOEFIC"
                                                                    disabled
                                                                    value={values.MARTCOEFIC}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Ajuste incluir.</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTAJUINC"
                                                                    disabled
                                                                    value={values.MARTAJUINC}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Ajuste deducir</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTAJUDED"
                                                                    disabled
                                                                    value={values.MARTAJUDED}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Fob unitario</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTUNITAR"
                                                                    disabled
                                                                    value={values.MARTUNITAR}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Ins. import temp.</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTINSTMP"
                                                                    disabled
                                                                    value={values.MARTINSTMP}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label>Ins. import cons.</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="MARTINSCON"
                                                                    disabled
                                                                    value={values.MARTINSCON}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="2">
                                                                <MySelect
                                                                    name="precioOficial" isDisabled label='Precio Oficial'
                                                                    options={[{
                                                                        value: '-',
                                                                        label: '-'
                                                                    }]} onValueChange={(e) => e} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 26, textAlign: 'right' }}
                                                                    type="text"
                                                                    disabled
                                                                    name="precioOficial"
                                                                    value={values.precioOficial}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <MySelect
                                                                    name="CARTUNTDCL" isDisabled label='Unidad Decl.'
                                                                    options={[{ value: values?.CARTUNTDCL!, label: values?.CARTUNTDCL! }, {
                                                                        value: '-',
                                                                        label: '-'
                                                                    }]} onValueChange={(e) => e} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="2">
                                                                <Form.Label>Cantidad Declarada</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="QARTUNTDCL"
                                                                    disabled
                                                                    value={values.QARTUNTDCL}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="2">
                                                                <Form.Label>Unidad Estadistica</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="CARTUNTEST"
                                                                    disabled
                                                                    value={values.CARTUNTEST}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="2">
                                                                <Form.Label>Cantidad Estadistica</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="QARTUNTEST"
                                                                    disabled
                                                                    value={values.QARTUNTEST}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="2" lg="2">
                                                                <Form.Label style={{ marginTop: 18 }}>Total Kilo Neto</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, textAlign: 'right' }}
                                                                    type="text"
                                                                    name="QARTKGRNET"
                                                                    disabled
                                                                    value={values.QARTKGRNET}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                        {/* <Form.Row>
                                                            <Form.Group as={Col} md="3" lg="2">
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="3">
                                                                <MySelect
                                                                    name="CARTUNTDCL" isDisabled label='Unidad Declarada'
                                                                    options={[{
                                                                        value: '-',
                                                                        label: '-'
                                                                    }]} onValueChange={(e) => e} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} lg="2">
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="4">
                                                                <Form.Label>Cantidad Declarada</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    name="QARTUNTDCL"
                                                                    disabled
                                                                    value={values.QARTUNTDCL}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" lg="2">
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="3">
                                                                <Form.Label>Unidad Estadistica</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    name="CARTUNTEST"
                                                                    disabled
                                                                    value={values.CARTUNTEST}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} lg="2">
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="4">
                                                                <Form.Label>Cantidad Estadistica</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    name="QARTUNTEST"
                                                                    disabled
                                                                    value={values.QARTUNTEST}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" lg="2">
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="3">
                                                      
                                                            </Form.Group>
                                                            <Form.Group as={Col} lg="2">
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" lg="4">
                                                                <Form.Label>Total Kilo Neto</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    name="QARTKGRNET"
                                                                    disabled
                                                                    value={values.QARTKGRNET}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row> */}
                                                    </Card.Body>
                                                </Tab>

                                                <Tab eventKey="infoAdic" title="Información Adicional" >
                                                    <Card.Body>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" lg="6">
                                                                <MySelect name="CCPL" label="Código" onValueChange={(e) => e}
                                                                    options={getOptionsInformacionComplementaria}
                                                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="5" lg="3" className='col-lg-3' >
                                                                <Form.Label>Valor:</Form.Label>
                                                                <Form.Control
                                                                    style={{ height: 37, marginTop: 0 }}
                                                                    type="text"
                                                                    autoFocus
                                                                    ref={infComplementariaRef}
                                                                    name="MCPL"
                                                                    value={values.MCPL}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="6" className='col-lg-3'>
                                                                <Button variant='success' className='buttonInsertar' style={{ width: 120 }} disabled={values?.MCPL?.length === 0 || values?.CCPL?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado}  onClick={() => {
                                                                    insertCPL({
                                                                        ICPL: nroProvisorio?.current!,
                                                                        NART: id,
                                                                        CCPL: values?.CCPL,
                                                                        ICPLDIF: "D",
                                                                        MCPL: values?.MCPL,
                                                                        AutomatizadorMaria_DDTID: despachoID
                                                                    })
                                                                }}>Insertar</Button>
                                                            </Form.Group>

                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Código</th>
                                                                        <th>Valor</th>
                                                                        <th>Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {tableInformacionComp.map((row: any, index: number) => (
                                                                        <>
                                                                            <tr onClick={() => {
                                                                                setRowIndexState(row?.ID);
                                                                                setRowBackGroundColor('#138496');
                                                                            }} style={{ backgroundColor: index === rowIndexState ? rowBackGroundColor : '' }}>
                                                                                <td>{row.CCPL}</td>
                                                                                <td>{row.MCPL}</td>
                                                                                <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                                                    <ModalEditarInfComp id={row?.ID} AutomatizadorMaria_DDTID={row?.AutomatizadorMaria_DDTID} ICPL={row?.ICPL} ICPLDIF={row?.ICPLDIF} NART={row?.NART} CCPL={row?.CCPL} MCPL={row?.MCPL} setReloadForm={setReloadForm} />
                                                                                    <ModalEliminarFacturasInfCompVentajas ID={row?.ID} typeModal={0}
                                                                                        triggerSubmit={cargarDatosForm} nroInfComp={row?.CCPL} />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </tbody>


                                                            </Table>
                                                        </Form.Row>
                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="ventajas" title="Ventajas"  >
                                                    <Card.Body>
                                                        {mensajeExitoInsertInfItemCero?.length > 0 && <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -20 }}><Alert style={{ fontSize: 15, fontWeight: 'bold' }} variant='primary'> <FontAwesomeIcon icon={faInfoCircle} />{mensajeExitoInsertInfItemCero}</Alert ></Container>}
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" lg="6">
                                                                <MySelect name="Codigo" label="Codigo Ventaja" onValueChange={(e) => setVentajaCodigoSeleccionado(e)}
                                                                    options={() => apiSintia.getVentajas().then(convertirDatosGenericosAOption)}
                                                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" lg="6">
                                                                <Button variant='success' disabled={ventajaCodigoSeleccionado?.length === 0 || estadoDespacho === EstadoDespacho.Oficializado} className='buttonInsertarVentajaItem' style={{ width: 120, marginTop: 27 }} onClick={() => insertarVentajaItem()}>Insertar</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Código</th>
                                                                        <th>Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {ventajasData?.map((row: any, index: number) => (
                                                                        <>
                                                                            <tr onClick={() => {
                                                                                setRowIndexState(index);
                                                                                setRowBackGroundColor('#138496')

                                                                            }} style={{ backgroundColor: index === rowIndexState ? rowBackGroundColor : '' }}>
                                                                                <td>{row?.CSRG}</td>
                                                                                <td>
                                                                                    <ModalEliminarFacturasInfCompVentajas ID={row?.ID} typeModal={2}
                                                                                        triggerSubmit={cargarDatosForm} ventajaDesc={row?.CSRG} />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </tbody>


                                                            </Table>
                                                        </Form.Row>
                                                    </Card.Body>                                                </Tab>
                                                <Tab eventKey="cancelaciones" disabled title="Cancelaciones" >
                                                </Tab>
                                                <Tab eventKey="subItem" title="Subitem" >
                                                    <Table striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th>Nro.Subitem</th>
                                                                <th>Unitario</th>
                                                                <th>Cantidad</th>
                                                                <th>FOB</th>
                                                                <th>Estadistica</th>
                                                                <th>Articulo</th>
                                                                <th>Sufijos</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {subItemData?.map((row: any, index: number) => (
                                                                <>
                                                                    <tr >
                                                                        <td>{row?.NART}</td>
                                                                        <td>{row?.MSBTUNITAR}</td>
                                                                        <td onClick={() => setShowModalEditarSubItem(index)}>{row?.QSBTUNTDCL}</td>
                                                                        <td>{row?.MSBTFOB}</td>
                                                                        <td>{row?.QSBTUNTEST}</td>
                                                                        <td>{row?.IEXT}</td>
                                                                        <td><ModalEditarSubItemSufijos estadoDespacho={estadoDespacho} IDDT={nroProvisorio?.current!} ISBT={row?.ISBT} NART={row?.NART} setReloadForm={setReloadForm} sufijosData={row?.CSBTSVL} despachoID={despachoID} id={row?.ID} /></td>
                                                                        <td style={{ display: 'none' }}>
                                                                            <ModalEditarSubItem setShowModalEditarSubItem={setShowModalEditarSubItem} handleShow={showModalEditarSubItem === index}
                                                                                cantidadDeclarada={row?.QSBTUNTDCL} cantidadEstadistica={row?.QSBTUNTEST} fobTotal={row?.MSBTFOB} itemNro={itemFormData?.NARTEXT!} subItemNro={row?.NART} unitario='' setReloadForm={setReloadForm} />
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Tab>
                                                <Tab eventKey="LiqItem" title="Liquidación Item" >
                                                    <Card.Body>
                                                        <Table style={{
                                                            'fontSize': '0.9rem',
                                                            'width': '100%',
                                                        }}
                                                            className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">                                                            <thead>
                                                                <tr>
                                                                    <th>ITEM</th>
                                                                    <th>Concepto</th>
                                                                    <th>Tipo</th>
                                                                    <th>Motivo</th>
                                                                    <th>Total</th>
                                                                    <th>Base Imp.</th>
                                                                    <th>Coeficiente</th>


                                                                </tr>
                                                            </thead>
                                                            {liquidacionItem && Object?.entries(liquidacionItem)?.map(([key, articulo]: [string, any], index: number) => (
                                                                <tr className="cursor-pointer" key={index}>
                                                                    <td>{articulo?.NART}</td>
                                                                    <td>{articulo?.CLQATAX}</td>
                                                                    <td>{articulo?.CLQATYP}</td>
                                                                    <td>{articulo?.CLQAMOTGAR}</td>
                                                                    <td>{articulo?.MLQA}</td>
                                                                    <td>{articulo?.MLQABAS}</td>
                                                                    <td>{articulo?.QLQACOEFIC}</td>
                                                                </tr>
                                                            ))}

                                                            <tbody>

                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Tab>
                                                <Tab eventKey="infCat" disabled title="Inf. Catalogada" >
                                                </Tab>
                                                <Tab eventKey="certTip" disabled title="Cert. Tip y Clas." >
                                                </Tab>
                                                <Tab eventKey="insumosComp" disabled title="Insumos Comprados en Plaza" >
                                                </Tab>
                                            </Tabs>

                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        </Container>}

                    </Modal.Body>}
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </BlockUi>
            </Modal>

            <Modal show={showModalDCEXP} onHide={handleCloseModalDCEXP} backdrop="static"
                centered
                keyboard={false}
                style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                <BlockUi blocking={cargando}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ display: 'flex', alignItems: 'flex-start', maxWidth: 400 }}>
                            <FontAwesomeIcon style={{ marginRight: 10, fontSize: 50, color: '#383be8' }} icon={faQuestionCircle} />
                            <div style={{ flexGrow: 1 }}>
                                <p style={{ fontSize: 15, fontStyle: 'italic' }}>¿Desea establecer el CCPAC {DCEXPValue} en los items?</p>
                                <div style={{ marginTop: 5 }}>
                                    <p>Si selecciona:</p>
                                    <p><b>[Si]</b> Se establecerá este valor en los items, se perderá los valores existentes</p>
                                    <p><b>[No]</b> Volver atras</p>
                                </div>
                            </div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" style={{ width: 120 }} onClick={() => insertDatosGlobalesCCM()}>
                            Si
                        </Button>
                        <Button variant="secondary" style={{ width: 120 }} onClick={handleCloseModalDCEXP}>
                            No
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>
            <ModalSelectMultipleItems cargando={cargando} handleCloseModalModificarItems={handleCloseModalModificarItems} showModalModificarItems={showModalModificarItems} tableDataItems={tableDataItems!} insertDatosGlobalesInfComp={insertDatosGlobalesInfComp} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
            <ModalInsertarProrrateos showModalInsertarProrrateos={showModalInsertarProrrateos} despachoID={Number(despachoID)} handleCloseModalProrrateos={handleCloseModalProrrateos} typeModal={typeModalProrrateos} porcentajeCheck={porcentajeCheck} valorFOB={ajusteFOBValue} kgNetoTotalValue={kgNetoTotalValue} IDDT={nroProvisorio?.current!} setMensajeExitoInsertInfItemCero={setMensajeExitoInsertInfItemCero} />
        </>
    )
}

export default ModalFormItem