import { SesionPerdidaError } from 'App';
import Axios, { AxiosRequestConfig } from 'axios';
import RateLimit from 'RateLimit';
import { tokenStorageADV } from '../../TokenStorage';
import { DateTime } from 'luxon';
import { storageFactory } from 'storage-factory';
import "../../modelos/Advanced";
import { BodyDatosGlobalesInfComp, bodyListarArchivos, DatosGloablesVentajas, InsertCPLBody, InsertDVDBody, ObtenerArticulosVistaResponse, ObtenerArticuosLiqVistaResponse, ObtenerDespachoCaratulaResponse, ObtenerDespachoFiltrosRequest, ObtenerDespachoFiltrosResponse, ObtenerEstadoArchivos, ObtenerItemVistaResponse, ObtenerUsuariosAFIPResponse, Perfiles, ResponseListarArchivos, SubirArchivos, UpdateCPLBody, UpdateDespachoCaratulaBody, UpdateDVDBody } from '../../modelos/Advanced';
import { useCancel } from 'SintiaHooks';
//import { ArchivoASubir, ArchivoBinario, bodyListarArchivos, ListArchivos, ObtenerArticulosVistaResponse, ObtenerArticuosLiqVistaResponse,ObtenerDespachoCaratulaResponse, ObtenerDespachoFiltrosRequest, ObtenerDespachoFiltrosResponse, ObtenerEstadoArchivos, ObtenerItemVistaResponse, ResponseListarArchivos, SubirArchivos, UpdateDespachoCaratulaBody } from '../../modelos/Advanced';

//import { randomFillSync } from 'crypto';

function getUrlApiAdv() {
  if (process.env.NODE_ENV === 'development') {
    //return 'http://200.85.188.24:8084/'
    //return 'http://192.168.2.91:8084/';
    //return 'https://test.apiadv.sintia.com.ar/'
    
    return 'https://apiadv.sintia.com.ar/';

  } else if (process.env.REACT_APP_BUILD === 'dev') {
    //return 'https://test.apiadv.sintia.com.ar/';
    return 'https://apiadv.sintia.com.ar/';    
  } else {
    return 'https://apiadv.sintia.com.ar/';
  }
}

//const urlAdvanced = 'https://apiadv.sintia.com.ar/';
const urlAdvanced = getUrlApiAdv();

const rateLimit = new RateLimit(7);

const advClient = Axios.create({
  baseURL: urlAdvanced
});

const storage = storageFactory(() => localStorage);

advClient.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    'Authorization': 'Bearer ' + tokenStorageADV.getAccessToken()
  };
  return rateLimit.delayConfig(config) as Promise<any>;
}, error => error);

//Se crea función para setear token en cada petición para api.postmaria
const urlAdvancedApiPostMaria = 'https://api.postmaria.alpha2000.com.ar/api/'

const advClientApiPostmaria = Axios.create({
  baseURL: urlAdvancedApiPostMaria
});

advClientApiPostmaria.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    'Authorization': 'Bearer ' + storage.getItem('accessToken')
  };
  return rateLimit.delayConfig(config) as Promise<any>;
}, error => error);

async function refrescarToken() {
  try {
    let respuesta = await advClient.post('/Account/ValidRefreshToken', {
      UserName: storage.getItem('userNameADV'),
      RefreshToken: tokenStorageADV.getRefreshToken()
    });

    tokenStorageADV.setAccessToken(respuesta.data.AccessToken);
    tokenStorageADV.setRefreshToken(respuesta.data.RefreshToken);
    console.log('se refresco el token');
  } catch (error2) {
    console.log('Error en funcion refrescarToken()', JSON.stringify(error2));
  }
}

let promesaRefrescarToken: Promise<void> | null = null;

advClient.interceptors.response.use(success => success, async error => {
  try {

    if (error.response?.status === 429) {
      debugger;
    }
    if (!error.response || error.response.status !== 401) {

      console.log('config _retry ' + error.config._retry);


      if (!error.config._retry) { error.config._retry = true; }
      return Promise.reject(error);
    }

    if (!promesaRefrescarToken) {
      console.log('ADVAPI se mando refreshtoken....');
      promesaRefrescarToken = refrescarToken();
    }

    await promesaRefrescarToken;
    promesaRefrescarToken = null;

    return advClient.request(error.config);

  } catch (error2) {

    //todo: ver como avisar al usuario de un error
    console.log('Error al refrescar la sesion en client.interceptors.response.use', JSON.stringify(error2));
    //appContextState.cerrarSesion();
    //browserHistory?.replace('/login');
    //mostrarErrorSesionPerdida();

    return Promise.reject(new SesionPerdidaError('Error al refrescar la sesion'));
  }

});


export function useAdvAPI() {

  let { isCancel } = useCancel();


  //let [ mensajeError, setMensajeError] = useState('');
  //let { iniciarSesion, cerrarSesion } = React.useContext(AppContext);

  // por ahora no es necesario por que el api de advanced validar y leer el accesstoken de sintia4
  /*
      function loginAdv(values: { userName: string; password: string; }) {
          
          return advClient.post('/Account/ValidLogin', {
              UserName: values.userName, 
              Password: values.password
          }).then(respuesta => {
                      tokenStorageADV.setUserName(values.userName);
                      tokenStorageADV.setPassword(values.password);
                      tokenStorageADV.setAccessToken(respuesta.data.AccessToken);
                      tokenStorageADV.setRefreshToken(respuesta.data.RefreshToken);
                      ///iniciarSesion(respuesta.data.AccessToken);
                  }).catch(error => {
                          if (error.response && error.response.status === 400) {
                              let mensajesError: any[] = [];
                              Object.keys(error.response.data).forEach(key => {
                                  error.response.data[key].forEach((mensaje: any) => mensajesError.push(mensaje));
                              });
                              //setMensajeError(mensajesError.join('\r\n'));
                              //cerrarSesion();
                          } else {
                              console.error('Error al iniciar sesion', error);
                              //setMensajeError('Hubo un error al iniciar sesión');
                              //cerrarSesion();
                          }
                      });
      }
  */


  async function getDeclaracionesOficializadas(fechaDesde: any, fechaHasta: any, cuitDespachante: any, cuitImpoExpo: any, aduana: any) {

    let fechaDesdeFinal = DateTime.fromISO(fechaDesde).toFormat('dd/MM/yyyy');
    let fechaHastaFinal = DateTime.fromISO(fechaHasta).toFormat('dd/MM/yyyy'); //DateTime.fromISO(fechaHasta).plus({ days: 1 }).plus({ seconds: -1 }).toISODate();

    //loginAdv({ userName: 'javierg@alpha2000.com.ar', password: '20221486312' });
    //   loginAdv({ userName: 'testadvanced', password: 'Alph@.2oo2' });

    let respuesta = await advClient.post('/KitMaria/getDeclaracionesOficializadas',
      {
        FechaDesde: fechaDesdeFinal,
        FechaHasta: fechaHastaFinal,
        cuitDespachante: cuitDespachante,
        cuitImpoExpo: cuitImpoExpo,
        aduana: aduana
      });
    return respuesta.data;
  }


  async function getRatificacionesDespachosHistoricosPorFecha(cuitDespachante: any, fechaDesde: any, fechaHasta: any) {

    let fechaDesdeFinal = fechaDesde //DateTime.fromISO(fechaDesde).toFormat('dd/MM/yyyy');
    let fechaHastaFinal = fechaHasta // DateTime.fromISO(fechaHasta).toFormat('dd/MM/yyyy'); //DateTime.fromISO(fechaHasta).plus({ days: 1 }).plus({ seconds: -1 }).toISODate();

    //loginAdv({ userName: 'javierg@alpha2000.com.ar', password: '20221486312' });
    //   loginAdv({ userName: 'testadvanced', password: 'Alph@.2oo2' });

    let respuesta = await advClient.post('/Ratificacion/getRatificacionesDespachosHistoricosPorFecha',
      {
        cuitDespachante: cuitDespachante,
        FechaDesde: fechaDesdeFinal,
        FechaHasta: fechaHastaFinal
      });
    return respuesta.data;
  }

  async function getSubCuentaMaria(
    AduanaConexion: any,
    AduanaRegistro: any,
    CuitDespachante: any,
    CuitImportador: any,
    DepConSaldoPendiente: any,
    fechaDesde: any,
    fechaHasta: any,
    TipoSubCuenta: any) {

    let fechaDesdeFinal = DateTime.fromISO(fechaDesde).toFormat('dd/MM/yyyy');
    let fechaHastaFinal = DateTime.fromISO(fechaHasta).toFormat('dd/MM/yyyy');

    //   ({ userName: 'testadvanced', password: 'Alph@.2oo2' });

    let respuesta = await advClient.post('/KitMaria/getSubCuentasMaria',
      {
        AduanaConexion: AduanaConexion,
        AduanaRegistro: AduanaRegistro,
        CuitDespachante: CuitDespachante,
        CuitImportador: CuitImportador,
        DepConSaldoPendiente: DepConSaldoPendiente,
        fechaDesde: fechaDesdeFinal,
        fechaHasta: fechaHastaFinal,
        TipoSubCuenta: TipoSubCuenta
      });

    return respuesta.data;

  }



  async function getAvisoDeCargaConsulta(despacho: string, cuitDespachante: string, aduana: string) {

    let respuesta = await advClient.post('/KitMaria/avisoDeCargaConsulta',
      {
        despacho: despacho,
        cuitDespachante: cuitDespachante,
        aduana: aduana
      });

    return respuesta.data;

  }


  async function avisoDeCargaEnviar(aduana: string, despacho: string, cuitdespachante: string, fechaaviso: string, horaaviso: string, calle: string, nropuerta: string, localidad: string, observacion: string, telefono: string) {

    let respuesta = await advClient.post('/KitMaria/avisoDeCargaEnviar',
      {
        Aduana: aduana,
        despacho: despacho,
        CuitDespachante: cuitdespachante,
        FechaAviso: fechaaviso,
        HoraAviso: horaaviso,
        Calle: calle,
        NroPuerta: nropuerta,
        Localidad: localidad,
        Observacion: observacion,
        Telefono: telefono
      });

    return respuesta.data;

  };



  async function getConsultaBultos(
    manifiesto: string,
    puerto: string,
    documentoTransporte: string,
    aduana: string) {
    let respuesta = await advClient.post('/KitMaria/getConsultaDeBultos',
      {
        manifiesto: manifiesto,
        puerto: puerto,
        documentoTransporte: documentoTransporte,
        aduana: aduana
      });

    return respuesta.data;
  }

  async function getManifiestos(
    puerto: string,
    documentoTransporte: string,
    aduana: string) {
    let respuesta = await advClient.post('/KitMaria/getConsultaManifiesto',
      {
        puerto: puerto,
        documentoTransporte: documentoTransporte,
        aduana: aduana
      });

    return respuesta.data;
  }



  async function setNotificacionCierreIngresoDeposito(
    cuitDespachante: string, puerto: string, documentoTransporte: string,
    aduana: string, manifiesto: string, email: string) {

    let respuesta = await advClient.post('/KitMaria/setNotificacionCierreIngresoDeposito',
      {
        cuitDespachante: cuitDespachante,
        puerto: puerto,
        documentoTransporte: documentoTransporte,
        aduana: aduana,
        manifiesto: manifiesto,
        email: email
      }).catch(error => { throw error });

    return respuesta.data;

  }


  async function getSesionInfo(cuitDespachante: string) {
    //trae info si esta iniciado sesion en sesion manager
    let respuesta = await advClient.post('/Ratificacion/GetSessionInfo',
      { Cuit: cuitDespachante }
    );

    return respuesta.data;

  }


  async function iniciarSesionAFIP(cuit: string, clave: string, token: string, captcha: string) {
    //trae info si esta iniciado sesion en sesion manager
    let respuesta = await advClient.post('/Ratificacion/Login',
      {
        Cuit: cuit,
        Password: (clave === '' || undefined) ? token : clave,
        Token: (clave === '' || undefined) ? '' : token,
        Captcha: (captcha === undefined || null) ? '' : captcha
      }
    );

    return respuesta.data;
  }


  async function getConstanciaAFIP(cuit: string) {
    //trae info si esta iniciado sesion en sesion manager
    let respuesta = await advClient.post('/ConsultaConstacia/getDatos',
      { idPersona: cuit }
    );



    /* RESPUESTA
    {
        "Caracterizacion": "caracterizacion: ...",
        "CodPostal": "codPostal: \\\"3283\\\"",
        "DatoAdicional": "datoAdicional: \\\"RUTA PROV N 26\\\"",
        "Direccion": "direccion: \\\"KILOMETRO 5 RUTA PRO 0\\\"",
        "IdProvincia": "idProvincia: 5",
        "Localidad": "localidad: \\\"SAN JOSE\\\"",
        "DescripcionProvincia": "descripcionProvincia: \\\"ENTRE RIOS\\\"",
        "TipoDomicilio": "tipoDomicilio: \\\"FISCAL\\\"",
        "EstadoClave": "estadoClave: \\\"ACTIVO\\\"",
        "IdPersonaRta": "idPersona: 30514392001",
        "RazonSocial": "razonSocial: \\\"LAS CAMELIAS S A\\\"",
        "Apellido": "apellido: null",
        "Nombres": "nombre: null",
        "TipoClave": "tipoClave: \\\"CUIT\\\"",
        "TipoPersona": null,
        "ImpuestoIVA": "idImpuesto: 30",
        "ImpuestoIVAEXENTO": null,
        "ImpuestoMONOTRIBUTO": null
    }
        "Apellido": "apellido: \\\"GONZALEZ\\\"",
        "Nombres": "nombre: \\\"FLORENCIO JAVIER\\\"",

    */




    return respuesta.data;
  }

  const getObtenerDespachosFiltros = async (param: ObtenerDespachoFiltrosRequest): Promise<ObtenerDespachoFiltrosResponse[]> => {
    try {
      let respuesta = await advClient.post<ObtenerDespachoFiltrosRequest, any>('/Advanced/ObtenerDespachosFiltros', param);
      return respuesta.data;
    } catch (err) {
      throw new Error(`Error getObtenrDespachosFiltros: ${err}`);
    }
  };


  const getObtenerItemVista = async (id: string): Promise<ObtenerItemVistaResponse[]> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerItemsVista?DespachoID=${id}`);
      return respuesta.data.Items;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerItemVista: ${err}`);
    }
  };

  const getObtenerArticulosVista = async (id: string): Promise<ObtenerArticulosVistaResponse[]> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerArticulosVista?DespachoID=${id}`);
      return respuesta.data.Articulos;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerArticulosVista: ${err}`);
    }
  };

  const getObtenerArticulosLiq = async (id: string): Promise<ObtenerArticuosLiqVistaResponse[]> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerArticulosLiquidacionVista?DespachoID=${id}`);
      return respuesta.data.Liquidaciones;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerArticulosLiquidaciónVista: ${err}`);
    }
  };

  const getObtenerDespachoCaratula = async (id: string): Promise<ObtenerDespachoCaratulaResponse> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerDespachoCaratula?DespachoID=${id}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerArticulosLiquidaciónVista: ${err}`);
    }
  }

  const updateDespachoCaratula = async (datosUpdate: UpdateDespachoCaratulaBody) => {
    try {
      let respuesta = await advClient.post(`/Advanced/UpdateDespachoCaratula`, { ...datosUpdate });
      return respuesta?.data
    } catch (error) {
      console.error(error);
      throw new Error(`Error updateDespachoCaratula: ${error}`);
    }
  }

  const insertDVD = async (dataDVD: InsertDVDBody) => {
    try {
      let respuesta = await advClient.post(`/Advanced/InsertDVD`, { ...dataDVD });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }


  const deleteDVD = async (idDVD: number) => {
    try {
      let respuesta = await advClient.get(`/Advanced/DeleteDVD?ID=${idDVD}`);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const insertCPL = async (dataCPL: InsertCPLBody) => {
    try {
      let respuesta = await advClient.post('/Advanced/InsertCPL', { ...dataCPL });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const deleteCPL = async (idCPL: number) => {
    try {
      let respuesta = await advClient.get(`/Advanced/DeleteCPL?ID=${idCPL}`);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }



  const subirTXT = async (archivoTXT: FormData): Promise<SubirArchivos[]> => {
    try {
      let respuesta = await advClient.post(`Advanced/SubirArchivos`, archivoTXT);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error al subir archivo: ${err}`);
    }
  }

  const obtenerEstadoArchivos = async (): Promise<ObtenerEstadoArchivos[]> => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerEstadoArchivos`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const eliminarArchivos = async (ids: number[]) => {
    try {
      let respuesta = await advClient.post(`Advanced/EliminarArchivos`, ids);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const getObtenerItemDetalleVista = async (despachoID: string, NroItem: string) => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerItemDetalleVista?DespachoID=${despachoID}&NroItem=${NroItem}`);
      return respuesta?.data;
    } catch (error) {
      throw new Error(`Error: ${error}`);

    }
  }

  const obtenerListaArchivos = async (bodyListarArchivos: bodyListarArchivos): Promise<ResponseListarArchivos> => {
    try {
      let respuesta = await advClientApiPostmaria.post(`Archivos/ListarArchivos`, { ...bodyListarArchivos });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const subirArchivosVarios = async (archivoASubir: any, options: AxiosRequestConfig) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/GuardarArchivo`, { ...archivoASubir }, options,);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const eliminarArchivo = async (idArchivo: number) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/EliminarArchivo`, { 'ID': idArchivo });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const descargarArchivo = async (idArchivo: number, options: AxiosRequestConfig,) => {
    try {
      let respuesta = await advClientApiPostmaria.get(`/Archivos/DescargarArchivo?ID=${idArchivo}`, options);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const editarObservacionArchivo = async (id: number, Observacion: string,) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/ModificarObservacionArchivo`, { ID: id, Observacion: Observacion });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const descargarInternoCompleto = async ( bodyDatosCliente:any) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/DescargarInternoCompleto`, bodyDatosCliente);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }


  const getObtenerItemDetalle = async (id: string): Promise<any[]> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerItemsDatos?DespachoID=${id}`);
      return respuesta.data.Items;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerItemVista: ${err}`);
    }
  };

  const getObtenerDespachoDetalle = async (id: string): Promise<any> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerDespachoDetalle?DespachoID=${id}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerArticulosLiquidaciónVista: ${err}`);
    }
  }


  const datosGlobalesBorrarFacturas = async (despachoID: string) => {
    try {
      let respuesta = await advClient.get(`/Advanced/DatosGlobalesBorrarFactura?DespachoId=${despachoID}`);
      return respuesta?.data
    } catch (error) {
      console.error(error);
      throw new Error(`Error datosGlobalesBorrarFacturas: ${error}`);
    }
  }

  const updateDVD = async (dataDVD: UpdateDVDBody) => {
    try {
      let respuesta = await advClient.post(`/Advanced/updateDVD`, { ...dataDVD });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const updateCPL = async (dataCPL: UpdateCPLBody) => {
    try {
      let respuesta = await advClient.post('/Advanced/UpdateCPL', { ...dataCPL });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const ValidarDespacho = async (idDespacho: string, IDPerfilSeleccionado?: Number) => {
    try {
      let respuesta = await advClient.post(`Advanced/Validar?DespachoID=${idDespacho}&PerfilId=${IDPerfilSeleccionado ?? null}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const ObtenerEstadoValidacion = async (idDespacho: string) => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerEstadoValidacion?DespachoID=${idDespacho}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const EnviarEventoDespacho = async (bodyEnviarDespacho: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/EnviarEventoDespacho`, bodyEnviarDespacho);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const CancelarValidar = async (idDespacho: string) => {
    try {
      let respuesta = await advClient.post(`Advanced/CancelarValidar?DespachoID=${idDespacho}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const ObtenerUltimoHistorialValidacion = async (idDespacho: string) => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerUltimoHistorial?DespachoID=${idDespacho}`);
      return respuesta?.data;
    } catch (err) {
    }
  }

  const ObtenerListaPerfiles = async (): Promise<Perfiles[]> => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerListaPerfiles`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const CrearNuevoPerfil = async (nuevoPerfil_InputBoxValue: string) => {
    try {
      let respuesta = await advClient.get(`Advanced/AgregarPerfil?Nombre=${nuevoPerfil_InputBoxValue}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const EliminarPerfil = async (PerfilID: Number) => {
    try {
      let respuesta = await advClient.get(`Advanced/EliminarPerfil?PerfilID=${PerfilID}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const ObtenerPreguntasPerfil = async (PerfilID: Number) => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerRespuestasByPerfil?PerfilID=${PerfilID}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DescargarPDFMaria = async (DespachoID: Number) => {
    try {
      let respuesta = await advClient.get(`Advanced/DescargarPDFProvisorio?DespachoID=${DespachoID}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const ObtenerEstadoDespacho = async (DespachoID: Number) => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerEstadoDespacho?DespachoID=${DespachoID}`);
      return respuesta?.data;
    } catch (err) {
    }
  }

  const ObtenerUsuariosAFIP = async (): Promise<ObtenerUsuariosAFIPResponse[]> => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerUsuariosAFIP`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const OficializarDespacho = async (DespachoID: string, CuitUsuarioAfip: string, Token: string) => {
    try {
      let respuesta = await advClient.post(`Advanced/OficializarDespacho?DespachoID=${DespachoID}&CuitUsuarioAfip=${CuitUsuarioAfip}&Token=${Token}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DescargarPDFOficializado = async (nroDespacho: string | null) => {
    try {
      let respuesta = await advClient.get(`Advanced/DescargarPDFOficializado?NroDespacho=${nroDespacho}`, {
        responseType: 'blob',
      });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const ConvertirLicenciaGeneral = async (NroDespacho: string, Destinacion: string, CuitDespachante: string) => {
    try {
      let respuesta = await advClient.post(`Advanced/ConvertirLicenciaGeneral?NroDespacho=${NroDespacho}&Destinacion=${Destinacion}&CuitDespachante=${CuitDespachante}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const ObtenerConversionesLicencia = async () => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerConversionesLicencia`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesInfoComplementaria = async (DatosGlobalesInfoComplementaria: BodyDatosGlobalesInfComp) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesInfoComplementaria`, { ...DatosGlobalesInfoComplementaria });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesVentajas = async (DatosGlobalesVentajas: DatosGloablesVentajas) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesVentajas`, { ...DatosGlobalesVentajas });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesInsertarFactura = async (DatosGlobalesInsertarFactura: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesInsertarFactura `, { ...DatosGlobalesInsertarFactura });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const EliminarProvisorio = async (ID: number) => {
    try {
      let respuesta = await advClient.get(`Advanced/EliminarDespacho?DespachoID=${ID}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesCCM = async (body: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesCCM `, { ...body });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const EliminarCCMById = async (ID: number) => {
    try {
      let respuesta = await advClient.get(`Advanced/DeleteCCMbyId?ID=${ID}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const ObtenerCertificaciones = async (despachoID: any, itemID: any) => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerCertificaciones?DespachoID=${despachoID}&Item=${itemID}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const UpdateSufijo = async (body: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/UpdateSufijo `, { ...body });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const UpdateSufijosGlobales = async (body: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGloblalesUpdateCodigoSufijo `, { ...body });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const PostCargarArchivo = async (body: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/PostCargarArchivo`, { "ArchivoBase64": body });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const deleteSRG = async (idSRG: number) => {
    try {
      let respuesta = await advClient.get(`/Advanced/DeleteSRG?ID=${idSRG}`);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const obtenerNotificacionesUsuarios = async (cuitDespachante: number) => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerNotificaciones?CuitDespachante=${cuitDespachante}`);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const removeSessionByCuit = async (body: {CuitLogin: string}) => {
    try {
      let respuesta = await advClient.post(`/Ratificacion/RemoveSessionByCuit`, body);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const DatosGlobalesArticulosLista = async (DatosGlobalesArticulosLista: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesArticulosLista`, { ...DatosGlobalesArticulosLista });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesArticulosMercaderia = async (DatosGlobalesArticulosMercaderia: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesArticulosMercaderia`, { ...DatosGlobalesArticulosMercaderia });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesArticulosOrigen = async (DatosGlobalesArticulosOrigen: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesArticulosOrigen`, { ...DatosGlobalesArticulosOrigen });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesArticulosDestino = async (DatosGlobalesArticulosDestino: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesArticulosDestino`, { ...DatosGlobalesArticulosDestino });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesProrrateoFOBTransaccion = async (DatosGlobalesProrrateoFOBTransaccion: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesProrrateoFOBTransaccion`, { ...DatosGlobalesProrrateoFOBTransaccion });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const DatosGlobalesProrrateoKilos = async (DatosGlobalesProrrateoKilos: any) => {
    try {
      let respuesta = await advClient.post(`Advanced/DatosGlobalesProrrateoKilos`, { ...DatosGlobalesProrrateoKilos });
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  return {
    isCancel,
    getDeclaracionesOficializadas,
    getAvisoDeCargaConsulta,
    avisoDeCargaEnviar,
    getSubCuentaMaria,
    getConsultaBultos,
    getManifiestos,
    setNotificacionCierreIngresoDeposito,
    getRatificacionesDespachosHistoricosPorFecha,
    getSesionInfo,
    iniciarSesionAFIP,
    getConstanciaAFIP,
    getObtenerDespachosFiltros,
    getObtenerItemVista,
    getObtenerItemDetalle,
    getObtenerArticulosVista,
    getObtenerArticulosLiq,
    getObtenerDespachoCaratula,
    subirTXT,
    obtenerEstadoArchivos,
    eliminarArchivos,
    getObtenerItemDetalleVista,
    obtenerListaArchivos,
    subirArchivosVarios,
    eliminarArchivo,
    descargarArchivo,
    updateDespachoCaratula,
    insertDVD,
    deleteDVD,
    updateDVD,
    insertCPL,
    updateCPL,
    deleteCPL,
    ObtenerEstadoValidacion,
    CancelarValidar,
    ValidarDespacho,
    EnviarEventoDespacho,
    ObtenerUltimoHistorialValidacion,
    ObtenerListaPerfiles,
    CrearNuevoPerfil,
    EliminarPerfil,
    ObtenerPreguntasPerfil,
    getObtenerDespachoDetalle,
    DescargarPDFMaria,
    ObtenerEstadoDespacho,
    ObtenerUsuariosAFIP,
    OficializarDespacho,
    DescargarPDFOficializado,
    ConvertirLicenciaGeneral,
    ObtenerConversionesLicencia,
    DatosGlobalesInfoComplementaria,
    DatosGlobalesVentajas,
    datosGlobalesBorrarFacturas,
    EliminarProvisorio,
    DatosGlobalesInsertarFactura,
    DatosGlobalesCCM,
    ObtenerCertificaciones,
    EliminarCCMById,
    UpdateSufijo,
    PostCargarArchivo,
    deleteSRG,
    obtenerNotificacionesUsuarios,
    removeSessionByCuit,
    DatosGlobalesArticulosDestino,
    DatosGlobalesArticulosLista,
    DatosGlobalesArticulosMercaderia,
    DatosGlobalesArticulosOrigen,
    DatosGlobalesProrrateoFOBTransaccion,
    DatosGlobalesProrrateoKilos,
    UpdateSufijosGlobales,
    editarObservacionArchivo,
    descargarInternoCompleto
  }
}