import { DateTime } from 'luxon';
import { Perfiles, Respuestas } from 'modelos/Advanced';
import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Tab, Table, Tabs } from 'react-bootstrap'
import './styleTable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import BlockUi from 'react-block-ui';

interface propsModalPerfiles {
    showModalPerfiles: boolean | undefined;
    handleCloseModalPerfiles: (() => void)
    handleShowModalPerfiles: (() => void)
    setShowModalNuevoPerfil: ((arg: boolean) => void)
    opcionesPerfiles: Perfiles[]
    cargando: boolean
    updateCargando: ((arg: boolean) => void)
    obtenerPerfiles: (() => void)
}

const ModalPerfiles = ({ showModalPerfiles, handleCloseModalPerfiles, opcionesPerfiles, cargando, updateCargando, setShowModalNuevoPerfil, obtenerPerfiles }: propsModalPerfiles) => {
    let api = useAdvAPI();

    const [showModalConfirmarEliminarPerfil, setShowModalConfirmarEliminarPerfil] = useState<boolean>(false)
    const [showModalVerPreguntas, setShowModalVerPreguntas] = useState<boolean>(false)
    const [respuestasPerfil, setRespuestasPerfil] = useState<Respuestas | any>()
    const [perfilID, setPerfilID] = useState<Number | null>(null)
    const [tabSelect, setTabSelect] = useState<any>('Preguntas');

    const handleShowModalConfirmarEliminarPerfil = (perfilID: Number) => {
        setPerfilID(perfilID)
        setShowModalConfirmarEliminarPerfil(true)
    }

    const eliminarPerfil = async (PerfilID: Number) => {
        updateCargando(true);
        try {
            await api.EliminarPerfil(PerfilID);
            setShowModalConfirmarEliminarPerfil(false)
            updateCargando(false);
            obtenerPerfiles()
        } catch (error) {
            console.log('error')
            setShowModalConfirmarEliminarPerfil(false)
            updateCargando(false);
            obtenerPerfiles()
        }
    }

    const obtenerPreguntasPerfil = async (PerfilID: Number) => {
        setShowModalVerPreguntas(true)
        updateCargando(true);
        try {
            let preguntas = await api.ObtenerPreguntasPerfil(PerfilID);
            setRespuestasPerfil(preguntas?.Respuestas)
            updateCargando(false);
        } catch (error) {
            console.log('error')
            updateCargando(false);
        }
    }

    return (
        <>
            <Modal show={showModalPerfiles} onHide={handleCloseModalPerfiles} dialogClassName="modal-perfiles"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Administrar Perfiles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={cargando}>
                        <Container>
                            <p style={{ fontSize: 20, fontStyle: 'normal', textAlign: 'center', fontWeight: 'bold' }}>PERFILES</p>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', padding: 2 }}>
                                <Button onClick={() => setShowModalNuevoPerfil(true)} style={{ width: 150, height: 40 }} variant='success'>Crear Perfil</Button>
                            </div>
                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <Table
                                    style={{
                                        fontSize: '0.9rem',
                                        width: '100%',
                                    }}
                                    className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered"
                                >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Fecha Creación</th>
                                            <th>Acciones</th>
                                            <th>Preguntas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {opcionesPerfiles.map((perfil: Perfiles) => (
                                            <tr className="cursor-pointer" key={perfil.ID}>
                                                <td>{perfil.ID}</td>
                                                <td>{perfil.Nombre}</td>
                                                <td>{DateTime.fromISO(perfil.FechaCreacion!).toLocaleString({
                                                    ...DateTime.DATE_SHORT
                                                })}</td>
                                                <td>
                                                    <Button title='Borrar perfil' variant="link" onClick={() => handleShowModalConfirmarEliminarPerfil(perfil.ID)} style={{ fontSize: 20, color: '#db3939' }}><FontAwesomeIcon icon={faTrash} /></Button>
                                                </td>
                                                <td>   <Button title='Ver Preguntas' variant="link" onClick={() => obtenerPreguntasPerfil(perfil.ID)} style={{ fontSize: 20, color: 'blue' }}><FontAwesomeIcon icon={faTasks} /></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                                <Button variant='danger' onClick={() => handleCloseModalPerfiles()} style={{ width: 80 }}>Cerrar</Button>
                            </div>
                        </Container>
                    </BlockUi>

                </Modal.Body>
            </Modal>

            <Modal show={showModalConfirmarEliminarPerfil} onHide={() => obtenerPerfiles()} dialogClassName="modal-eliminar-perfil"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }} >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <h4 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 5, alignContent: 'center', alignItems: 'center' }}>¿Desea eliminar este perfil?</h4>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                            <Button variant='danger' onClick={() => eliminarPerfil(perfilID!)} style={{ width: 80 }}>Si</Button>
                            <Button variant='secondary' onClick={() => setShowModalConfirmarEliminarPerfil(false)} style={{ width: 80 }}>No</Button>

                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={showModalVerPreguntas} onHide={() => obtenerPerfiles()} dialogClassName="modal-preguntas-perfil"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                size={"lg"}
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Preguntas - Respuestas</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                    <Container>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={tabSelect}
                            onSelect={(k) => setTabSelect(k)}
                        >
                            <Tab eventKey="Preguntas" title="Preguntas">
                                {respuestasPerfil?.map((respuestas: Respuestas) => (
                                    respuestas?.VentanaMaria?.TipoVentanaMariaID === 2 ?
                                        <>
                                            <Container className="cardPreguntas" style={{ width: '450px', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                    <FontAwesomeIcon style={{ marginTop: 30, marginRight: 10, fontSize: 50, color: '#383be8' }} icon={faQuestionCircle} />
                                                    <p style={{ fontSize: 20, fontStyle: 'italic', textAlign: 'center', }}>{respuestas?.VentanaMaria?.Texto}</p>
                                                </div>
                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: 15 }}>Respuesta: {respuestas?.BotonVentanaMaria?.Nombre === "&Sí" ? <Button variant='success'>Sí</Button> : respuestas?.BotonVentanaMaria?.Nombre === "&No" ? <Button variant='danger'>No</Button> : ''}</p>
                                                </div>
                                            </Container>
                                        </>
                                        : <></>
                                ))}
                            </Tab>
                            <Tab eventKey="seleccion" title="Selección" >
                                {respuestasPerfil?.map((respuestas: Respuestas) => (
                                    respuestas?.VentanaMaria?.TipoVentanaMariaID === 3 ?
                                        <>
                                            <div style={{ maxHeight: '300px', maxWidth: '100%', overflowY: 'auto', padding: 20, marginBottom: 15 }}>
                                                <Table responsive striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Pregunta de selección multiple</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {respuestas?.VentanaMaria?.Opciones.map((opcion: any) =>
                                                            <tr className="cursor-pointer" key={opcion?.ID
                                                            }>
                                                                <td style={{ backgroundColor: opcion?.Texto === respuestas?.OpcionVentanaMaria?.Texto ? '#0d6efd' : 'white', color: opcion?.Texto === respuestas?.OpcionVentanaMaria?.Texto ? 'white' : 'black' }}>{opcion?.Texto}</td>
                                                            </tr>)}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr className="solid" />
                                        </> : <>
                                        </>
                                ))}
                            </Tab>
                            <Tab eventKey="formulario" title="Formulario">
                                {respuestasPerfil?.map((respuestas: Respuestas) => (
                                    respuestas?.VentanaMaria?.TipoVentanaMariaID === 1 ?
                                        <>

                                            <Container className="cardPreguntas">
                                                <div className="content">
                                                    <p style={{ fontSize: 20, fontStyle: 'italic', textAlign: 'center', marginTop: 10, color: 'black' }}> {respuestas?.VentanaMaria?.Texto}</p>
                                                </div>
                                                <p style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>Respuesta:</p>
                                                <Form.Group as={Col} md="12" lg="12">
                                                    <Form.Control
                                                        style={{ height: 38 }}
                                                        type="text"
                                                        name="ventanaMaria_InputBoxValue"
                                                        value={respuestas?.Texto!}
                                                        placeholder='Ingrese un valor..'
                                                        required
                                                    />
                                                </Form.Group>
                                            </Container>


                                        </>
                                        : <></>
                                ))}
                            </Tab>
                        </Tabs>
                        {respuestasPerfil?.length === 0 ? <h4 style={{ textAlign: 'center' }}>No se registraron preguntas/respuestas para este perfil.</h4> : <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>



                        </div>}

                    </Container>
                </Modal.Body>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                    <Button variant='danger' onClick={() => setShowModalVerPreguntas(false)} style={{ width: 80 }}>Cerrar</Button>
                </div>
            </Modal>
        </>
    )
}

export default ModalPerfiles