import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React, { useState } from 'react'
import BlockUi from 'react-block-ui'
import { Button, Form, Modal } from 'react-bootstrap'

interface propsModalPDFMaria {
    showModalPDFMaria: boolean;
    cargando: boolean;
    updateCargando: ((arg: boolean) => void)
    setShowModalPDFMaria: ((arg: boolean) => void)
    despachoID: Number
    estadoDespacho: string
    nroDespacho: string | null
    nroProvisorio: string | null
}

const ModalPDFMaria = ({ showModalPDFMaria, cargando, updateCargando, setShowModalPDFMaria, despachoID, estadoDespacho, nroDespacho, nroProvisorio }: propsModalPDFMaria) => {

    let api = useAdvAPI();

    const [error, setError] = useState('')

    const descargarPDFMaria = async () => {
        try {
            updateCargando(true)
            let response = await api.DescargarPDFMaria(despachoID!)
            const base64String = response?.ArchivoBase64;
            if(response?.ArchivoBase64 === null){
                updateCargando(false);
                setError('No existe el .PDF actual')
            }else{
                const byteCharacters = atob(base64String);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                // Crear un Blob a partir del array de bytes
                const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
                // Crear un enlace en memoria y descargar el archivo
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${nroProvisorio}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                updateCargando(false);
                setShowModalPDFMaria(false)
            }
         

        } catch (error) {
            updateCargando(false);
            setShowModalPDFMaria(false)
            console.log('error')
        }
    }

    const DescargarPDFOficializado = async () => {
        try {
            updateCargando(true)
            let response = await api.DescargarPDFOficializado(nroDespacho)
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nroDespacho}.pdf`);
            document.body.appendChild(link);
            link.click();
            link?.parentNode!.removeChild(link);
            updateCargando(false);
            setShowModalPDFMaria(false)
        } catch (error) {
            updateCargando(false);
            setError('Error al generar o no se encontró el PDF.')
            // setShowModalPDFMaria(false)
            console.log('error')
        }
    }

    return (
        <>
            <Modal show={showModalPDFMaria} dialogClassName="modal-pdf-maria"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static"
                onHide={() => setShowModalPDFMaria(false)} >
                <BlockUi blocking={cargando} message={<b>Descargando PDF..</b>}>
                    <Modal.Header closeButton>
                        <Modal.Title>Descargar Impresión Malvina</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     {  error === '' ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>N° Copias</Form.Label>
                                <Form.Control as="select" disabled>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </Form.Control>
                            </Form.Group>
                            <Button style={{ marginLeft: 20, width: 100, height: 60, marginTop: 10 }} onClick={() => {
                                estadoDespacho === 'Oficializado' ?
                                    DescargarPDFOficializado()
                                    : descargarPDFMaria()
                            }}>Generar</Button>
                        </div> : <div style={{display:'flex', flexDirection:'column'}}>
                           <p style={{fontSize:20, color:'red', fontWeight:'bold', marginLeft:120, marginBottom:20}}> {error}</p>
                            <Button onClick={() => {
                                setError('')
                                setShowModalPDFMaria(false)}}>Cerrar</Button>
                            </div>}
                    </Modal.Body>
                </BlockUi>

            </Modal>
        </>
    )
}

export default ModalPDFMaria