import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { MyForm, MyFormControlWithIcon, LoginPasswordControl } from './FormikHooks';
import { loginClient, AppContext } from './App';
import { tokenStorage } from './TokenStorage'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import loginPanelBg from './Imagenes/login_panel_transparente.png';
import BlockUi from 'react-block-ui';
import './App.css';
import base64js from 'base64-js';
import { storageFactory } from 'storage-factory';

export function Login() {

    const [cargando, setCargando] = useState(false)

    const [mensajeError, setMensajeError] = useState('')
    const { iniciarSesion, cerrarSesion } = React.useContext(AppContext)
    const storage = storageFactory(() => localStorage);


    //Función para hacer un login automatico, recibe accessToken, refreshToken y userName en base64. 
    //Ejemplo: 

    //accessToken=123456?refreshToken=123456?userName=123456@gmail.com   --> Recibe esto, pero en base64, cada value separado con ? tal comno está.

    //Quedando de la siguiente forma: URSintia.com/login?YWNjZXNzVG9rZW49ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnVZVzFsYVdRaU9pSXhNVE14TnpnaUxDSjFibWx4ZFdWZmJtRnRaU0k2SW14MWEyRjZNVGd3TWk1c2JFQm5iV0ZwYkM1amIyMGlMQ0pPY205RGJHbGxiblJsUVd4d2FHRWlPaUl5TWpFaUxDSlRaWE56YVc5dVNXUWlPaUprTWprd09USTBZaTA0WmpJekxUUXpNMll0T0Rsa01TMDNOalEyTnpjNU5EUXlZMllpTENKTmIyUjFiRzhpT2lJeUlpd2ljbTlzWlNJNklrTnNhV1Z1ZEdVaUxDSkZiWEJ5WlhOaFNXUWlPaUl6TXpjd056Z3pNRFF6T1NJc0lrRmtkbUZ1WTJWa0lqb2lRMnhwWlc1MFpTSXNJbTVpWmlJNk1UY3pNREkwTWprME1Dd2laWGh3SWpveE56TXdNamcyTVRRd0xDSnBZWFFpT2pFM016QXlOREk1TkRBc0ltbHpjeUk2SWtGc2NHaGhMa0Z3YVZOcGJuUnBZVTF2Ym1kdkluMC5iXzhJSDJrME5UQTAtRUczVEFtRzQ0TU9UeS1uWGdJTW1vRGpUX25JQVhVP3JlZnJlc2hUb2tlbj1ZVnkyU1Z0NGsyRjNGY0RvdWFHVDE1d2FnekhXZkE1Q0t2ZjFQY2lsb3RjPQ==


    const extractTokens = (data: string) => {

        //Decode base64 a string para poder leerlo y setearlo en cookies.
        let array = base64js?.toByteArray(data)
        let decoder = new TextDecoder()
        let tokenData = decoder?.decode(array)
        const params = tokenData.split('?')
        const obj: { [key: string]: string } = {}
        // Agregar accessToken al objeto.
        const [key1, ...value1Parts] = params[0].split('=')
        const value1 = value1Parts.join('=')
        obj[key1] = value1

        // Separar los parámetros de refreshToken y agregarlo al objeto.
        const [key2, ...value2Parts] = params[1].split('=')
        const value2 = value2Parts.join('=')
        obj[key2] = value2

        // Separar los parámetros de userName y agregarlo al objeto.
        const [key3, ...value3Parts] = params[2].split('=')
        const value3 = value3Parts.join('=')
        obj[key3] = value3

        // Separar los parámetros de loginAutomatico y agregarlo al objeto.
        const [key4, ...value4Parts] = params[3].split('=')
        const value4 = value4Parts.join('=')
        obj[key4] = value4

        
        // Obtener la cadena de accessToken, refreshToken y userName.
        const accessToken = obj.accessToken
        const refreshToken = obj.refreshToken
        const userName = obj.userName
        const loginAutomatico = obj.loginAutomatico


        // Si la URL contiene los tokens los seteo en cookies junto con el userName.
        if (accessToken && refreshToken) {
            tokenStorage.setAccessToken(accessToken)
            tokenStorage.setRefreshToken(refreshToken)
            storage.setItem('loginAutomatico', loginAutomatico)
            storage.setItem('userName', userName)
        }
    }

    useEffect(() => {
        const query = window?.location?.search?.substring(1)
        if (query?.length > 0) {
            extractTokens(query)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    let schema = Yup.object({
        userName: Yup.string().nullable().required('Debe ingresar el nombre de usuario'),
        password: Yup.string().nullable().required('Debe ingresar la contraseña')
    });
    function login(values: { userName: string; password: string; }) {
        setCargando(true);
        return loginClient.post('/Account/Login', {
            UserName: values.userName, Password: values.password,
            Proyecto: 2
        }).then(respuesta => {
            tokenStorage.setUserName(values.userName);
            tokenStorage.setPassword(values.password);
            tokenStorage.setAccessToken(respuesta.data.AccessToken);
            tokenStorage.setRefreshToken(respuesta.data.RefreshToken);
            iniciarSesion(respuesta.data.AccessToken);
            setCargando(false);
        }).catch(error => {

            setCargando(false);
            if (error.response && error.response.status === 400) {
                let mensajesError: any[] = [];
                Object.keys(error.response.data).forEach(key => {
                    error.response.data[key].forEach((mensaje: any) => mensajesError.push(mensaje));
                });
                setMensajeError(mensajesError.join('\r\n'));
                cerrarSesion();
            } else {
                console.error('Error al iniciar sesion', error);
                setMensajeError('Hubo un error al iniciar sesión');
                cerrarSesion();
            }
        });
    }
    function submit(values: { userName: string; password: string; }) {
        // mantenimientoClient.get('/Mantenimiento').then(respuesta => {
        //     if (respuesta.data.EnMantenimiento) {
        //         cerrarSesion();
        //         setMensajeError('El Sintia está en mantenimiento. Intente nuevamente más tarde');
        //     } else {
        //         login(values);
        //     }
        // });
        setCargando(false);
        return login(values);
    }

    let initialValues = { userName: tokenStorage.getUserName(), password: tokenStorage.getPassword() };
    return (<div className='mi-div'>
        <img src={loginPanelBg} style={{ width: '100%', height: 'auto', marginTop: '1rem' }} alt="Sintia NET" title="Sintia NET"></img>
        <h2 className="text-center">Iniciar sesión</h2>
        {mensajeError && (<Alert variant="danger">{mensajeError}</Alert>)}


        <BlockUi blocking={cargando}>
            <Formik initialValues={initialValues}
                validationSchema={schema}
                onSubmit={submit}>
                <MyForm className="loginForm">
                    <Form.Group>
                        <MyFormControlWithIcon type="text" icon={faUser} placeholder="Nombre de usuario"
                            label="Nombre de usuario" name="userName"></MyFormControlWithIcon>
                    </Form.Group>
                    <Form.Group>
                        <LoginPasswordControl placeholder="Contraseña" label="Contraseña" name="password" />
                    </Form.Group>
                    <div className="d-flex flex-wrap justify-content-center">
                        <Button type="submit">Iniciar sesión</Button>
                        <div style={{ width: '100%', margin: '5px' }}></div>
                        <a href="http://www.alpha2000.com.ar/sistemas/clientes/Sintia4/SetupSintia4.exe">Descargue la versión de escritorio</a>
                    </div>
                </MyForm>
            </Formik >
        </BlockUi>



    </div >);
}