import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Col, Table, Alert, DropdownButton, Dropdown } from "react-bootstrap";
//import { BaseSelect } from "BaseSelect";
//import styled from "styled-components";
import { MyForm, MyFormControl, MySelect, SelectOption } from "../../FormikHooks";
import * as Yup from "yup";
//import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
import { convertirDatosGenericosAOptionIdDescripcion, toDate } from "Utilidades";
//import { useHistory } from "react-router";
//import { LoginAFIP, LoginAFIPRef } from './sesionafip';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikProps } from "formik";
import BlockUi from "react-block-ui";
import "../../modelos/Advanced";
import { ObtenerDespachoFiltrosRequest, ObtenerDespachoFiltrosResponse } from "../../modelos/Advanced";
import { Link } from "react-router-dom";
import ModalConvertirLicencia from "Paginas/advanced/ConvertirLicenciaModal/ModalConvertirLicencia";
import ModalPDFMaria from "Paginas/advanced/ProvisorioTable/ModalPDFMaria";
import ModalEliminarProvisorio from "Paginas/advanced/ModalEliminarProvisorio";
import ModalSubirTXT from "Paginas/advanced/SubirTXTForm/ModalSubirTXT";
//import { DateTime } from "luxon";

/*const CustomSelect = styled(BaseSelect)`
    width:100%;
`;*/

export function VerDestincaciones() {

    let api = useAdvAPI();
    let apiSintia = useApi();
    const [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    const [optionsImportadores, updateOptionsImportadores] = useState<SelectOption[]>([]);
    const [optionsAduana, updateoptionsAduana] = useState<SelectOption[]>([]);
    const [ConsultaSubError, updateConsultaSubError] = useState(false);
    const [ConsultaSubMsg, updateConsultaSubMsg] = useState('');
    //const [optionsaduanaReg, updateoptionsAduanaReg] = useState<SelectOption[]> ([{ value: '000', label: '000 - ADUANA RECAUDADORA' }]);
    const [cargando, updateCargando] = useState(false);
    const [cargandoDespachantes, setCargandoDespachantes] = useState(false);
    const [cargandoImportadores, setCargandoImportadores] = useState(false);
    const [cargandoAduanas, setCargandoAduanas] = useState(false);
    const [despachosNoResult, updateDespachosNoResult] = useState(false);
    const [showModalPDFMaria, setShowModalPDFMaria] = useState(false)
    const [params, setParams] = useState<any>()
    const [resulado, updateREsulado] = useState<ObtenerDespachoFiltrosResponse[]>([]);

    //let loginAFIPRef = useRef<LoginAFIPRef>(null);
    let formikRef = useRef<FormikProps<any>>(null);
    let huboCambios = useRef<{ valor: boolean }>({ valor: false });
    let despachoID = useRef<any>(null);
    let nroProvisorio = useRef<any>(null);
    let estadoDespacho = useRef<any>(null)
    let nroDespacho = useRef<any>(null)
    const formRef = useRef<any>(null);


    const showModalPDF = (row: ObtenerDespachoFiltrosResponse) => {
        setShowModalPDFMaria(true)
        despachoID.current = row?.ID
        nroProvisorio.current = row?.NumeroProvisorio
        nroDespacho.current = row?.NroDespacho
        estadoDespacho.current = row?.EstadoDescripcion
    }

    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }

    let schema = Yup.object({
        'aduanaconexion': Yup.string().nullable(),
        'cuitDespachante': Yup.string().nullable(),
        'cuitImpoExpo': Yup.string().nullable(),
        'identificadorPropio': Yup.string().nullable(),
        'nroDestinacion': Yup.string().nullable(),
        'fechaDesde': Yup.date().required('Debe ingresar Fecha Desde'),
        'fechaHasta': Yup.date().required('Debe ingresar Fecha Hasta'),
    });

    var d = new Date();
    let hoy = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
    ].join('-');

    const getDespachantes = async () => {
        setCargandoDespachantes(true)
        try {
            await apiSintia.getDespachantes().then((resp) => {
                updateOptionsDespachantes(resp.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                setCargandoDespachantes(false)
            })
        } catch (error) {
            setCargandoDespachantes(false)
        }
    }

    const getImportadores = async () => {
        setCargandoImportadores(true)
        try {
            await apiSintia.getImportadores().then((resp) => {
                updateOptionsImportadores(resp.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                setCargandoImportadores(false)
            })
        } catch (error) {
            setCargandoImportadores(false)
        }
    }

    const getAduanas = async () => {
        setCargandoAduanas(true)
        try {
            await apiSintia.getAduanas().then((resp) => {
                let options = convertirDatosGenericosAOptionIdDescripcion(resp)
                updateoptionsAduana(options);
                setCargandoAduanas(false)
            })
        } catch (error) {
            setCargandoAduanas(false)
        }
    }

    useEffect(() => {
        async function cargar() {
            try {
                getDespachantes()
                getImportadores()
                getAduanas()
                updateCargando(false);
            } catch (error) {
                if (!apiSintia.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                    updateCargando(false);

                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);

    const reloadTable = async () => {
        try {
            let respuesta = await api.getObtenerDespachosFiltros(params);
            if (respuesta.length > 0) {
                updateREsulado(respuesta);
                updateCargando(false);
            }
            else {
                updateREsulado([]);
                updateDespachosNoResult(true);
                updateCargando(false);
            }
        } catch (error: any) {
            updateCargando(false);
            updateConsultaSubMsg(error?.response?.data);
            updateREsulado([]);
            updateDespachosNoResult(true);
            updateConsultaSubError(true);
        }
    }

    async function cargarDatos(pbusqueda?: any) {
        updateDespachosNoResult(false);
        updateCargando(true);
        updateConsultaSubError(false);
        updateConsultaSubMsg('');
        try {
            const param: ObtenerDespachoFiltrosRequest = {
                cuitsDespachantes: pbusqueda.cuitDespachante !== "" ? [pbusqueda.cuitDespachante] : [],
                cuitsImpoExpo: pbusqueda.cuitImpoExpo !== "" ? [pbusqueda.cuitImpoExpo] : [],
                aduanas: pbusqueda.aduanaconexion !== "" ? [pbusqueda.aduanaconexion] : [],
                identificadorPropio: pbusqueda.identificadorPropio !== "" ? pbusqueda.identificadorPropio : null,
                referencia: null,
                despachoID: null,
                nroDespacho: pbusqueda.nroDestinacion !== "" ? pbusqueda.nroDestinacion : null,
                FechaCreacionDesde: pbusqueda.fechaDesde,
                FechaCreacioHasta: pbusqueda.fechaHasta
            };
            setParams(param)
            let respuesta = await api.getObtenerDespachosFiltros(param);
            if (respuesta.length > 0) {
                updateCargando(false);
                updateREsulado(respuesta);
            }
            else {
                updateCargando(false);
                updateREsulado([]);
                updateDespachosNoResult(true);
            }
        } catch (error: any) {
            console.log(error);
            updateCargando(false);
            updateREsulado([]);
            updateDespachosNoResult(true);
            updateConsultaSubError(true);
            updateConsultaSubMsg(error?.response?.data);
        }
    }

    /*
    Anio
    TipoSubCuenta
    MedioDeposito
    NroDeposito
    ImporteDeposito
    FechaAcreditacion
    SaldoPendienteAfectacion
    */

    function GrillaDespachos(props: { despachos: ObtenerDespachoFiltrosResponse[] }) {
        const estiloTabla = {
            fontSize: '0.9rem', // Puedes ajustar este valor según tus necesidades
            width: '100%',
        };
        /*            const estiloCelda = {
                        border: '1px solid #dddddd',
                        padding: '1px',
                      };*/
        return <>
            <div className="table-responsive" style={{ height: 400 }}>
                <Table style={estiloTabla} className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Id Propio</th>
                            <th>Declaracion</th>
                            <th>Despachante</th>
                            <th>ImpoExpo</th>
                            <th>Comprador/Vendedor</th>
                            <th>Fecha Cración</th>
                            <th>Estado</th>
                            <th>Vista</th>
                        </tr>
                    </thead>
                    <tbody>{props.despachos.map((t: ObtenerDespachoFiltrosResponse, indice: number) =>
                    (
                        <tr className="cursor-pointer " key={t.ID} onClick={e => {
                            //let element = e.target as HTMLElement;
                            //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                            /*if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                                props.eventoAbrir(t);
                            }*/
                        }}>
                            <td> <Link to={{
                                pathname: `/provisorio/${t.ID}`,
                                state: { estado: t.EstadoDescripcion }
                            }}>{t.IdentificadorPropio}</Link></td>
                            <td>{t?.EstadoDescripcion === 'Oficializado' ? <><Link to={{
                                pathname: `/provisorio/${t.ID}`,
                                state: { estado: t.EstadoDescripcion }
                            }}>{t.NroDespacho}</Link></>
                                : <><Link to={{
                                    pathname: `/provisorio/${t.ID}`,
                                    state: { estado: t.EstadoDescripcion }
                                }}>{t.NumeroProvisorio}</Link></>}</td>
                            <td>{t.Despachante}</td>
                            <td>{t.ImpoExpo}</td>
                            <td>{t.CompradorVendedor}</td>
                            <td>{toDate(t.FechaCreacion)}</td>
                            <td>{t.EstadoDescripcion}</td>
                            <td>
                                {(t?.EstadoDescripcion === 'Oficializado' || t?.EstadoDescripcion === 'Validado') ? <DropdownButton drop="down" title="...">
                                    <Dropdown.Item as="button"><Link to={{
                                        pathname: `/provisorio/${t.ID}`,
                                        state: { estado: t.EstadoDescripcion }
                                    }}>Ver</Link></Dropdown.Item>
                                    {((t?.Destinacion === 'SIMI' && t?.EstadoDescripcion === 'Oficializado') || (t?.Destinacion === 'SEDI' && t?.EstadoDescripcion === 'Oficializado')) && <Dropdown.Item as="button"><ModalConvertirLicencia despachanteNombre={t?.Despachante} despachanteCuit={t?.DespachenteCuit} nroSimi={t?.NroDespacho} styleLink={true} /></Dropdown.Item>
                                    }
                                    {((t?.EstadoDescripcion === 'Oficializado') || (t?.EstadoDescripcion === 'Validado')) && <Dropdown.Item as="button" onClick={() => setShowModalPDFMaria(true)}><button onClick={() => showModalPDF(t)} className="styleLink">Descargar PDF</button></Dropdown.Item>}
                                </DropdownButton> : t?.EstadoDescripcion !== 'Oficializado' && <DropdownButton drop="down" title="..."> <Dropdown.Item as="button"><Link to={{
                                    pathname: `/provisorio/${t.ID}`,
                                    state: { estado: t.EstadoDescripcion }
                                }}>Ver</Link></Dropdown.Item>
                                    <Dropdown.Item as="button"><ModalEliminarProvisorio triggerSubmit={reloadTable} ID={t?.ID} /></Dropdown.Item></DropdownButton>}
                            </td>
                            {/*
                            <td className="text-right">{Number(t.ImporteDeposito).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                            <td className="text-right">{ Number(t.SaldoPendienteAfectacion).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                            <td>{DateTime.fromFormat(t.FechaAcreditacion,"dd-MMM-yy").toFormat('dd/MM/yyyy')} {t.FechaAcreditacion}</td>*/}

                            {/*<td className="text-right">{toFixedDecimal(t.SaldoPendienteAfectacion,2)}</td>*/}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </>;
    }

    let initialvalues = {
        cuitDespachante: '',
        cuitImpoExpo: '',
        aduanaconexion: '',
        identificadorPropio: '',
        nroDestinacion: '',
        fechaDesde: hoy,
        fechaHasta: hoy
    };

      useEffect(() => {
          if(optionsDespachantes?.length === 1){
              formikRef?.current?.setFieldValue('cuitDespachante', optionsDespachantes?.[0]?.value)
          }
      }, [optionsDespachantes])
    

    return (
        <>
            <Container>
                <br /><p className="h3">Destinaciones</p><br />
                <Container style={{ display: 'flex', flexDirection: 'row' }}>
                    <ModalSubirTXT />
                    <ModalConvertirLicencia styleLink={false} />
                </Container>
                <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} ref={formRef} onSubmit={cargarDatos}>
                    <BlockUi blocking={cargando}>
                        <MyForm className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 col-lg-12">
                                    <Form.Group as={Col} className="col-md-12">
                                        {ConsultaSubError &&
                                            <Alert variant="danger" onClose={() => updateConsultaSubError(false)} dismissible>
                                                <Alert.Heading>Error al consultar Sub Cuenta</Alert.Heading>
                                                <p><b>{ConsultaSubMsg}</b></p>
                                            </Alert>
                                        }
                                    </Form.Group>
                                    <Form.Row className="row">
                                        <Form.Group as={Col} className="col-lg-6 col-12 col-md-6">
                                            <BlockUi blocking={cargandoImportadores}>
                                                <MySelect
                                                    name="cuitImpoExpo" label="Importador"
                                                    options={optionsImportadores} onValueChange={eventoHuboCambios} />
                                            </BlockUi>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-lg-6">
                                            <BlockUi blocking={cargandoDespachantes} >
                                                <MySelect
                                                    name="cuitDespachante" label="Despachante" defaultValue={optionsDespachantes?.length === 1 && optionsDespachantes?.[0]}
                                                    options={optionsDespachantes} onValueChange={eventoHuboCambios} />
                                            </BlockUi>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="row">
                                        <Form.Group as={Col} className="col-md-4 col-12">
                                            <BlockUi blocking={cargandoAduanas}>
                                                <MySelect
                                                    name="aduanaconexion" label="Aduana"
                                                    options={optionsAduana}
                                                />
                                            </BlockUi>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <MyFormControl style={{ height: 38 }} name="fechaDesde" type="date" label="Fecha Desde" ></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <MyFormControl style={{ height: 38 }} name="fechaHasta" type="date" label="Fecha Hasta" ></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="row">
                                        <Form.Group as={Col} className="col-md-4 col-12">
                                            <MyFormControl name="nroDestinacion" type="text" label="Nro Destinacion" ></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <MyFormControl name="identificadorPropio" type="text" label="Identificador Propio" ></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <Button type="submit" className="align-self-end my-4">Buscar</Button>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group className="mr-2  mb-2 col-md-12">
                                        {despachosNoResult &&
                                            <Alert variant="warning">
                                                <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                                                <span className="ml-3">No hay Declaraciones para los paremetros seleccionados</span>
                                            </Alert>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </MyForm>
                    </BlockUi>
                </Formik>
                <div className="mt-2">
                    <GrillaDespachos despachos={resulado}></GrillaDespachos>
                </div>
                <ModalPDFMaria cargando={cargando} updateCargando={updateCargando} despachoID={despachoID?.current} nroDespacho={nroDespacho?.current} nroProvisorio={nroProvisorio?.current} setShowModalPDFMaria={setShowModalPDFMaria} showModalPDFMaria={showModalPDFMaria} estadoDespacho={estadoDespacho?.current} />


            </Container>

        </>

    )
}